import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { InvoiceService } from "../../core/services/invoice.service";
import { EditInvoiceDataComponent } from "../new-invoice/edit-invoice-data/edit-invoice-data.component";
import { PositionListComponent } from "../shared/components/position-list/position-list.component";
import { ManageBillingAddressComponent } from "../../shared/components/manage-billing-address/manage-billing-address.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModalComponent, ConfirmModalData } from "../../shared/modals/confirm-modal/confirm-modal.component";
import { ConfirmModalResult } from "../../shared/model/confirm-modal-result";
import { CustomerInvoiceTO, CustomerInvoiceType } from "../../shared/generated/transportObjects";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ti-edit-invoice",
  templateUrl: "./edit-invoice.component.html",
  styleUrls: ["./edit-invoice.component.scss"]
})
export class EditInvoiceComponent implements OnInit {
  @ViewChild(ManageBillingAddressComponent, { static: true })
  private manageBillingAddressComponent: ManageBillingAddressComponent;

  @ViewChild(EditInvoiceDataComponent, { static: true })
  private editInvoiceDataComponent: EditInvoiceDataComponent;

  @ViewChild(PositionListComponent, { static: true })
  private positionListComponent: PositionListComponent;

  @Input()
  invoice: CustomerInvoiceTO;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  saved: EventEmitter<any> = new EventEmitter();

  private is: InvoiceService = inject(InvoiceService);
  private dialog: MatDialog = inject(MatDialog);
  private translate: TranslateService = inject(TranslateService);

  invoiceType: CustomerInvoiceType;
  constructor() {}

  ngOnInit(): void {
    this.customerInvoiceType(this.invoice);
  }
  get savable(): boolean {
    return (
      this.editInvoiceDataComponent.formGroup.valid &&
      this.positionListComponent.positions.length > 0 &&
      this.manageBillingAddressComponent.formGroup.valid
    );
  }

  cancelEdit(): void {
    this.cancel.emit();
  }

  checkSave(): void {
    const context: ConfirmModalData = {
      title: this.translate.instant("invoice.editInvoice.sure"),
      question: this.translate.instant("invoice.editInvoice.saveConfirmModalData.question")
    };
    this.dialog
      .open(ConfirmModalComponent, { data: context })
      .afterClosed()
      .subscribe({
        next: (res: ConfirmModalResult) => {
          if (this.translate.instant(ConfirmModalResult.ACCEPT) === res) {
            this.saveChanges();
          }
        }
      });
  }

  checkDelete(): void {
    const context: ConfirmModalData = {
      title: this.translate.instant("invoice.editInvoice.sure"),
      question: this.translate.instant("invoice.editInvoice.deleteConfirmModalData.question")
    };
    this.dialog
      .open(ConfirmModalComponent, { data: context })
      .afterClosed()
      .subscribe({
        next: (res: ConfirmModalResult) => {
          if (this.translate.instant(ConfirmModalResult.ACCEPT) === res) {
            this.deleteInvoice();
          }
        }
      });
  }

  private saveChanges(): void {
    this.invoice.customerInvoiceType = this.invoiceType;
    this.invoice.positions = this.positionListComponent.positions;

    this.invoice.invoiceData = this.editInvoiceDataComponent.formGroup.value;
    this.invoice.billingAddress = this.manageBillingAddressComponent.formGroup.value;

    this.is.updateInvoice(this.invoice).subscribe({
      next: () => this.saved.emit()
    });
  }

  private deleteInvoice(): void {
    this.is.deleteInvoice(this.invoice.id).subscribe({
      next: () => this.saved.emit()
    });
  }

  customerInvoiceType(invoice): void {
    this.invoiceType = (invoice as CustomerInvoiceTO).customerInvoiceType;
  }

  selectInvoiceType(invoiceType: CustomerInvoiceType): void {
    this.invoiceType = invoiceType;
  }
}
