import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { CustomerRoutingModule } from "./customer-routing.module";
import { CustomerWelcomeComponent } from "./customer-welcome/customer-welcome.component";
import { ShowAllCustomersComponent } from "./show-all-customers/show-all-customers.component";
import { SearchCustomerComponent } from "./search-customer/search-customer.component";
import { HeaderFactory } from "../core/utils/header-factory";
import { ChooseTypeComponent } from "./manage/choose-type/choose-type.component";
import { PrivateCustomerComponent } from "./manage/private-customer/private-customer.component";
import { BusinessCustomerComponent } from "./manage/business-customer/business-customer.component";
import { ShowCustomerComponent } from "./manage/show-customer/show-customer.component";
import { ContactPersonComponent } from "./manage/contact-person/contact-person.component";
import { CreditsComponent } from "./credits/credits.component";
import { CreditListComponent } from "./shared/components/credit-list/credit-list.component";
import { CreateCreditModalComponent } from "./shared/modals/create-credit-modal/create-credit-modal.component";
import { FreelanceCustomerComponent } from "./manage/freelance-customer/freelance-customer.component";

@NgModule({
  imports: [CustomerRoutingModule, SharedModule],
  declarations: [
    CustomerWelcomeComponent,
    ShowAllCustomersComponent,
    SearchCustomerComponent,
    ChooseTypeComponent,
    PrivateCustomerComponent,
    BusinessCustomerComponent,
    ShowCustomerComponent,
    ContactPersonComponent,
    CreditsComponent,
    CreditListComponent,
    CreateCreditModalComponent,
    FreelanceCustomerComponent
  ],
  providers: [HeaderFactory],
  exports: []
})
export class CustomerModule {}
