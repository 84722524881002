<div class="first ti-cards">
  <div e2e="card-btn-create-invoice" class="ti-card ti-link-card medium cms-card" (click)="navigateToCreateNewInvoice()">
    <span class="material-icons">note_add</span>
    <div>{{ "invoice.invoiceWelcome.createInvoice" | translate }}</div>
  </div>
  <div e2e="card-btn-show-all-invoices" class="ti-card ti-link-card medium cms-card" routerLink="/invoices/all">
    <span class="material-icons">reorder</span>
    <div>{{ "invoice.common.allInvoices" | translate }}</div>
  </div>
  <div e2e="card-btn-show-invoices-for-customer" class="ti-card ti-link-card medium cms-card" routerLink="/invoices/search-customer">
    <span class="material-icons">supervised_user_circle</span>
    <div>{{ "invoice.invoiceWelcome.customerInvoices" | translate }}</div>
  </div>
</div>

<div class="second ti-cards">
  <div e2e="card-btn-manage-credits" class="ti-card ti-link-card medium cms-card" routerLink="/invoices/credits">
    <span class="material-icons">trending_up</span>
    <div>{{ "invoice.invoiceWelcome.manageCredits" | translate }}</div>
  </div>
  <div e2e="card-btn-report-profit" class="ti-card ti-link-card medium cms-card" routerLink="/invoices/report-profit">
    <span class="material-icons">euro_symbol</span>
    <div>{{ "invoice.invoiceWelcome.reportProfit" | translate }}</div>
  </div>
  <div e2e="card-btn-sales-positions-per-year" class="ti-card ti-link-card medium cms-card" routerLink="/invoices/list-sales">
    <span class="material-icons">receipt</span>
    <div class="header">{{ "invoice.invoiceWelcome.salesPositions" | translate }}</div>
  </div>
</div>

<div *ngIf="quickStats" class="statistics">
  <til-flex-icon-message icon="people_alt" [withCountTo]="true" [amount]="quickStats.customerCount" color="accent">{{
    "common.customers" | translate
  }}</til-flex-icon-message>
  <til-flex-icon-message icon="description" [withCountTo]="true" [amount]="quickStats.amountPaidInvoicesCurrentYear" color="accent">{{
    "invoice.invoiceWelcome.bills" | translate
  }}</til-flex-icon-message>
  <til-flex-icon-message icon="euro_symbol" [withCountTo]="true" [amount]="+quickStats.salesCurrentYear.toFixed(0)" color="accent"
    >{{ "invoice.invoiceWelcome.salesVolume" | translate }} {{ quickStats.salesYear }}</til-flex-icon-message
  >
</div>
