<div class="form-container">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="save()">
    <mat-form-field>
      <mat-label>{{ "customer.manage.freelanceCustomer.companyName" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-company-name" matInput placeholder="treeIT" formControlName="name" />
      <mat-error *ngIf="hasError('name')">{{ "formValidation.company.companyData.required.name" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editCompanyData.legalForm" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-company-legalForm" matInput placeholder="GmbH & Co. KG" formControlName="legalForm" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editCompanyData.taxId" | translate }}</mat-label>
      <input [maxlength]="14" e2e="input-company-taxId" matInput placeholder="DE12345678" formControlName="taxId" />
      <mat-error *ngIf="hasError('taxId')">{{ "formValidation.company.companyData.pattern.taxId" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editCompanyData.fon" | translate }}</mat-label>
      <input [maxlength]="16" e2e="input-company-fon" matInput placeholder="9012345678" formControlName="fon" />
      <mat-error *ngIf="hasError('fon')">{{ "formValidation.company.companyData.pattern.phone" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editCompanyData.webAddress" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-company-webAddress" matInput placeholder="www.company.de" formControlName="webAddress" />
      <mat-error *ngIf="hasError('webAddress')">{{ "formValidation.company.companyData.pattern.webAddress" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editCompanyData.mailAddress" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-company-mailAddress" matInput placeholder="infofo@company.de" formControlName="mailAddress" />
      <mat-error *ngIf="hasError('mailAddress')">{{ "formValidation.company.companyData.pattern.mailAddress" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editCompanyData.invoiceTemplate" | translate }}</mat-label>
      <mat-select formControlName="invoiceTemplateName">
        <mat-option *ngFor="let invoiceTemplateName of invoiceTemplates" [value]="invoiceTemplateName">
          {{ invoiceTemplateName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="ti controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
