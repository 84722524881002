import { NgModule } from "@angular/core";

import { StatisticsRoutingModule } from "./statistics-routing.module";
import { SharedModule } from "../shared/shared.module";
import { StatisticsWelcomeComponent } from "./statistics-welcome/statistics-welcome.component";
import { StatsOverviewComponent } from "./stats-overview/stats-overview.component";
import { StatSalesPerYearComponent } from "./stat-sales-per-year/stat-sales-per-year.component";
import { StatSalesTrendComponent } from "./stat-sales-trend/stat-sales-trend.component";
import { NgChartsModule } from "ng2-charts";
import { StatisticsResolver } from "./shared/resolver/statistics.resolver";
import { NoDataComponent } from "./shared/components/no-data.component";

@NgModule({
  imports: [StatisticsRoutingModule, SharedModule, NgChartsModule],
  providers: [StatisticsResolver],
  declarations: [StatisticsWelcomeComponent, StatsOverviewComponent, StatSalesPerYearComponent, StatSalesTrendComponent, NoDataComponent]
})
export class StatisticsModule {}
