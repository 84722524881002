import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { HeaderFactory } from "../core/utils/header-factory";
import { ShowCompanyComponent } from "./show-company/show-company.component";
import { CompanyRoutingModule } from "./company-routing.module";
import { EditCompanyDataComponent } from "./edit-company-data/edit-company-data.component";
import { EditBankAccountComponent } from "./edit-bank-account/edit-bank-account.component";

@NgModule({
  imports: [CompanyRoutingModule, SharedModule],
  declarations: [ShowCompanyComponent, EditCompanyDataComponent, EditBankAccountComponent],
  providers: [HeaderFactory],
  exports: []
})
export class CompanyModule {}
