import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { TimeUnit } from "../../shared/enums/time-unit.enum";
import { InvoiceTO } from "../../../shared/generated/transportObjects";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ti-check-invoice",
  templateUrl: "./check-invoice.component.html",
  styleUrls: ["./check-invoice.component.scss"]
})
export class CheckInvoiceComponent {
  @Input()
  invoice: InvoiceTO;

  @Input()
  showControls: boolean;

  @Output()
  back: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  save: EventEmitter<any> = new EventEmitter<any>();

  private translate: TranslateService = inject(TranslateService);

  constructor() {}

  get showCountry(): boolean {
    return this.invoice.billingAddress.country && this.invoice.billingAddress.country.toLocaleLowerCase() !== "germany";
  }

  cancel(): void {
    this.back.emit();
  }

  saveInvoice(): void {
    this.save.emit();
  }

  get dueString(): string {
    let unit: string;
    if (this.invoice.invoiceData.timeUnit === TimeUnit.DAYS) {
      unit = this.translate.instant("invoice.newInvoice.editInvoiceData.days");
    } else if (this.invoice.invoiceData.timeUnit === TimeUnit.MONTHS) {
      unit = this.translate.instant("invoice.newInvoice.editInvoiceData.months");
    } else if (this.invoice.invoiceData.timeUnit === TimeUnit.WEEKS) {
      unit = this.translate.instant("invoice.newInvoice.editInvoiceData.weeks");
    }
    return this.invoice.invoiceData.timeForPayment.toString() + unit;
  }
}
