import { CustomerService } from "../../core/services/customer.service";
import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BusinessCustomerTO, CustomerTO, FreelanceCustomerTO, PrivateCustomerTO } from "../../shared/generated/transportObjects";

@Component({
  selector: "ti-show-all-customers",
  templateUrl: "./show-all-customers.component.html",
  styleUrls: ["./show-all-customers.component.scss"]
})
export class ShowAllCustomersComponent implements OnInit {
  privateCustomers: PrivateCustomerTO[] = [];
  businessCustomers: BusinessCustomerTO[] = [];
  freelanceCustomer: FreelanceCustomerTO[] = [];
  allPrivateCustomers: number;
  allBusinessCustomers: number;
  allFreelanceCustomers: number;

  private cs: CustomerService = inject(CustomerService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);

  constructor() {}

  private fillData(res: CustomerTO[]): void {
    this.privateCustomers = res.filter((cu: CustomerTO) => cu.type === "private").map((value: CustomerTO) => value as PrivateCustomerTO);
    this.allPrivateCustomers = res.filter((cu: CustomerTO) => cu.type === "private").length;
    this.businessCustomers = res.filter((cu: CustomerTO) => cu.type === "business").map((value: CustomerTO) => value as BusinessCustomerTO);
    this.allBusinessCustomers = res.filter((cu: CustomerTO) => cu.type === "business").length;
    this.freelanceCustomer = res
      .filter((cu: CustomerTO) => cu.type === "freelance")
      .map((value: CustomerTO) => value as FreelanceCustomerTO);
    this.allFreelanceCustomers = res.filter((cu: CustomerTO) => cu.type === "freelance").length;
  }

  private loadData(): void {
    const searchTerm: string = this.route.snapshot.params["search"];
    if (searchTerm) {
      this.cs.searchCustomers(searchTerm).subscribe({
        next: (value: CustomerTO[]) => this.fillData(value)
      });
    } else {
      this.cs.getAllCustomers().subscribe({
        next: (value: CustomerTO[]) => this.fillData(value)
      });
    }
  }

  showCustomer(customerNumber: string): void {
    this.router.navigateByUrl("/customers/show/" + customerNumber);
  }

  ngOnInit(): void {
    this.loadData();
  }
}
