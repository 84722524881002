import { Component, Input } from "@angular/core";

@Component({
  selector: "ti-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"]
})
export class MessageComponent {
  @Input()
  headline: string;

  @Input()
  content: string;

  @Input()
  icon: string;

  @Input()
  loading: boolean = false;

  @Input()
  type: "info" = "info";
}
