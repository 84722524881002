<div class="modal">
  <h2 class="header">
    {{ (editMode ? "common.edit" : "common.create") | translate }} {{ "customer.shared.modals.createCredit.credit" | translate }}
  </h2>
  <div class="content">
    <div class="two fields">
      <div class="customer-select">
        <mat-form-field>
          <mat-label>{{ "customer.shared.modals.createCredit.chooseCustomer" | translate }}</mat-label>
          <mat-select e2e="select-credit-customer" [(value)]="selectedCustomer" [disabled]="editMode" class="selection">
            <mat-option *ngFor="let option of customerHeaders" [value]="option">
              {{ option.customerName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <span *ngIf="selectedCustomer" class="current-customer">
        {{ selectedCustomer.customerName }} ({{ selectedCustomer.customerNumber }} ) - {{ selectedCustomer.type }}
      </span>
    </div>
    <div *ngIf="selectedCustomer" class="form-container">
      <form (keyup.enter)="save()" [formGroup]="formGroup" novalidate>
        <div class="two fields">
          <div [ngClass]="{ error: formGroup.errors || hasError('creditDate') }" class="field">
            <mat-form-field>
              <mat-label>{{ "customer.shared.modals.createCredit.creditDate" | translate }}</mat-label>
              <input e2e="input-effort-date" [matDatepicker]="datePicker" formControlName="creditDate" matInput />
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div [ngClass]="{ error: formGroup.errors || hasError('creditMonth') }" class="field">
            <mat-form-field>
              <mat-label>{{ "customer.shared.modals.createCredit.creditMonth" | translate }}</mat-label>
              <input e2e="input-effort-month" [matDatepicker]="monthPicker" formControlName="creditMonth" matInput />
              <mat-datepicker-toggle [for]="monthPicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #monthPicker (monthSelected)="selectMonth($event, monthPicker)" startView="year"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div [ngClass]="{ error: hasError('hours') }" class="field">
          <mat-form-field>
            <mat-label>{{ "customer.shared.modals.createCredit.creditAmount" | translate }}</mat-label>
            <input e2e="input-credit-amount" formControlName="amount" matInput name="amount" placeholder="120.00 €" type="number" />
            <mat-error *ngIf="hasError('amount')">{{ "formValidation.customer.credit.required.amount" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div [ngClass]="{ error: hasError('hours') }" class="field">
          <mat-form-field>
            <mat-label>{{ "customer.shared.modals.createCredit.note" | translate }}</mat-label>
            <mat-error *ngIf="hasError('note')">{{ "formValidation.customer.credit.required.note" | translate }}</mat-error>
            <input
              e2e="input-credit-note"
              [maxlength]="500"
              formControlName="note"
              matInput
              name="note"
              placeholder="Credit WKS 12/20"
              type="text" />
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="controls actions">
    <div class="spacer"></div>
    <button e2e="btn-cancel" (click)="cancel()" color="warn" mat-button>{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" (click)="save()" [disabled]="formGroup.invalid" color="primary" mat-raised-button>
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
