<div class="show-company-container">
  <div class="company-data">
    <div class="left">
      <div e2e="card-company-data" class="item ti-card">
        <til-divider actionButtonIcon="edit" (buttonClicked)="editCompany = true">{{
          "company.showCompany.companyDetails" | translate
        }}</til-divider>
        <div *ngIf="company && !editCompany">
          <div *ngIf="company.name">{{ company.name }}</div>
          <div *ngIf="company.legalForm">{{ company.legalForm }}</div>
          <div *ngIf="company.taxId">{{ company.taxId }}</div>
          <div *ngIf="company.fon">{{ company.fon }}</div>
          <div *ngIf="company.webAddress">{{ company.webAddress }}</div>
          <div *ngIf="company.mailAddress">{{ company.mailAddress }}</div>
          <div *ngIf="company.invoiceTemplateName">
            {{ "company.showCompany.invoiceTemplateName" | translate }} : {{ company.invoiceTemplateName }}
          </div>
        </div>
        <ti-edit-company-data
          *ngIf="editCompany"
          [company]="company"
          [invoiceTemplates]="invoiceTemplates"
          (cancel)="editCompany = false"
          (updated)="updateCompany($event)"></ti-edit-company-data>
      </div>
      <div e2e="card-company-address" class="item ti-card">
        <til-divider actionButtonIcon="edit" (buttonClicked)="editAddress = true">{{
          "company.showCompany.companyAddress" | translate
        }}</til-divider>
        <div *ngIf="company.address && !editAddress; else noAddress">
          <div *ngIf="company.address.street && company.address.streetNumber">
            {{ company.address.street }} {{ company.address.streetNumber }}
          </div>
          <div *ngIf="company.address.addressInfo">{{ company.address.addressInfo }}</div>
          <div *ngIf="company.address.zip && company.address.city">{{ company.address.zip }} {{ company.address.city }}</div>
        </div>
        <ng-template #noAddress>
          <div *ngIf="!editAddress" class="no-data">
            {{ "company.showCompany.noCompanyAddressAvailable" | translate }}
          </div>
        </ng-template>
        <ti-manage-address
          *ngIf="editAddress"
          (cancel)="editAddress = false"
          (created)="updateAddress($event)"
          [address]="company.address"></ti-manage-address>
      </div>
    </div>
    <div class="right">
      <div e2e="card-company-bank-account-details" class="item ti-card">
        <til-divider actionButtonIcon="edit" (buttonClicked)="editBankAccountDetails = true">{{
          "company.showCompany.bankAccountDetails" | translate
        }}</til-divider>
        <div *ngIf="company.bankAccount && !editBankAccountDetails; else noBankAccountDetails">
          <div>{{ company.bankAccount.bankname }}</div>
          <div>{{ company.bankAccount.iban }}</div>
          <div>{{ company.bankAccount.bic }}</div>
        </div>
        <ng-template #noBankAccountDetails>
          <div *ngIf="!editBankAccountDetails" class="no-data">
            {{ "company.showCompany.noCompanyBankAccountDetailsAvailable" | translate }}
          </div>
        </ng-template>
        <ti-edit-bank-account
          *ngIf="editBankAccountDetails"
          (cancel)="editBankAccountDetails = false"
          (created)="updateBankDetails($event)"
          [bankAccount]="company.bankAccount"></ti-edit-bank-account>
      </div>
    </div>
  </div>
</div>
