import { Component, EventEmitter, inject, Input, Output, ViewChild } from "@angular/core";
import { PositionListComponent } from "../../shared/components/position-list/position-list.component";
import { ApplicationStateService } from "../../../core/services/application-state.service";
import { PositionTOUnion } from "../../../shared/generated/transportObjects";

@Component({
  selector: "ti-edit-invoice-possitions",
  templateUrl: "./edit-invoice-positions.component.html",
  styleUrls: ["./edit-invoice-positions.component.scss"]
})
export class EditInvoicePositionsComponent {
  @ViewChild(PositionListComponent, { static: true })
  private positions: PositionListComponent;

  @Input()
  taxInPercent: number;

  @Output()
  created: EventEmitter<PositionTOUnion[]> = new EventEmitter<PositionTOUnion[]>();

  @Output()
  back: EventEmitter<any> = new EventEmitter<any>();

  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);

  constructor() {}

  getPositions(): PositionTOUnion[] {
    const step: any = this.applicationStateService.getInvoiceStep(5);
    if (step) {
      return step;
    } else {
      return null;
    }
  }

  get posLength(): number {
    if (this.positions.positions) {
      return this.positions.positions.length;
    } else {
      return 0;
    }
  }

  save(): void {
    this.created.emit(this.positions.positions);
  }

  cancel(): void {
    if (this.posLength > 0) {
      this.applicationStateService.setInvoiceStep(5, this.positions.positions);
    }
    this.back.emit();
  }
}
