import { ApiEndpoints } from "../../shared/utils/api-endpoints";
import { inject, Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PreferredLanguage, UserContextTO } from "../../shared/generated/transportObjects";

@Injectable()
export class UserService {
  private http: HttpClient = inject(HttpClient);
  constructor(@Inject("SERVER_URL") private serverUrl: string) {}

  loginCmsUser(token: string): Observable<UserContextTO> {
    const url: string = this.serverUrl + ApiEndpoints.loginCmsUser;
    return this.http.post<UserContextTO>(url, token);
  }

  updatePreferredLanguage(username, preferredLanguage: string): Observable<PreferredLanguage> {
    const url: string = this.serverUrl + ApiEndpoints.updatePreferredLanguage;
    return this.http.put<PreferredLanguage>(url, preferredLanguage);
  }
}
