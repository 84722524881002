import { AfterContentInit, Component, inject, ViewChild } from "@angular/core";
import { ApplicationStateService } from "../../core/services/application-state.service";
import { TimeUnit } from "../shared/enums/time-unit.enum";
import { CalculationHelper } from "../shared/utils/calculation-helper";
import { InvoiceService } from "../../core/services/invoice.service";
import { MatStepper } from "@angular/material/stepper";
import {
  BillingAddressTO,
  CustomerInvoiceTO,
  CustomerInvoiceType,
  InvoiceDataTO,
  InvoiceTO,
  PositionTOUnion,
  SearchCustomerTO
} from "../../shared/generated/transportObjects";
import { SpinnerService } from "ti-frontend-shared";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ti-new-invoice",
  templateUrl: "./new-invoice.component.html",
  styleUrls: ["./new-invoice.component.scss"]
})
export class NewInvoiceComponent implements AfterContentInit {
  @ViewChild("stepper", { static: true })
  private stepper: MatStepper;

  step: number;

  searchCustomer: SearchCustomerTO;
  billingAddress: BillingAddressTO;
  invoiceData: InvoiceDataTO;
  positions: PositionTOUnion[];
  invoiceType: CustomerInvoiceType;

  saved: InvoiceTO;
  loading: boolean;

  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private is: InvoiceService = inject(InvoiceService);
  private spinner: SpinnerService = inject(SpinnerService);
  private translate: TranslateService = inject(TranslateService);

  ngAfterContentInit(): void {
    this.checkSteps();
  }

  selectCustomer(customer: SearchCustomerTO): void {
    this.applicationStateService.clearSteps();
    this.billingAddress = undefined;
    this.invoiceData = undefined;
    this.positions = undefined;
    this.searchCustomer = customer;
    this.applicationStateService.setInvoiceStep(1, this.searchCustomer);
    this.step++;
    this.stepper.next();
  }

  selectInvoiceType(invoiceType: CustomerInvoiceType): void {
    this.invoiceType = invoiceType;
    this.applicationStateService.setInvoiceStep(2, this.invoiceType);
    this.step++;
    this.stepper.next();
  }

  selectBillingAddress(billingAddress: BillingAddressTO): void {
    this.billingAddress = billingAddress;
    this.applicationStateService.setInvoiceStep(3, this.billingAddress);
    this.step++;
    this.stepper.next();
  }

  selectInvoiceData(invoiceData: InvoiceDataTO): void {
    this.invoiceData = invoiceData;
    this.applicationStateService.setInvoiceStep(4, this.invoiceData);
    this.step++;
    this.stepper.next();
  }

  selectInvoicePositions(positions: PositionTOUnion[]): void {
    this.positions = positions;
    this.stepper.steps.get(0).completed = true;
    this.stepper.steps.get(1).completed = true;
    this.stepper.steps.get(2).completed = true;
    this.stepper.steps.get(3).completed = true;
    this.stepper.steps.get(4).completed = true;
    this.applicationStateService.setInvoiceStep(5, this.positions);
    this.step++;
    this.stepper.next();
  }

  getInvoice(): CustomerInvoiceTO {
    return {
      created: undefined,
      due: undefined,
      gross: undefined,
      id: undefined,
      invoiceNumber: undefined,
      invoiceNumberString: undefined,
      net: undefined,
      tax: undefined,
      version: undefined,
      type: "customer",
      customerInvoiceType: this.invoiceType,
      billingAddress: this.billingAddress,
      positions: this.positions,
      invoiceData: this.invoiceData
    };
  }

  createStandardInvoiceData(): InvoiceDataTO {
    const step: any = this.applicationStateService.getInvoiceStep(4);
    if (step) {
      return step;
    }
    let salutation: string;
    if (this.billingAddress) {
      if (this.billingAddress.salutation && this.billingAddress.salutation.toLowerCase() === "herr") {
        salutation = "Sehr geehrter Herr " + this.billingAddress.lastName + ",";
      }
      if (this.billingAddress.salutation && this.billingAddress.salutation.toLowerCase() === "frau") {
        salutation = "Sehr geehrte Frau " + this.billingAddress.lastName + ",";
      }
    }
    if (!salutation) {
      salutation = "Sehr geehrte Damen und Herren,";
    }
    const now: Date = new Date();
    return {
      salutation: salutation,
      timeUnit: TimeUnit.DAYS,
      timeForPayment: 30,
      subject: "Kostenrechnung",
      paid: false,
      numberPrefix: now.getFullYear().toString(),
      invoiceTextBefore: "vielen Dank für Ihren Auftrag. Hierfür berechnen wir folgende Leistungen:",
      invoiceTextAfter: "",
      invoiceDate: now,
      taxInPercent: 19,
      signer: undefined,
      dueString: undefined
    } as any;
  }

  get totalAmountNet(): number {
    return this.positions
      .map((value: PositionTOUnion) => CalculationHelper.getPositionNet(value))
      .reduce((previousValue: number, currentValue: number) => currentValue + previousValue);
  }

  private checkSteps(): void {
    this.step = 1;
    const step1: any = this.applicationStateService.getInvoiceStep(1);
    if (step1) {
      this.searchCustomer = step1;
      this.step = 2;
    }
    const step2: any = this.applicationStateService.getInvoiceStep(2);
    if (step2) {
      this.invoiceType = step2;
      this.step = 3;
    }

    const step3: any = this.applicationStateService.getInvoiceStep(3);
    if (step3) {
      this.billingAddress = step3;
      this.step = 4;
    }
    const step4: any = this.applicationStateService.getInvoiceStep(4);
    if (step4) {
      this.invoiceData = step4;
      this.step = 5;
    }
    const step5: any = this.applicationStateService.getInvoiceStep(5);
    if (step5) {
      this.positions = step5;
      this.step = 6;
    }
    if (this.applicationStateService.getInvoiceCopy() !== null) {
      this.step = 5;
      this.stepper.selectedIndex = 4;
      this.searchCustomer = step1;
      this.step = 3;
      this.invoiceType = step2;
      this.billingAddress = step3;
      this.invoiceData = step4;
      this.positions = step5;
    }
  }

  saveInvoice(): void {
    this.spinner.spin(true);
    this.is.saveInvoice(this.getInvoice(), this.searchCustomer.customerNumber).subscribe({
      next: (res: InvoiceTO) => {
        this.spinner.spin(false);
        this.saved = res;
        this.applicationStateService.clearSteps();
        this.clearCollectedData();
        this.step++;
        this.stepper.next();
      },
      error: () => this.spinner.spin(false)
    });
  }

  private clearCollectedData(): void {
    this.searchCustomer = undefined;
    this.positions = undefined;
    this.invoiceData = undefined;
    this.billingAddress = undefined;
  }

  backToStart(): void {
    this.clearCollectedData();
    this.applicationStateService.clearSteps();
    this.stepper.selectedIndex = 0;
    this.step = 1;
  }

  backTo(step: number): void {
    for (let i: number = 0; i < this.stepper.selectedIndex; i++) {
      this.stepper.steps.get(i).completed = true;
    }
    this.step = step;
    this.stepper.selectedIndex = step - 1;
  }
}
