import { Component } from "@angular/core";

@Component({
  selector: "ti-no-data",
  templateUrl: "./no-data.component.html",
  styleUrls: ["./no-data.component.scss"]
})
export class NoDataComponent {
  constructor() {}
}
