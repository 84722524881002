import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { CustomerService } from "../../core/services/customer.service";
import { CreditListComponent } from "../shared/components/credit-list/credit-list.component";
import { CreateCreditModalComponent } from "../shared/modals/create-credit-modal/create-credit-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Page, SpinnerService } from "ti-frontend-shared";
import { TiDatePipe } from "../../shared/pipes/ti-date-pipe";
import { CreditsService } from "../../core/services/credits.service";
import { CreditTO } from "../../shared/generated/transportObjects";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "ti-credits",
  templateUrl: "./credits.component.html",
  styleUrls: ["./credits.component.scss"]
})
export class CreditsComponent implements OnInit {
  customerHeaders: any[];
  searchFor: string;
  page: Page<CreditTO>;

  @ViewChild(CreditListComponent, { static: true })
  private cl: CreditListComponent;

  private cs: CustomerService = inject(CustomerService);
  private creditService: CreditsService = inject(CreditsService);
  private spinner: SpinnerService = inject(SpinnerService);
  private dialog: MatDialog = inject(MatDialog);
  private datePipe: TiDatePipe = inject(TiDatePipe);
  constructor() {}
  ngOnInit(): void {
    this.cs.loadCustomerHeaders().subscribe({
      next: (res: any[]) => (this.customerHeaders = res)
    });
    this.loadCredits();
  }

  loadCredits(): void {
    this.creditService.loadCredits(0).subscribe({
      next: (value: Page<CreditTO>) => {
        this.page = value;
      },
      complete: () => (this.cl.tableDataSource = new MatTableDataSource<CreditTO>(this.page.content))
    });
  }

  openCreditModal(): void {
    this.dialog
      .open(CreateCreditModalComponent, { data: { customerHeaders: this.customerHeaders } })
      .afterClosed()
      .subscribe({
        next: (res: CreditTO) => {
          if (res) {
            this.createCredit(res);
          }
        }
      });
  }

  editCredit(credit: CreditTO): void {
    this.dialog
      .open(CreateCreditModalComponent, { data: { customerHeaders: this.customerHeaders, credit: credit, editMode: true } })
      .afterClosed()
      .subscribe({
        next: (res: CreditTO) => {
          if (res) {
            this.updateCredit(res);
          }
        }
      });
  }

  deleteCredit(credit: CreditTO): void {
    this.creditService.deleteCredit(credit.id).subscribe({
      complete: () => {
        this.loadPage(this.cl.paginator.pageIndex || 0);
      }
    });
  }

  processPageEvent(pageEvent: PageEvent): void {
    if (pageEvent.pageIndex !== this.page.number || pageEvent.pageSize !== this.page.size) {
      this.loadPage(pageEvent.pageIndex);
    }
  }

  loadPage(page: number): void {
    this.spinner.spin(true);
    if (this.searchFor) {
      this.creditService.loadCredits(page).subscribe({
        next: (res: Page<CreditTO>) => {
          this.cl.page = res;
          this.cl.updateRows();
          this.spinner.spin(false);
        }
      });
    } else {
      this.creditService.loadCredits(page).subscribe({
        next: (res: Page<CreditTO>) => {
          this.cl.page = res;
          this.cl.updateRows();
          this.spinner.spin(false);
        }
      });
    }
  }

  private createCredit(credit: CreditTO): void {
    this.creditService.saveCredit(credit.customer, credit).subscribe({
      complete: () => {
        this.loadPage(this.cl.paginator?.pageIndex || 0);
      }
    });
  }

  private updateCredit(credit: CreditTO): void {
    this.creditService.updateCredit(credit).subscribe({
      complete: () => {
        this.loadPage(this.cl.paginator.pageIndex || 0);
      }
    });
  }
}
