import { Observable, throwError as observableThrowError } from "rxjs";
import { inject, Injectable } from "@angular/core";
import { LoginModalComponent } from "../../shared/modals/login-modal/login-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class ErrorResponseService {
  private dialog: MatDialog = inject(MatDialog);
  constructor() {}

  handleErrorResponse(err: any): Observable<never> {
    if (err.status === 401) {
      this.dialog.open(LoginModalComponent);
    }
    return observableThrowError(err || "backend server error");
  }
}
