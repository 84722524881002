<div class="headline">
  <h2>{{ "invoice.editInvoice.editInvoice" | translate }}</h2>
</div>

<div class="edit-container">
  <div class="customer-type">
    <til-divider icon="person_pin">{{ "invoice.common.invoiceType" | translate }}</til-divider>
    <ti-edit-invoice-type [invoiceType]="invoiceType" (typeSelected)="selectInvoiceType($event)"></ti-edit-invoice-type>
  </div>
  <div class="address">
    <til-divider icon="local_post_office">{{ "common.billingAddress" | translate }}</til-divider>
    <ti-manage-billing-address [edit]="invoice.billingAddress"></ti-manage-billing-address>
  </div>
  <div class="data">
    <til-divider icon="list_alt">{{ "invoice.editInvoice.invoiceData" | translate }}</til-divider>
    <ti-edit-invoice-data [invoiceData]="invoice.invoiceData"></ti-edit-invoice-data>
  </div>
  <div class="positions">
    <til-divider icon="message">{{ "invoice.common.invoicePositions" | translate }}</til-divider>
    <ti-position-list [editable]="true" [inputPositions]="invoice.positions"></ti-position-list>
  </div>
  <div class="controls">
    <button e2e="btn-invoice-delete" (click)="checkDelete()" color="warn" mat-raised-button>{{ "common.delete" | translate }}</button>
    <div class="flex-spacer"></div>
    <button (click)="cancelEdit()" mat-button>{{ "common.cancel" | translate }}</button>
    <button e2e="btn-invoice-update" [disabled]="!savable" (click)="checkSave()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
