import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedIn } from "../shared/guards/logged-in";
import { NewInvoiceComponent } from "./new-invoice/new-invoice.component";
import { AllInvoicesComponent } from "./all-invoices/all-invoices.component";
import { InvoiceWelcomeComponent } from "./invoice-welcome/invoice-welcome.component";
import { SearchInvoiceByCustomerComponent } from "./search-invoice-by-customer/search-invoice-by-customer.component";
import { AllOpenInvoicesComponent } from "./all-open-invoices/all-open-invoices.component";
import { CreditsComponent } from "../customer/credits/credits.component";
import { ProfitReportingComponent } from "./profit-reporting/profit-reporting.component";
import { ListSalesPositionsComponent } from "./list-sales-positions/list-sales-positions.component";

const routes: Routes = [
  { path: "invoices/new", component: NewInvoiceComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/all", component: AllInvoicesComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/open", component: AllOpenInvoicesComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/welcome", component: InvoiceWelcomeComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/search-customer", component: SearchInvoiceByCustomerComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/customer/:customerId", component: SearchInvoiceByCustomerComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/credits", component: CreditsComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/report-profit", component: ProfitReportingComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "invoices/list-sales", component: ListSalesPositionsComponent, outlet: "primary", canActivate: [LoggedIn] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoiceRoutingModule {}
