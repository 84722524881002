import { Component, inject, OnInit } from "@angular/core";
import { StatisticsService } from "../../statistics/shared/services/statistics.service";
import { QuickStatisticTO } from "../../shared/generated/transportObjects";
import { ApplicationStateService } from "../../core/services/application-state.service";
import { Router } from "@angular/router";

@Component({
  selector: "ti-invoice-welcome",
  templateUrl: "./invoice-welcome.component.html",
  styleUrls: ["./invoice-welcome.component.scss"]
})
export class InvoiceWelcomeComponent implements OnInit {
  quickStats: QuickStatisticTO;

  private statisticsService: StatisticsService = inject(StatisticsService);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private router: Router = inject(Router);

  constructor() {}

  ngOnInit(): void {
    this.statisticsService.getQuickStatistics().subscribe({
      next: (res: QuickStatisticTO) => (this.quickStats = res)
    });
  }

  navigateToCreateNewInvoice(): void {
    this.applicationStateService.clearSteps();
    this.router.navigateByUrl("/invoices/new");
  }
}
