import {
  AmountPositionTO,
  FreelancerPositionTO,
  HourPositionTO,
  PositionTOUnion,
  UnitPositionTO
} from "../../../shared/generated/transportObjects";

export class CalculationHelper {
  static getPositionNet(pos: PositionTOUnion): number {
    let amount: number;
    switch (pos.type) {
      case "hours": {
        const hours: HourPositionTO = pos as HourPositionTO;
        amount = hours.hourlyRate * hours.hours;
        break;
      }
      case "freelancer": {
        const fp: FreelancerPositionTO = pos as FreelancerPositionTO;
        amount = fp.hourlyRate * fp.hours;
        break;
      }
      case "units": {
        const units: UnitPositionTO = pos as UnitPositionTO;
        amount = units.price * units.units;
        break;
      }
      case "amount": {
        const amp: AmountPositionTO = pos as AmountPositionTO;
        amount = amp.amount;
        break;
      }
    }
    return amount;
  }
}
