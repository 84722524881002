import { AfterViewInit, Component, inject, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatInput } from "@angular/material/input";

@Component({
  selector: "ti-search-customer",
  templateUrl: "./search-customer.component.html",
  styleUrls: ["./search-customer.component.scss"]
})
export class SearchCustomerComponent implements OnInit, AfterViewInit {
  formGroup: UntypedFormGroup;

  @ViewChild("searchinput", { static: true })
  private searchField: MatInput;

  private router: Router = inject(Router);
  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  constructor() {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      searchTerm: this.fb.control("")
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchField.focus();
    }, 300);
  }

  search(): void {
    if (this.formGroup.valid) {
      this.router.navigateByUrl("/customers/search/" + this.formGroup.get("searchTerm").value);
    } else {
      this.router.navigateByUrl("/customers/all");
    }
  }
}
