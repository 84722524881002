<til-divider icon="face">{{ headline }} ({{ from }} {{ "invoice.shared.components.invoiceCards.from" | translate }} {{ to }}) </til-divider>
<div class="ti-cards floating">
  <div *ngFor="let invoice of invoices" class="ti-card">
    <div class="headline">
      <div class="customer">{{ getReceiver(invoice.billingAddress) }}</div>
      <div class="subheader">{{ invoice.invoiceNumberString }}</div>
      <div class="headline-controls">
        <mat-icon (click)="showDetailsClicked.emit(invoice)" class="selectable">search</mat-icon>
        <mat-icon e2e="btn-edit-invoice-menu" [matMenuTriggerFor]="cusmenu" class="selectable">edit</mat-icon>
        <mat-menu #cusmenu="matMenu">
          <button e2e="btn-edit-invoice" (click)="editInvoiceClicked.emit(invoice)" mat-menu-item>
            <mat-icon>edit</mat-icon> {{ "common.edit" | translate }}
          </button>
          <button e2e="btn-copy-invoice" (click)="copyInvoiceClicked.emit(invoice)" mat-menu-item>
            <mat-icon>add</mat-icon> {{ "invoice.shared.components.invoiceCards.copy" | translate }}
          </button>
          <button e2e="btn-correct-invoice" (click)="correctInvoiceClicked.emit(invoice)" mat-menu-item>
            <mat-icon>rule</mat-icon> {{ "invoice.shared.components.invoiceCards.correct" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="state">
      <div>
        <span class="animated fadeIn" *ngIf="invoice.invoiceData.paid">
          <mat-icon class="positive">done_all</mat-icon> {{ "invoice.shared.components.invoiceCards.paid" | translate }}
        </span>
        <span class="animated fadeIn" *ngIf="!invoice.invoiceData.paid && !isDue(invoice)">
          <mat-icon class="info">info</mat-icon> {{ "invoice.common.open" | translate }}
        </span>
        <span class="animated fadeIn" *ngIf="!invoice.invoiceData.paid && isDue(invoice)">
          <mat-icon class="warn">gavel</mat-icon> {{ "invoice.common.due" | translate }}
        </span>
      </div>
      <div class="freelance-invoice" *ngIf="checkCustomerInvoiceType(invoice)">
        <span>
          <mat-icon>transfer_within_a_station</mat-icon>
          {{ "common.freelance" | translate }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="details">
      <div class="desc-content">
        <p>{{ "invoice.shared.components.invoiceCards.created on" | translate }}:</p>
        <p>{{ "invoice.shared.components.invoiceCards.reDate" | translate }}:</p>
        <p>{{ "invoice.shared.components.invoiceCards.dueOn" | translate }}:</p>
      </div>
      <div class="left-content">
        <p>{{ invoice.created | date }}</p>
        <p>{{ invoice.invoiceData.invoiceDate | tiDate }}</p>
        <p>{{ invoice.due | tiDate }} <i *ngIf="isDue(invoice)" class="red warning circle icon"></i></p>
      </div>
      <div class="spacer"></div>
      <div class="right-content">
        <p>{{ invoice.net | euro }}</p>
        <p>{{ invoice.tax | euro }}</p>
        <p>{{ invoice.gross | euro }}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="actions">
      <span class="animated fadeIn">
        <a (click)="printInvoiceClicked.emit(invoice)">{{ "invoice.common.print" | translate }}</a>
      </span>
      <span class="animated fadeIn" *ngIf="!invoice.invoiceData.paid">
        <a (click)="changePaymentStateClicked.emit(invoice)">{{ "invoice.shared.components.invoiceCards.markAsPaid" | translate }}</a>
      </span>
      <span class="animated fadeIn" *ngIf="invoice.invoiceData.paid">
        <a (click)="changePaymentStateClicked.emit(invoice)">{{ "invoice.shared.components.invoiceCards.markAsOpen" | translate }}</a>
      </span>
    </div>
  </div>

  <div *ngIf="currentPage + 1 < totalPages" class="ti card load-more" (click)="loadMoreClicked.emit()">
    <div class="load-more">
      <span class="material-icons">add_box</span>
      <div>{{ "invoice.shared.components.invoiceCards.loadMore" | translate }}</div>
    </div>
  </div>
</div>
