import { Component, inject, Inject } from "@angular/core";
import { ApplicationStateService } from "../../services/application-state.service";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { UserContextTO } from "../../../shared/generated/transportObjects";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ti-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private userService: UserService = inject(UserService);
  private router: Router = inject(Router);
  translate: TranslateService = inject(TranslateService);
  constructor(@Inject("ADM_SERVER_URL") private adminServerUrl: string) {}

  get admUrl(): string {
    return this.adminServerUrl;
  }

  login(token: string): void {
    this.userService.loginCmsUser(token).subscribe({
      next: (currentUser: UserContextTO) => {
        this.applicationStateService.setCurrentUser(currentUser);
        this.translate.use(this.applicationStateService.getCurrentUser().preferredLanguage);
        if (currentUser.company.name === null) {
          //TODO Company was empty -> redirect to provide company informations
          console.log("-----------------------");
          console.log("Company is empty!");
          console.log("Redirect to create Company information");
          console.log("-----------------------");
          this.router.navigateByUrl("apps");
        } else {
          this.router.navigateByUrl("apps");
        }
      }
    });
  }
}
