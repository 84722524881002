<ng-template #logInView>
  <div class="application-content">
    <mat-toolbar>
      <til-application-toolbar
        sideHeaderWidth="110px"
        (logoutEvent)="logout()"
        applicationName="treeIT CMS"
        [loggedInUser]="fullUserName"
        (logoClickEvent)="navHome()"
        (selectedLanguage)="updateLanguage($event)">
        <button e2e="topnav-btn-customers" routerLink="/customers/welcome" mat-button>{{ "common.customers" | translate }}</button>
        <button e2e="topnav-btn-invoices" routerLink="/invoices/welcome" mat-button>{{ "topNavigation.invoices" | translate }}</button>
        <button e2e="topnav-btn-products" routerLink="/apps/products" mat-button>{{ "common.products" | translate }}</button>
        <button e2e="topnav-btn-stats" routerLink="/statistics/welcome" mat-button>{{ "topNavigation.stats" | translate }}</button>
        <button e2e="topnav-btn-company" (click)="getCompany()" mat-button>
          {{ "customer.manage.businessCustomer.company" | translate }}
        </button>
      </til-application-toolbar>
    </mat-toolbar>

    <mat-sidenav-container [hasBackdrop]="false" class="sidenav">
      <mat-sidenav mode="side" [(opened)]="sideNavOpened" [fixedInViewport]="false">
        <ti-sidenav></ti-sidenav>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="route-content-container">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-template>

<div *ngIf="!loggedIn; else logInView" class="public-container">
  <router-outlet></router-outlet>
</div>

<ti-spinner *ngIf="loading"></ti-spinner>
