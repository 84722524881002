import { Component, inject, OnInit } from "@angular/core";
import { InvoiceService } from "../../core/services/invoice.service";
import { Observable } from "rxjs";
import { MatSelectChange } from "@angular/material/select";
import { InvoiceTO } from "../../shared/generated/transportObjects";
import { Page } from "ti-frontend-shared";

@Component({
  selector: "ti-all-invoices",
  templateUrl: "./all-invoices.component.html",
  styleUrls: ["./all-invoices.component.scss"]
})
export class AllInvoicesComponent implements OnInit {
  invoiceFilter: "ALL" | "OPEN" | "DUE" = "ALL";
  customerPageLoader: (page: number) => Observable<Page<InvoiceTO>>;
  productPageLoader: (page: number) => Observable<Page<InvoiceTO>>;

  private is: InvoiceService = inject(InvoiceService);

  constructor() {}

  ngOnInit(): void {
    this.customerPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllCustomerInvoices(page);
    this.productPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllProductInvoices(page);
  }

  applyFilter(filter: MatSelectChange): void {
    switch (filter.value) {
      case "ALL":
        this.customerPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllCustomerInvoices(page);
        this.productPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllProductInvoices(page);
        break;
      case "OPEN":
        this.customerPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllOpenCustomerInvoices(page);
        this.productPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllOpenProductInvoices(page);
        break;
      case "DUE":
        this.customerPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllDueCustomerInvoices(page);
        this.productPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllDueProductInvoices(page);
        break;
    }
  }
}
