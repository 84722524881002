import { Component, inject, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../../../shared/utils/ValidationHelper";
import { Subscription } from "rxjs/index";
import { Moment } from "moment";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  AmountPositionTO,
  FreelancerPositionTO,
  HourPositionTO,
  PositionTO,
  UnitPositionTO
} from "../../../../shared/generated/transportObjects";
import { InvoicePositionForm } from "../../../form-model/invoice-position-form";

@Component({
  selector: "ti-invoice-position-modal",
  templateUrl: "./invoice-position-modal.component.html",
  styleUrls: ["./invoice-position-modal.component.scss"]
})
export class InvoicePositionModalComponent implements OnInit, OnDestroy {
  position: PositionTO;
  formGroup: FormGroup;
  createMode: boolean;
  type: string;
  typeSelected: boolean;
  private monthSub: Subscription;
  private dateSub: Subscription;

  fb: FormBuilder = inject(FormBuilder);
  dialogRef: MatDialogRef<InvoicePositionModalComponent> = inject(MatDialogRef<InvoicePositionModalComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: PositionTO) {}

  ngOnInit(): void {
    if (this.data) {
      this.position = this.data;
    } else {
      this.createMode = true;
      this.position = {} as any;
    }
    this.type = this.position.type;
    this.typeSelected = false;
    this.createFormGroup();
    this.monthSub = this.formGroup.get("posMonth").valueChanges.subscribe({
      next: (value: any) => {
        if (value) {
          this.formGroup.get("posDate").setValue(undefined);
        }
      }
    });
    this.dateSub = this.formGroup.get("posDate").valueChanges.subscribe({
      next: (value: any) => {
        if (value) {
          this.formGroup.get("posMonth").setValue(undefined);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.monthSub.unsubscribe();
    this.dateSub.unsubscribe();
  }

  private createFormGroup(): void {
    const formGroup: FormGroup<InvoicePositionForm> = this.fb.group<InvoicePositionForm>({
      description: this.fb.control(this.position.description || "", Validators.required),
      posMonth: this.fb.control(this.position.posMonth || ""),
      posDate: this.fb.control(this.position.posDate || "")
    });
    this.formGroup = formGroup;
    if (this.position.type === "amount") {
      this.addAmountControls();
    }

    if (this.position.type === "units") {
      this.addUnitControls();
    }

    if (this.position.type === "hours") {
      this.addHoursControls();
    }

    if (this.position.type === "freelancer") {
      this.addFreelancerControls();
    }
    this.formGroup.setValidators(ValidationHelper.atLeastOneRequired(["posMonth", "posDate"]));
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  selectMonth(month: Moment, dp: any): void {
    this.formGroup.get("posMonth").setValue(month.toDate());
    dp.close();
  }

  save(): void {
    if (this.formGroup.valid) {
      const any: any = this.formGroup.value;
      any.type = this.type;
      if (!this.createMode) {
        any.number = this.position.number;
      }
      this.dialogRef.close(any);
    }
  }

  delete(): void {
    this.dialogRef.close({ delete: true });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  switchType(type: string): void {
    switch (type) {
      case "amount": {
        this.removeUnitControls();
        this.removeHouresControls();
        this.removeFreelancerControls();
        this.addAmountControls();
        this.type = type;
        this.typeSelected = true;
        break;
      }
      case "hours": {
        this.removeAmountControls();
        this.removeUnitControls();
        this.removeFreelancerControls();
        this.addHoursControls();
        this.type = type;
        this.typeSelected = true;
        break;
      }
      case "units": {
        this.removeAmountControls();
        this.removeHouresControls();
        this.removeFreelancerControls();
        this.addUnitControls();
        this.type = type;
        this.typeSelected = true;
        break;
      }
      case "freelancer": {
        this.removeAmountControls();
        this.removeHouresControls();
        this.removeUnitControls();
        this.addFreelancerControls();
        this.type = type;
        this.typeSelected = true;
        break;
      }
    }
  }

  private addUnitControls(): void {
    const up: UnitPositionTO = this.position as UnitPositionTO;
    this.formGroup.addControl("units", this.fb.control(up.units, Validators.required));
    this.formGroup.addControl("price", this.fb.control(up.price, Validators.required));
  }

  private removeUnitControls(): void {
    this.formGroup.removeControl("units");
    this.formGroup.removeControl("price");
  }

  private addHoursControls(): void {
    const hp: HourPositionTO = this.position as HourPositionTO;
    this.formGroup.addControl("hours", this.fb.control(hp.hours, Validators.required));
    this.formGroup.addControl("hourlyRate", this.fb.control(hp.hourlyRate, Validators.required));
  }

  private addFreelancerControls(): void {
    const fp: FreelancerPositionTO = this.position as FreelancerPositionTO;
    this.formGroup.addControl("hours", this.fb.control(fp.hours, Validators.required));
    this.formGroup.addControl("hourlyRate", this.fb.control(fp.hourlyRate, Validators.required));
    this.formGroup.addControl("freelancerRate", this.fb.control(fp.freelancerRate, Validators.required));
  }

  private removeHouresControls(): void {
    this.formGroup.removeControl("hours");
    this.formGroup.removeControl("hourlyRate");
  }

  private removeFreelancerControls(): void {
    this.formGroup.removeControl("hours");
    this.formGroup.removeControl("hourlyRate");
    this.formGroup.removeControl("freelancerRate");
  }

  addAmountControls(): void {
    const amp: AmountPositionTO = this.position as AmountPositionTO;
    this.formGroup.addControl("amount", this.fb.control(amp.amount, Validators.required));
  }

  removeAmountControls(): void {
    this.formGroup.removeControl("amount");
  }
  isDirtyOrTouched(): boolean {
    const posMonth: boolean = this.formGroup.get("posMonth").dirty || this.formGroup.get("posMonth").touched;
    const posDate: boolean = this.formGroup.get("posDate").dirty || this.formGroup.get("posDate").touched;
    if (posMonth || posDate) {
      return true;
    }
    return false;
  }
}
