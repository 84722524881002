<div class="animated fadeIn credits-container">
  <til-divider icon="trending_up">{{ "common.credits" | translate }}</til-divider>
  <div *ngIf="customerHeaders" class="select-customer">
    <mat-form-field>
      <mat-label>{{ "common.search" | translate }}</mat-label>
      <input
        e2e="search-customer-credit"
        #searchinput="matInput"
        (keyup.enter)="loadPage(0)"
        [(ngModel)]="searchFor"
        class="selection"
        matInput
        placeholder="{{ 'customer.credits.modelCompany' | translate }}" />
    </mat-form-field>
    <div class="spacer"></div>
    <button e2e="btn-add-credit" (click)="openCreditModal()" color="primary" mat-mini-fab>
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <ti-credit-list
    (deleted)="deleteCredit($event)"
    (edit)="editCredit($event)"
    (pageEvent)="processPageEvent($event)"
    [itemsPerPageConfigurable]="true"
    [itemsPerPage]="10"
    [noCreditsHint]="true"
    [page]="page"
    [provideFilter]="true"
    [showPagination]="true"></ti-credit-list>
</div>
