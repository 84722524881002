import { BrowserModule } from "@angular/platform-browser";
import { SharedModule } from "./shared/shared.module";
import { LoggedIn } from "./shared/guards/logged-in";
import { Injector, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { CustomerModule } from "./customer/customer.module";
import { CoreModule } from "./core/core.module";
import { CurrencyPipe, DatePipe, registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { StatisticsModule } from "./statistics/statistics.module";
import { InvoiceModule } from "./invoice/invoice.module";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CompanyModule } from "./company/company.module";

registerLocaleData(localeDe, "de-DE", localeDeExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CustomerModule,
    CoreModule,
    StatisticsModule,
    InvoiceModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    CompanyModule
  ],
  providers: [LoggedIn, DatePipe, CurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
