import { ApiEndpoints } from "../../shared/utils/api-endpoints";
import { inject, Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { HeaderFactory } from "../utils/header-factory";
import { Page } from "ti-frontend-shared";
import { CreditTO } from "../../shared/generated/transportObjects";

@Injectable()
export class CreditsService {
  private http: HttpClient = inject(HttpClient);
  private hf: HeaderFactory = inject(HeaderFactory);
  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    this.hf = new HeaderFactory();
  }

  loadCredits(pageNumber: number): Observable<Page<CreditTO>> {
    const url: string = this.serverUrl + ApiEndpoints.getAllCredits;
    return this.http.get<Page<CreditTO>>(url, {
      params: this.hf.create().add("page", pageNumber.toString()).build()
    });
  }

  saveCredit(customerNumber: string, credit: CreditTO): Observable<CreditTO> {
    const url: string = this.serverUrl + ApiEndpoints.createCredit(customerNumber);
    return this.http.post<CreditTO>(url, credit);
  }

  updateCredit(credit: CreditTO): Observable<CreditTO> {
    const url: string = this.serverUrl + ApiEndpoints.updateCredit(credit.id);
    return this.http.put<CreditTO>(url, credit);
  }

  deleteCredit(creditId: number): Observable<Object> {
    const url: string = this.serverUrl + ApiEndpoints.deleteCredit(creditId);
    return this.http.delete(url);
  }
}
