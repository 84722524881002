import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomerInvoiceType } from "../../../shared/generated/transportObjects";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "ti-edit-invoice-type",
  templateUrl: "./edit-invoice-type.component.html",
  styleUrls: ["./edit-invoice-type.component.scss"]
})
export class EditInvoiceTypeComponent implements OnInit {
  @Output()
  typeSelected: EventEmitter<CustomerInvoiceType> = new EventEmitter<CustomerInvoiceType>();

  @Output()
  back: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  showControls: boolean;

  @Input()
  invoiceType: CustomerInvoiceType;

  constructor() {}

  ngOnInit(): void {
    if (!this.invoiceType) {
      this.invoiceType = "CUSTOMER";
    }
  }

  selected(invoiceType: MatSelectChange): void {
    this.typeSelected.emit(invoiceType.value);
  }
}
