import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { InvoiceService } from "../../../core/services/invoice.service";
import { DownloadHelper } from "../../shared/utils/download-helper";
import { SpinnerService } from "ti-frontend-shared";
import { InvoiceTO } from "../../../shared/generated/transportObjects";

@Component({
  selector: "ti-finished-invoice",
  templateUrl: "./finished-invoice.component.html",
  styleUrls: ["./finished-invoice.component.scss"]
})
export class FinishedInvoiceComponent {
  @Input()
  invoice: InvoiceTO;

  @Output()
  newInvoice: EventEmitter<any> = new EventEmitter<any>();

  private is: InvoiceService = inject(InvoiceService);
  private spinner: SpinnerService = inject(SpinnerService);
  constructor() {}

  createNewInvoice(): void {
    this.newInvoice.emit();
  }

  print(): void {
    this.spinner.spin(true);
    this.is.printCustomerInvoice(this.invoice.invoiceNumberString).subscribe({
      next: (res: ArrayBuffer) => {
        const blob: Blob = new Blob([res], { type: "application/pdf" });
        const fileName: string = this.invoice.invoiceNumberString + ".pdf";
        DownloadHelper.downloadFile(blob, fileName);
        this.spinner.spin(false);
      },
      error: () => this.spinner.spin(false)
    });
  }
}
