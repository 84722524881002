<div class="invoice-type-container">
  <mat-form-field>
    <mat-label>{{ "invoice.common.invoiceType" | translate }}</mat-label>
    <mat-select e2e="select-invoice-type" [(value)]="invoiceType" (selectionChange)="selected($event)">
      <mat-option value="CUSTOMER">{{ "common.customer" | translate }}</mat-option>
      <mat-option value="FREELANCER">{{ "common.freelancer" | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="showControls" class="controls">
  <button e2e="btn-back" (click)="back.emit()" mat-button color="warn">{{ "common.back" | translate }}</button>
  <button e2e="btn-next" [disabled]="!invoiceType" (click)="typeSelected.emit(invoiceType)" mat-raised-button color="primary">
    {{ "common.next" | translate }}
  </button>
</div>
