<div class="form-container">
  <form novalidate [formGroup]="formGroup">
    <div class="invoice fields">
      <mat-form-field>
        <mat-label>{{ "invoice.common.invoiceDate" | translate }}</mat-label>
        <input e2e="input-invoice-date" matInput formControlName="invoiceDate" [matDatepicker]="datePicker" />
        <mat-error *ngIf="hasError('invoiceDate')">{{ "formValidation.invoice.required.invoiceDate" | translate }}</mat-error>
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "invoice.newInvoice.editInvoiceData.invoiceNumberPrefix" | translate }}</mat-label>
        <input [maxlength]="20" e2e="input-invoice-number-prefix" matInput type="text" formControlName="numberPrefix" />
        <mat-error *ngIf="hasError('numberPrefix')">{{ "formValidation.invoice.required.numberPrefix" | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "invoice.newInvoice.editInvoiceData.tax" | translate }} (%)</mat-label>
        <input e2e="input-invoice-tax" matInput type="number" formControlName="taxInPercent" />
        <mat-error *ngIf="hasError('taxInPercent')">{{ "formValidation.invoice.required.taxInPercent" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="spacer"></div>

    <mat-form-field>
      <mat-label>{{ "invoice.newInvoice.editInvoiceData.subject" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-invoice-subject" matInput type="text" formControlName="subject" />
      <mat-error *ngIf="hasError('subject')">{{ "formValidation.invoice.required.subject" | translate }}</mat-error>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field>
      <mat-label>{{ "customer.manage.common.salutation" | translate }}</mat-label>
      <input [maxlength]="500" e2e="input-invoice-salutation" matInput type="text" formControlName="salutation" />
    </mat-form-field>

    <mat-form-field>
      <mat-label
        >{{ "invoice.newInvoice.editInvoiceData.invoiceText" | translate }} ({{
          "invoice.newInvoice.editInvoiceData.before" | translate
        }})</mat-label
      >
      <textarea
        [maxlength]="500"
        e2e="input-invoice-text-before"
        matInput
        type="text"
        placeholder="{{ 'invoice.newInvoice.editInvoiceData.thankYouForYourOrder' | translate }}."
        formControlName="invoiceTextBefore"></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label
        >{{ "invoice.newInvoice.editInvoiceData.invoiceText" | translate }} ({{
          "invoice.newInvoice.editInvoiceData.after" | translate
        }})</mat-label
      >
      <textarea
        [maxlength]="500"
        e2e="input-invoice-text-after"
        matInput
        type="text"
        placeholder="{{ 'invoice.newInvoice.editInvoiceData.invoicePaidByCreditCard' | translate }}."
        formControlName="invoiceTextAfter"></textarea>
    </mat-form-field>

    <div class="spacer"></div>

    <mat-form-field>
      <mat-label>{{ "invoice.newInvoice.editInvoiceData.signer" | translate }}</mat-label>
      <input
        [maxlength]="100"
        e2e="input-invoice-signer"
        placeholder="{{ 'invoice.newInvoice.editInvoiceData.seniorVicePresidentSoeder' | translate }}"
        matInput
        type="text"
        formControlName="signer" />
    </mat-form-field>

    <div class="group-controls">
      <mat-form-field>
        <mat-label>{{ "invoice.common.due" | translate }}</mat-label>
        <input [maxlength]="500" e2e="input-invoice-due-in" placeholder="30" matInput type="text" formControlName="timeForPayment" />
        <mat-error *ngIf="hasError('timeForPayment')">{{ "formValidation.invoice.required.timeForPayment" | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "invoice.newInvoice.editInvoiceData.unit" | translate }}</mat-label>
        <mat-select e2e="select-invoice-due-unit" formControlName="timeUnit" name="timeUnit">
          <mat-option *ngFor="let opt of timeUnitOptions" [value]="opt.value">
            {{ opt.text }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('timeUnit')">{{ "formValidation.invoice.required.timeUnit" | translate }}</mat-error>
      </mat-form-field>
      <mat-checkbox e2e="checkbox-invoice-already-paid" formControlName="paid">{{
        "invoice.newInvoice.editInvoiceData.alreadyPaid" | translate
      }}</mat-checkbox>
    </div>
  </form>
  <div *ngIf="showControls" class="controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">{{ "common.back" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button color="primary">
      {{ "common.next" | translate }}
    </button>
  </div>
</div>
