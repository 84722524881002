import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../../shared/utils/ValidationHelper";
import { AddressTO, BillingAddressTO, BusinessCustomerTO, CustomerTO, PrivateCustomerTO } from "../../generated/transportObjects";
import { BillingAddressForm } from "../../form-model/billing-address-form";

@Component({
  selector: "ti-manage-billing-address",
  templateUrl: "./manage-billing-address.component.html",
  styleUrls: ["./manage-billing-address.component.scss"]
})
export class ManageBillingAddressComponent implements OnInit {
  formGroup: FormGroup;

  @Output()
  created: EventEmitter<BillingAddressTO> = new EventEmitter<BillingAddressTO>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  cancelLabel: string;

  @Input()
  confLabel: string;

  @Input()
  showControls: boolean;

  @Input()
  edit: BillingAddressTO;

  @Input()
  customer: CustomerTO;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}

  ngOnInit(): void {
    if (this.edit) {
      this.updateBillingAddress();
    } else {
      this.createBillingAddress();
    }
  }

  private createBillingAddress(): void {
    let salutation: string;
    let firstName: string;
    let lastName: string;
    let company: string;
    let customerAddress: AddressTO;
    if (this.customer) {
      if (this.customer.type === "private") {
        const pc: PrivateCustomerTO = this.customer as PrivateCustomerTO;
        salutation = pc.salutation;
        firstName = pc.firstName;
        lastName = pc.lastName;
      }
      if ((this.customer.type = "business")) {
        company = (this.customer as BusinessCustomerTO).companyName;
      }
    }
    if (this.customer.address) {
      customerAddress = this.customer.address;
    } else {
      customerAddress = {
        id: undefined,
        version: undefined,
        street: "",
        streetNumber: "",
        zip: "",
        city: "",
        addressInfo: ""
      };
    }
    const newAddress: BillingAddressTO = {
      degree: undefined,
      id: undefined,
      version: undefined,
      country: undefined,
      salutation: salutation || "",
      addInfo: customerAddress.addressInfo || "",
      city: customerAddress.city || "",
      company: company || "",
      firstName: firstName || "",
      lastName: lastName || "",
      street: customerAddress.street || "",
      streetNumber: customerAddress.streetNumber || "",
      zip: customerAddress.zip || ""
    };
    this.initFormGroup(newAddress);
  }

  private updateBillingAddress(): void {
    this.initFormGroup(this.edit);
  }

  private initFormGroup(billingAddress: BillingAddressTO): void {
    this.formGroup = this.fb.group<BillingAddressForm>(
      {
        id: this.fb.control(billingAddress.id),
        version: this.fb.control(billingAddress.version),
        salutation: this.fb.control(billingAddress.salutation),
        addInfo: this.fb.control(billingAddress.addInfo),
        city: this.fb.control(billingAddress.city, Validators.required),
        company: this.fb.control(billingAddress.company),
        firstName: this.fb.control(billingAddress.firstName),
        lastName: this.fb.control(billingAddress.lastName),
        street: this.fb.control(billingAddress.street, Validators.required),
        streetNumber: this.fb.control(billingAddress.streetNumber, Validators.required),
        zip: this.fb.control(billingAddress.zip, Validators.required)
      },
      {
        validators: [
          ValidationHelper.atLeastOneRequired(["firstName", "company"]),
          ValidationHelper.atLeastOneRequired(["lastName", "company"])
        ]
      }
    );
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  saveAddress(): void {
    if (this.formGroup.get("company").value || (this.formGroup.get("firstName").value && this.formGroup.get("lastName").value)) {
      this.formGroup.get("company").setErrors(null);
      this.formGroup.get("firstName").setErrors(null);
      this.formGroup.get("lastName").setErrors(null);
      if (this.formGroup.valid) {
        this.created.emit(this.formGroup.value);
      }
    } else {
      this.formGroup.get("company").markAsTouched();
      this.formGroup.get("firstName").markAsTouched();
      this.formGroup.get("lastName").markAsTouched();
      this.formGroup.get("company").setErrors({ validBillingAddress: false });
      this.formGroup.get("firstName").setErrors({ validBillingAddress: false });
      this.formGroup.get("lastName").setErrors({ validBillingAddress: false });
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }
}
