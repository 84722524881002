import { NotificationService } from "./../../../core/services/notification.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, inject, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthenticationService } from "ti-frontend-shared";
import { LoginForm } from "../../form-model/login-form";

@Component({
  selector: "ti-login-modal",
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"]
})
export class LoginModalComponent implements OnInit {
  loginForm: FormGroup<LoginForm>;
  dialogRef: MatDialogRef<LoginModalComponent> = inject(MatDialogRef<LoginModalComponent>);
  private fb: FormBuilder = inject(FormBuilder);
  private router: Router = inject(Router);
  private as: AuthenticationService = inject(AuthenticationService);
  private ns: NotificationService = inject(NotificationService);
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, @Inject("ADM_SERVER_URL") private adminServerUrl: string) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.loginForm = this.fb.group<LoginForm>({
      un: this.fb.control("", Validators.required),
      pw: this.fb.control("", Validators.required)
    });
  }

  login(): void {
    this.as.authenticateUser(this.loginForm.value.un, this.loginForm.value.pw, this.adminServerUrl).subscribe({
      next: () => {
        this.redirect(true);
      },
      error: () => this.redirect(false)
    });
  }

  private redirect(success: boolean): void {
    if (success) {
      if (this.data) {
        this.router.navigateByUrl(this.data);
      }
      this.dialogRef.close();
      this.ns.userInfoChanged();
    }
  }
}
