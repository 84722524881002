<div class="invoice-details">
  <div class="address">
    <div *ngIf="!invoice.billingAddress.company">{{ invoice.billingAddress.salutation }}</div>
    <div *ngIf="invoice.billingAddress.company">{{ invoice.billingAddress.company }}</div>
    <div>{{ invoice.billingAddress.firstName }} {{ invoice.billingAddress.lastName }}</div>
    <div *ngIf="invoice.billingAddress.addInfo">{{ invoice.billingAddress.addInfo }}</div>
    <div>{{ invoice.billingAddress.street }} {{ invoice.billingAddress.streetNumber }}</div>
    <div>{{ invoice.billingAddress.zip }} {{ invoice.billingAddress.city }}</div>
    <div *ngIf="showCountry">{{ invoice.billingAddress.country }}</div>
  </div>

  <div class="invoice-info">
    <div>{{ "invoice.newInvoice.checkInvoice.numberPrefix" | translate }}: {{ invoice.invoiceData.numberPrefix }}</div>
    <div>{{ "invoice.common.invoiceDate" | translate }}: {{ invoice.invoiceData.invoiceDate | date }}</div>
    <div>{{ "invoice.newInvoice.checkInvoice.dueIn" | translate }}: {{ dueString }}</div>
  </div>

  <div class="subject">{{ invoice.invoiceData.subject }}</div>
  <div class="salutation">{{ invoice.invoiceData.salutation }}</div>
  <div class="invoice-text">
    <pre>{{ invoice.invoiceData.invoiceTextBefore }}</pre>
  </div>
  <div class="positions">
    <ti-position-list
      [taxInPercent]="invoice.invoiceData.taxInPercent"
      [inputPositions]="invoice.positions"
      [editable]="false"></ti-position-list>
  </div>
  <div class="invoice-text-after">
    <pre>{{ invoice.invoiceData.invoiceTextAfter }}</pre>
  </div>
  <div *ngIf="invoice.invoiceData.dueString" class="due-string">
    {{ invoice.invoiceData.dueString }}
  </div>

  <div>{{ "invoice.newInvoice.checkInvoice.bestRegards" | translate }},</div>
  <div class="signer">{{ invoice.invoiceData.signer || "treeIT GmbH & Co. KG" }}</div>
</div>
<div *ngIf="showControls" class="controls">
  <button e2e="btn-cancel" (click)="cancel()" mat-button color="warn">{{ "common.back" | translate }}</button>
  <button e2e="btn-save" (click)="saveInvoice()" mat-raised-button color="primary">{{ "common.save" | translate }}</button>
</div>
