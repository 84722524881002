<div class="form-container">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="save()">
    <div class="multi fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.salutation" | translate }}</mat-label>
        <mat-select e2e="select-salutation" formControlName="salutation" name="salutation">
          <mat-option *ngFor="let sal of ['Mr', 'Ms']" [value]="sal">
            {{ "customer.manage.freelanceCustomer.salutation." + sal | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('salutation')">{{ "formValidation.common.required.salutation" | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "customer.manage.common.firstname" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-firstname" matInput placeholder="Max" formControlName="firstName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "customer.manage.common.lastname" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-lastname" matInput placeholder="Master" formControlName="lastName" />
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>{{ "customer.manage.freelanceCustomer.companyName" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-company" matInput placeholder="treeIT" formControlName="companyName" />
    </mat-form-field>
    <mat-error *ngIf="isDirtyOrTouched() && formGroup.hasError('atLeastOneRequired')">
      {{ "formValidation.customer.freelance.required.atLeastOneRequired" | translate }}
    </mat-error>
    <mat-form-field>
      <mat-label>{{ "customer.manage.common.phone" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-phone" matInput placeholder="09701 675 999 0" formControlName="phone" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "customer.manage.common.mobile" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-mobile" matInput placeholder="0151 123 123 12" formControlName="mobile" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "customer.manage.common.email" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-email" matInput placeholder="max@private.com" formControlName="mail" />
    </mat-form-field>

    <div formGroupName="bankAccount">
      <mat-form-field>
        <mat-label>{{ "customer.manage.freelanceCustomer.bankName" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-bankname" matInput formControlName="bankname" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>IBAN</mat-label>
        <input [maxlength]="34" e2e="input-iban" matInput formControlName="iban" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>BIC</mat-label>
        <input [maxlength]="20" e2e="input-bic" matInput formControlName="bic" />
      </mat-form-field>
    </div>
  </form>
  <div class="ti controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
