import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../../shared/utils/ValidationHelper";
import { PrivateCustomerTO } from "../../../shared/generated/transportObjects";
import { PrivateCustomerForm } from "../../form-model/private-customer-form";

@Component({
  selector: "ti-private-customer",
  templateUrl: "./private-customer.component.html",
  styleUrls: ["./private-customer.component.scss"]
})
export class PrivateCustomerComponent implements OnInit {
  @Input()
  privateCustomer: PrivateCustomerTO;

  @Output()
  created: EventEmitter<PrivateCustomerTO> = new EventEmitter<PrivateCustomerTO>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup;
  createMode: boolean;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  private init(): void {
    if (!this.privateCustomer) {
      this.createMode = true;
      this.privateCustomer = {} as any;
    }
    this.formGroup = this.createFormGroup();
  }

  private createFormGroup(): FormGroup<PrivateCustomerForm> {
    return this.fb.group<PrivateCustomerForm>({
      salutation: this.fb.control(this.privateCustomer.salutation || "", Validators.required),
      firstName: this.fb.control(this.privateCustomer.firstName || "", Validators.required),
      lastName: this.fb.control(this.privateCustomer.lastName || "", Validators.required),
      mobile: this.fb.control(this.privateCustomer.mobile || "", Validators.pattern(ValidationHelper.mobilePhoneNumber)),
      mail: this.fb.control(this.privateCustomer.mail || "", Validators.pattern(ValidationHelper.mailAddress)),
      phone: this.fb.control(this.privateCustomer.phone || "", Validators.pattern(ValidationHelper.phoneNumber))
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const result: PrivateCustomerTO = this.formGroup.value;
      if (!this.createMode) {
        result.id = this.privateCustomer.id;
        result.version = this.privateCustomer.version;
        result.customerNumber = this.privateCustomer.customerNumber;
      }
      result.type = "private";
      this.created.emit(result);
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }
}
