import { Component, inject, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmModalResult } from "../../model/confirm-modal-result";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ti-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"]
})
export class ConfirmModalComponent {
  private dialogRef: MatDialogRef<ConfirmModalComponent> = inject(MatDialogRef<ConfirmModalComponent>);
  private translate: TranslateService = inject(TranslateService);
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmModalData) {}

  accept(): void {
    this.dialogRef.close(this.translate.instant(ConfirmModalResult.ACCEPT));
  }

  close(): void {
    this.dialogRef.close(this.translate.instant(ConfirmModalResult.DENIED));
  }
}

export interface ConfirmModalData {
  title: string;
  question: string;
  approveLabel?: string;
}
