import { NgModule } from "@angular/core";

import { InvoiceRoutingModule } from "./invoice-routing.module";
import { SharedModule } from "../shared/shared.module";
import { NewInvoiceComponent } from "./new-invoice/new-invoice.component";
import { InvoiceSearchCustomerComponent } from "./new-invoice/invoice-search-customer/invoice-search-customer.component";
import { EditBillingAddressComponent } from "./new-invoice/edit-billing-address/edit-billing-address.component";
import { EditInvoiceDataComponent } from "./new-invoice/edit-invoice-data/edit-invoice-data.component";
import { EditInvoicePositionsComponent } from "./new-invoice/edit-invoice-positions/edit-invoice-positions.component";
import { InvoicePositionModalComponent } from "./shared/modals/invoice-position-modal/invoice-position-modal.component";
import { PositionListComponent } from "./shared/components/position-list/position-list.component";
import { CheckInvoiceComponent } from "./new-invoice/check-invoice/check-invoice.component";
import { FinishedInvoiceComponent } from "./new-invoice/finished-invoice/finished-invoice.component";
import { AllInvoicesComponent } from "./all-invoices/all-invoices.component";
import { InvoiceWelcomeComponent } from "./invoice-welcome/invoice-welcome.component";
import { EditInvoiceComponent } from "./edit-invoice/edit-invoice.component";
import { SearchInvoiceByCustomerComponent } from "./search-invoice-by-customer/search-invoice-by-customer.component";
import { InvoiceListComponent } from "./shared/components/invoice-list/invoice-list.component";
import { AllOpenInvoicesComponent } from "./all-open-invoices/all-open-invoices.component";
import { ListSalesPositionsComponent } from "./list-sales-positions/list-sales-positions.component";
import { ProfitReportingComponent } from "./profit-reporting/profit-reporting.component";
import { InvoiceCardsComponent } from "./shared/components/invoice-cards/invoice-cards.component";
import { EditInvoiceTypeComponent } from "./new-invoice/edit-invoice-type/edit-invoice-type.component";
import { CreditsService } from "../core/services/credits.service";

@NgModule({
  imports: [InvoiceRoutingModule, SharedModule],
  declarations: [
    NewInvoiceComponent,
    InvoiceSearchCustomerComponent,
    EditBillingAddressComponent,
    EditInvoiceDataComponent,
    EditInvoicePositionsComponent,
    InvoicePositionModalComponent,
    PositionListComponent,
    CheckInvoiceComponent,
    FinishedInvoiceComponent,
    AllInvoicesComponent,
    InvoiceWelcomeComponent,
    EditInvoiceComponent,
    SearchInvoiceByCustomerComponent,
    InvoiceListComponent,
    AllOpenInvoicesComponent,
    ListSalesPositionsComponent,
    ProfitReportingComponent,
    InvoiceCardsComponent,
    EditInvoiceTypeComponent
  ],
  providers: [CreditsService]
})
export class InvoiceModule {}
