<div class="search-container">
  <div class="animated slideInUp">
    <form novalidate [formGroup]="formGroup" (keyup.enter)="search()">
      <mat-form-field>
        <mat-label>{{ "customer.searchCustomers.searchCustomers" | translate }}</mat-label>
        <input e2e="input-search-customer" matInput placeholder="Max" formControlName="searchTerm" #searchinput="matInput" />
      </mat-form-field>
    </form>
  </div>
</div>
