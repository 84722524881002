<div class="modal">
  <h2 class="header">{{ createMode ? "Create" : "Edit" }} {{ "invoice.shared.modals.position" | translate }}</h2>
  <div class="content">
    <div class="form-container">
      <form novalidate [formGroup]="formGroup" (keyup.enter)="save()">
        <div class="two fields">
          <mat-form-field>
            <mat-label>{{ "common.effortDate" | translate }}</mat-label>
            <input e2e="input-invoice-position-date" matInput formControlName="posDate" [matDatepicker]="datePicker" />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "common.effortMonth" | translate }}</mat-label>
            <input e2e="input-invoice-position-month" matInput formControlName="posMonth" [matDatepicker]="monthPicker" />
            <mat-datepicker-toggle matSuffix [for]="monthPicker"></mat-datepicker-toggle>
            <mat-datepicker #monthPicker startView="year" (monthSelected)="selectMonth($event, monthPicker)"></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-error *ngIf="isDirtyOrTouched() && formGroup.hasError('atLeastOneRequired')">
          {{ "formValidation.invoice.position.required.atLeastOneRequired" | translate }}
        </mat-error>
        <mat-form-field>
          <mat-label>{{ "common.description" | translate }}</mat-label>
          <textarea
            [maxlength]="200"
            e2e="input-invoice-position-description"
            matInput
            type="text"
            placeholder="Project Save the World"
            formControlName="description"></textarea>
          <mat-error *ngIf="hasError('description')">{{ "formValidation.invoice.position.required.description" | translate }}</mat-error>
        </mat-form-field>
        <div class="switcher">
          <div class="type-switcher">
            <div e2e="btn-invoice-position-type-amount" (click)="switchType('amount')" [ngClass]="{ active: type === 'amount' }">
              {{ "common.amount" | translate }}
            </div>
            <div e2e="btn-invoice-position-type-hours" (click)="switchType('hours')" [ngClass]="{ active: type === 'hours' }">
              {{ "invoice.shared.modals.hours" | translate }}
            </div>
            <div e2e="btn-invoice-position-type-units" (click)="switchType('units')" [ngClass]="{ active: type === 'units' }">
              {{ "invoice.shared.modals.units" | translate }}
            </div>
            <div
              e2e="btn-invoice-position-type-freelancer"
              (click)="switchType('freelancer')"
              [ngClass]="{ active: type === 'freelancer' }">
              {{ "common.freelancer" | translate }}
            </div>
          </div>
        </div>
        <div *ngIf="type === 'hours'" class="two fields">
          <mat-form-field>
            <mat-label>{{ "invoice.shared.modals.amountHours" | translate }}</mat-label>
            <input e2e="input-invoice-position-hours" matInput type="number" formControlName="hours" placeholder="120" />
            <mat-error *ngIf="hasError('hours')">{{ "formValidation.invoice.position.required.amountHours" | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "invoice.shared.modals.ratePerHour" | translate }}</mat-label>
            <input e2e="input-invoice-position-hourly-rate" matInput type="number" formControlName="hourlyRate" placeholder="85" />
            <mat-error *ngIf="hasError('hourlyRate')">{{ "formValidation.invoice.position.required.hourlyRate" | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="type === 'freelancer'">
          <div>
            <mat-form-field>
              <mat-label>{{ "invoice.shared.modals.amountHours" | translate }}</mat-label>
              <input e2e="input-invoice-position-hours" matInput type="number" formControlName="hours" placeholder="120" />
              <mat-error *ngIf="hasError('hours')">{{ "formValidation.invoice.position.required.amountHours" | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="two fields">
            <mat-form-field>
              <mat-label>{{ "invoice.shared.modals.ratePerHour" | translate }}</mat-label>
              <input e2e="input-invoice-position-hourly-rate" matInput type="number" formControlName="hourlyRate" placeholder="85" />
              <mat-error *ngIf="hasError('hourlyRate')">{{ "formValidation.invoice.position.required.hourlyRate" | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "invoice.shared.modals.freelancerRate" | translate }}</mat-label>
              <input
                e2e="input-invoice-position-freelancer-rate"
                matInput
                type="number"
                formControlName="freelancerRate"
                placeholder="75" />
              <mat-error *ngIf="hasError('freelancerRate')">{{
                "formValidation.invoice.position.required.freelancerRate" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="type === 'units'" class="two fields">
          <mat-form-field>
            <mat-label>{{ "invoice.shared.modals.units" | translate }}</mat-label>
            <input e2e="input-invoice-position-unit" matInput type="number" formControlName="units" placeholder="12" />
            <mat-error *ngIf="hasError('units')">{{ "formValidation.invoice.position.required.units" | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "invoice.shared.modals.pricePerUnit" | translate }}</mat-label>
            <input e2e="input-invoice-position-price-per-unit" matInput type="number" formControlName="price" placeholder="120" />
            <mat-error *ngIf="hasError('price')">{{ "formValidation.invoice.position.required.price" | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="type === 'amount'">
          <mat-form-field>
            <mat-label>{{ "common.amount" | translate }}</mat-label>
            <input e2e="input-invoice-position-amount" matInput type="number" formControlName="amount" placeholder="2500" />
            <mat-error *ngIf="hasError('amount')">{{ "formValidation.invoice.position.required.amount" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>

  <div class="controls actions">
    <button e2e="btn-delete" *ngIf="!createMode" (click)="delete()" mat-raised-button color="warn">
      {{ "common.delete" | translate }}
    </button>
    <div class="spacer"></div>
    <button e2e="btn-cancel" (click)="cancel()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid || !typeSelected" (click)="save()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
