import { LoginComponent } from "./core/components/login/login.component";
import { StartComponent } from "./core/components/start/start.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoggedIn } from "./shared/guards/logged-in";
import { ProductsOverviewComponent } from "./core/components/products/products-overview/products-overview.component";

const routes: Routes = [
  { path: "", redirectTo: "apps", pathMatch: "full" },
  { path: "apps", component: StartComponent, canActivate: [LoggedIn] },
  { path: "apps/login", component: LoginComponent },
  { path: "apps/products", component: ProductsOverviewComponent, outlet: "primary", canActivate: [LoggedIn] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
