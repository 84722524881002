<div class="filter">
  <mat-form-field>
    <mat-label>{{ "invoice.allInvoices.filter" | translate }}</mat-label>
    <mat-select class="selection" [(value)]="invoiceFilter" (selectionChange)="applyFilter($event)">
      <mat-option [value]="'ALL'"> {{ "common.all" | translate }} </mat-option>
      <mat-option [value]="'OPEN'"> {{ "invoice.common.open" | translate }} </mat-option>
      <mat-option [value]="'DUE'"> {{ "invoice.common.due" | translate }} </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<ti-invoice-list [customerPageLoader]="customerPageLoader" [productPageLoader]="productPageLoader"></ti-invoice-list>
