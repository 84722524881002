import { StatisticsService } from "../services/statistics.service";
import { Resolve } from "@angular/router";
import { inject, Injectable } from "@angular/core";

@Injectable()
export class StatisticsResolver implements Resolve<any> {
  private ss: StatisticsService = inject(StatisticsService);
  constructor() {}
  resolve(): any {
    return this.ss.getStatistics();
  }
}
