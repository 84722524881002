import { Component, inject, Input, OnInit } from "@angular/core";
import { EuroPipe } from "../../shared/pipes/euro-pipe";
import { ChartConfiguration, TooltipItem } from "chart.js";
import { ActivatedRoute } from "@angular/router";
import { ChartColors } from "../../shared/utils/chart-colors";
import { StatisticTO } from "../../shared/generated/transportObjects";

@Component({
  selector: "ti-stat-sales-trend",
  templateUrl: "./stat-sales-trend.component.html",
  styleUrls: ["./stat-sales-trend.component.scss"]
})
export class StatSalesTrendComponent implements OnInit {
  statistics: StatisticTO;

  @Input()
  chartColors = ChartColors.SETCOLORS;
  options: ChartConfiguration<"bar">["options"];
  chartData: ChartConfiguration<"bar">["data"] = { labels: [], datasets: [] };

  private route: ActivatedRoute = inject(ActivatedRoute);
  private ep: EuroPipe = inject(EuroPipe);
  constructor() {}

  ngOnInit(): void {
    this.statistics = this.route.snapshot.data.statistics;
    this.initData();
    this.initOptions();
  }

  private initData(): void {
    const sales: { data: any[]; label: string | undefined } = { data: [], label: undefined };
    const profit: { data: any[]; label: string | undefined } = { data: [], label: undefined };
    const salesArr: number[] = new Array();
    const profitArr: number[] = new Array();
    if (this.statistics) {
      Object.keys(this.statistics.salesPerYear).forEach((year: string) => {
        const profitValue: number = this.statistics.salesPerYear[year].profit || 0;
        salesArr.push(this.statistics.salesPerYear[year].totalSales - profitValue);
        profitArr.push(profitValue);

        this.chartData.labels.push(year);
      });

      sales.data = salesArr;

      profit.data = profitArr;

      sales.label = "Sales";

      profit.label = "Profit";

      this.chartData.datasets.push(profit, sales);
    }
  }

  private initOptions(): void {
    const that: any = this;
    this.options = {
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      },
      plugins: {
        legend: {
          position: "top",
          display: true,
          fullSize: true,
          labels: {
            font: {
              size: 18
            },
            padding: 20
          }
        },
        tooltip: {
          callbacks: {
            label: function (context: TooltipItem<"bar">): string {
              let value: number = context.parsed.y as number;
              if (context.datasetIndex === 1) {
                value = (value + that.chartData.datasets[0].data[context.dataIndex]) as number;
              }
              return that.ep.transform(value);
            }
          }
        }
      },
      responsive: true
    };
  }
}
