import { Component, inject, OnInit } from "@angular/core";
import { AddressTO, BankAccountTO, CompanyTO, UserContextTO } from "../../shared/generated/transportObjects";
import { ApplicationStateService } from "../../core/services/application-state.service";
import { CompanyService } from "../../core/services/company.service";
import { InvoiceTemplateService } from "../../core/services/invoiceTemplateService";

@Component({
  selector: "ti-show-company",
  templateUrl: "./show-company.component.html",
  styleUrls: ["./show-company.component.scss"]
})
export class ShowCompanyComponent implements OnInit {
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  company: CompanyTO;
  editCompany: boolean;
  editAddress: boolean;
  editBankAccountDetails: boolean;
  invoiceTemplates: string[];
  private cs: CompanyService = inject(CompanyService);
  private invoiceTemplateService: InvoiceTemplateService = inject(InvoiceTemplateService);
  constructor() {}

  ngOnInit(): void {
    this.editCompany = false;
    this.editAddress = false;
    this.editBankAccountDetails = false;
    this.company = this.applicationStateService.getCurrentUser().company;
    this.invoiceTemplateService.findAllTemplates().subscribe({
      next: (value: string[]) => {
        this.invoiceTemplates = value;
      }
    });
  }

  updateCompany(company: CompanyTO): void {
    this.doUpdateCompany(company, this.company.companyId);
  }

  updateAddress(address: AddressTO): void {
    this.company.address = address;
    this.doUpdateCompany(this.company, this.company.companyId);
  }

  updateBankDetails(bankAccount: BankAccountTO): void {
    this.company.bankAccount = bankAccount;
    this.doUpdateCompany(this.company, this.company.companyId);
  }

  doUpdateCompany(company: CompanyTO, companyId: number): void {
    this.cs.updateCompany(company, companyId).subscribe({
      next: (res: CompanyTO) => {
        this.company = res;
        this.editCompany = false;
        this.editAddress = false;
        this.editBankAccountDetails = false;
        let userContext: UserContextTO = this.applicationStateService.getCurrentUser();
        userContext.company = res;
        this.applicationStateService.setCurrentUser(userContext);
      }
    });
  }
}
