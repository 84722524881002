<div class="form-container">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="save()">
    <mat-form-field>
      <mat-label>{{ "customer.manage.freelanceCustomer.bankName" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-bankname" matInput placeholder="abc Bank" formControlName="bankname" />
      <mat-error *ngIf="hasError('bankname')">{{ "formValidation.company.bankAccount.required.bankName" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editBankAccount.iban" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-iban" matInput placeholder="DEXX XXXX XXXX XXXX XXXX XX" formControlName="iban" />
      <mat-error *ngIf="formGroup.get('iban').hasError('required')">
        {{ "formValidation.company.bankAccount.required.iban" | translate }}
      </mat-error>
      <mat-error *ngIf="formGroup.get('iban').hasError('pattern')">
        {{ "formValidation.company.bankAccount.pattern.iban" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "company.editBankAccount.bic" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-bic" matInput placeholder="BYXXXXXXXX" formControlName="bic" />
      <mat-error *ngIf="formGroup.get('bic').hasError('required')">
        {{ "formValidation.company.bankAccount.required.bic" | translate }}
      </mat-error>
      <mat-error *ngIf="formGroup.get('bic').hasError('pattern')">
        {{ "formValidation.company.bankAccount.pattern.bic" | translate }}
      </mat-error>
    </mat-form-field>
  </form>
  <div class="ti controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
