import { Component, inject } from "@angular/core";
import { StatisticsService } from "../../statistics/shared/services/statistics.service";
import { ReportProfitModalComponent } from "../../shared/modals/report-profit-modal/report-profit-modal.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { StatisticTO } from "../../shared/generated/transportObjects";

@Component({
  selector: "ti-profit-reporting",
  templateUrl: "./profit-reporting.component.html",
  styleUrls: ["./profit-reporting.component.scss"]
})
export class ProfitReportingComponent {
  sales: StatisticTO;

  private ss: StatisticsService = inject(StatisticsService);
  dialog: MatDialog = inject(MatDialog);

  constructor() {
    this.ss.getStatistics().subscribe({
      next: (items: StatisticTO) => (this.sales = items)
    });
  }

  report(year: string): void {
    const max: number = this.sales.salesPerYear[year].totalSales;
    const dialogRef: MatDialogRef<ReportProfitModalComponent> = this.dialog.open(ReportProfitModalComponent, {
      width: "500px",
      data: { year: year, max: max }
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => this.reportProfit(year, res as number)
    });
  }

  private reportProfit(year: string, profit: number): void {
    if (profit && profit >= 0) {
      this.ss.reportProfit(Number(year), profit).subscribe({
        next: () => {
          this.ss.getStatistics().subscribe({
            next: (items: StatisticTO) => (this.sales = items)
          });
        }
      });
    }
  }

  protected readonly Object = Object;
}
