import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";
import { BillingAddressTO, CustomerInvoiceTO, InvoiceTO } from "../../../../shared/generated/transportObjects";

@Component({
  selector: "ti-invoice-cards",
  templateUrl: "./invoice-cards.component.html",
  styleUrls: ["./invoice-cards.component.scss"]
})
export class InvoiceCardsComponent {
  @Input()
  invoices: InvoiceTO[] | CustomerInvoiceTO[];
  @Input()
  headline: string;
  @Input()
  from: number;
  @Input()
  to: number;
  @Input()
  currentPage: number;
  @Input()
  totalPages: number;

  @Output()
  copyInvoiceClicked: EventEmitter<InvoiceTO | CustomerInvoiceTO> = new EventEmitter<InvoiceTO | CustomerInvoiceTO>();
  @Output()
  editInvoiceClicked: EventEmitter<InvoiceTO | CustomerInvoiceTO> = new EventEmitter<InvoiceTO | CustomerInvoiceTO>();
  @Output()
  correctInvoiceClicked: EventEmitter<InvoiceTO | CustomerInvoiceTO> = new EventEmitter<InvoiceTO | CustomerInvoiceTO>();
  @Output()
  printInvoiceClicked: EventEmitter<InvoiceTO | CustomerInvoiceTO> = new EventEmitter<InvoiceTO | CustomerInvoiceTO>();
  @Output()
  changePaymentStateClicked: EventEmitter<InvoiceTO | CustomerInvoiceTO> = new EventEmitter<InvoiceTO | CustomerInvoiceTO>();
  @Output()
  showDetailsClicked: EventEmitter<InvoiceTO | CustomerInvoiceTO> = new EventEmitter<InvoiceTO | CustomerInvoiceTO>();
  @Output()
  loadMoreClicked: EventEmitter<void> = new EventEmitter<void>();

  getReceiver(address: BillingAddressTO): string {
    if (address.company) {
      return address.company;
    } else {
      return address.firstName + " " + address.lastName;
    }
  }

  isDue(invoice: InvoiceTO): boolean {
    if (invoice.invoiceData.paid) {
      return false;
    } else {
      const due: Moment = moment(invoice.due);
      return due.isBefore(moment());
    }
  }

  checkCustomerInvoiceType(invoice: InvoiceTO | CustomerInvoiceTO): boolean {
    return (invoice as CustomerInvoiceTO).customerInvoiceType && (invoice as CustomerInvoiceTO).customerInvoiceType === "FREELANCER";
  }
}
