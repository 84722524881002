<div *ngIf="!showDetails && !editInvoice" class="invoices">
  <div *ngIf="customerInvoices?.content?.length === 0 && productInvoices?.content?.length === 0" class="no-invoice">
    <div class="message-container">
      <ti-message
        class="animated fadeIn"
        icon="report_problem"
        type="info"
        headline="invoice.shared.components.invoiceList.noInvoicesCreated"
        content="invoice.shared.components.invoiceList.pleaseCreateInvoiceForCustomersOrProducts"></ti-message>
      <div class="action">
        <button e2e="btn-create-new-invoice" routerLink="/invoices/new" mat-raised-button color="accent" class="btn-new-invoice">
          {{ "invoice.common.newInvoice" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="customerInvoices?.content?.length > 0" class="customer-invoices">
    <ti-invoice-cards
      [headline]="'Customer Invoices'"
      [from]="customerInvoices.content.length"
      [to]="customerInvoices.totalElements"
      [invoices]="customerInvoices.content"
      [currentPage]="currentCustomerPage"
      [totalPages]="customerInvoices.totalPages"
      (changePaymentStateClicked)="changePaymentState($event)"
      (copyInvoiceClicked)="copyInvoiceDataForNewOne($event)"
      (correctInvoiceClicked)="correctInvoice($event)"
      (editInvoiceClicked)="edit($event, 'customer')"
      (loadMoreClicked)="loadMoreCustomerInvoices()"
      (printInvoiceClicked)="printCustomerInvoice($event)"
      (showDetailsClicked)="showDetails = $event"></ti-invoice-cards>
  </div>
  <div class="product-invoices" *ngIf="productInvoices?.content?.length > 0">
    <ti-invoice-cards
      [headline]="'Product Invoices'"
      [from]="productInvoices.content.length"
      [to]="productInvoices.totalElements"
      [invoices]="productInvoices.content"
      [currentPage]="currentCustomerPage"
      [totalPages]="productInvoices.totalPages"
      (changePaymentStateClicked)="changePaymentState($event)"
      (copyInvoiceClicked)="copyInvoiceDataForNewOne($event)"
      (correctInvoiceClicked)="correctInvoice($event)"
      (editInvoiceClicked)="edit($event, 'product')"
      (loadMoreClicked)="loadMoreProductInvoices()"
      (printInvoiceClicked)="printProductInvoice($event)"
      (showDetailsClicked)="showDetails = $event"></ti-invoice-cards>
  </div>
</div>

<div *ngIf="showDetails" class="show-invoice-details">
  <ti-check-invoice [showControls]="false" (back)="showDetails = null" [invoice]="showDetails"></ti-check-invoice>
  <mat-icon class="close-icon" (click)="showDetails = undefined">{{ "common.close" | translate }}</mat-icon>
</div>
<div *ngIf="editInvoice" class="edit-invoice">
  <ti-edit-invoice (cancel)="cancelEditMode()" (saved)="saved()" [invoice]="editInvoice"></ti-edit-invoice>
</div>
