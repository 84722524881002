<div class="stats-overview-container">
  <div class="stats-overview">
    <div class="stat">
      <div class="value">
        <span class="material-icons">euro_symbol</span>

        <span>{{ statistics.salesPerYear[prevYear] ? statistics.salesPerYear[prevYear].totalSales : (0 | number : "1.0-0") }}</span>
      </div>
      <div class="label">{{ "statistics.overview.sales" | translate }} {{ prevYear }}</div>
    </div>
    <div class="stat">
      <div class="value">
        <span class="material-icons">euro_symbol</span>
        <span>{{ statistics.salesPerYear[currentYear] ? statistics.salesPerYear[currentYear].totalSales : (0 | number : "1.0-0") }}</span>
      </div>
      <div class="label">{{ "statistics.overview.sales" | translate }} {{ currentYear }}</div>
    </div>
    <div class="stat">
      <div class="value">
        <span class="material-icons">euro_symbol</span>
        <span>{{ calculateYearAvg | number : "1.0-0" }}</span>
      </div>
      <div class="label">{{ "statistics.overview.avgYearSale" | translate }}</div>
    </div>
    <div class="stat">
      <div class="value">
        <span class="material-icons">euro_symbol</span>
        <span>{{ calculateMonthAvg | number : "1.0-0" }}</span>
      </div>
      <div class="label">{{ "statistics.overview.avgMonthSale" | translate }}</div>
    </div>
  </div>
</div>
