<div class="first ti-cards">
  <div e2e="card-btn-statistics-overview" class="ti-card ti-link-card cms-card medium" routerLink="/statistics/overview">
    <span class="material-icons">view_module</span>
    <div>{{ "statistics.welcome.overview" | translate }}</div>
    <p>{{ "statistics.welcome.someStatistics" | translate }}</p>
  </div>
  <div e2e="card-btn-statistics-sales-per-year" class="ti-card ti-link-card cms-card medium" routerLink="/statistics/sales/year">
    <span class="material-icons">bar_chart</span>
    <div>{{ "statistics.welcome.salesPerYear" | translate }}</div>
    <p>{{ "statistics.welcome.salesStatisticsPerYear" | translate }}</p>
  </div>
  <div e2e="card-btn-statistics-sales-trend" class="ti-card ti-link-card cms-card medium" routerLink="/statistics/sales/trend">
    <span class="material-icons">trending_up</span>
    <div>{{ "statistics.welcome.salesTrend" | translate }}</div>
    <p>{{ "statistics.welcome.salesStatisticsTrend" | translate }}</p>
  </div>
</div>
