<div class="message-container">
  <ti-message
    [headline]="'invoice.newInvoice.finishedInvoice.great'"
    [icon]="'sports_kabaddi'"
    [content]="'invoice.newInvoice.finishedInvoice.invoiceCreatedGrabABeer'"></ti-message>
  <div class="actions">
    <button e2e="btn-show-all-invoices" routerLink="/invoices/all" mat-raised-button>{{ "invoice.common.allInvoices" | translate }}</button>
    <button e2e="btn-create-new-invoice" (click)="createNewInvoice()" mat-raised-button color="accent">
      {{ "invoice.common.newInvoice" | translate }}
    </button>
    <button e2e="btn-print-invoice" (click)="print()" mat-raised-button color="primary">{{ "invoice.common.print" | translate }}</button>
  </div>
</div>
