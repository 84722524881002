<div>
  <til-divider icon="important_devices">{{ "common.products" | translate }}</til-divider>
</div>
<div class="ti-cards products">
  <div *ngFor="let product of products" class="ti-card">
    <div class="ti-product-headline">
      <span>{{ product.productName }}</span>
    </div>
    <div class="cn meta">{{ "products.overview.technicalID" | translate }} {{ product.productId }}</div>
    <mat-divider></mat-divider>
    <div class="content body">
      <div class="description">
        <div>
          <div>{{ product.description }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
