import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { CustomerService } from "../../../core/services/customer.service";
import { ApplicationStateService } from "../../../core/services/application-state.service";
import { BillingAddressTO, CustomerTO, SearchCustomerTO } from "../../../shared/generated/transportObjects";

@Component({
  selector: "ti-edit-billing-address",
  templateUrl: "./edit-billing-address.component.html",
  styleUrls: ["./edit-billing-address.component.scss"]
})
export class EditBillingAddressComponent implements OnInit {
  @Input()
  customer: SearchCustomerTO;

  customerLoaded: CustomerTO;
  billingAddress: BillingAddressTO;

  @Output()
  addressCreated: EventEmitter<BillingAddressTO> = new EventEmitter<BillingAddressTO>();

  @Output()
  back: EventEmitter<any> = new EventEmitter<any>();

  private cs: CustomerService = inject(CustomerService);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);

  constructor() {}

  ngOnInit(): void {
    const step: any = this.applicationStateService.getInvoiceStep(3);
    if (step) {
      this.billingAddress = step;
    } else {
      this.cs.findCustomer(this.customer.customerNumber).subscribe({
        next: (res: CustomerTO) => (this.customerLoaded = res)
      });
    }
  }

  saveAddress(ba: BillingAddressTO): void {
    this.addressCreated.emit(ba);
  }

  get address(): BillingAddressTO {
    if (this.customerLoaded) {
      return this.customerLoaded.billingAddress;
    } else {
      return this.billingAddress;
    }
  }

  cancel(): void {
    this.back.emit();
  }
}
