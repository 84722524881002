<div class="create-container">
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step>
      <ng-template matStepLabel>{{ "invoice.newInvoice.selectCustomer" | translate }}</ng-template>
      <ti-invoice-search-customer (selected)="selectCustomer($event)"></ti-invoice-search-customer>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{ "invoice.common.invoiceType" | translate }}</ng-template>
      <ti-edit-invoice-type
        [showControls]="true"
        [invoiceType]="invoiceType"
        (typeSelected)="selectInvoiceType($event)"
        *ngIf="stepper.selectedIndex === 1"
        (back)="backTo(1)"></ti-edit-invoice-type>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{ "invoice.newInvoice.selectBillingAddress" | translate }}</ng-template>
      <ti-edit-billing-address
        *ngIf="stepper.selectedIndex === 2"
        (back)="backTo(2)"
        (addressCreated)="selectBillingAddress($event)"
        [customer]="searchCustomer"></ti-edit-billing-address>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ "invoice.editInvoice.invoiceData" | translate }}</ng-template>
      <ti-edit-invoice-data
        *ngIf="stepper.selectedIndex === 3"
        [showControls]="true"
        (back)="backTo(3)"
        (dataCreated)="selectInvoiceData($event)"
        [invoiceData]="createStandardInvoiceData()"></ti-edit-invoice-data>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{ "invoice.common.invoicePositions" | translate }}</ng-template>
      <ti-edit-invoice-possitions
        *ngIf="stepper.selectedIndex === 4"
        [taxInPercent]="invoiceData.taxInPercent"
        (back)="backTo(4)"
        (created)="selectInvoicePositions($event)"></ti-edit-invoice-possitions>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{ "invoice.newInvoice.checkInvoiceData" | translate }}</ng-template>
      <ti-check-invoice
        *ngIf="stepper.selectedIndex === 5"
        [showControls]="true"
        (save)="saveInvoice()"
        [invoice]="getInvoice()"
        (back)="backTo(5)"></ti-check-invoice>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>{{ "invoice.common.print" | translate }}</ng-template>
      <ti-finished-invoice *ngIf="stepper.selectedIndex === 6" [invoice]="saved" (newInvoice)="backToStart()"></ti-finished-invoice>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div *ngIf="false" class="create-invoice-container">
  <div *ngIf="step !== 6" class="invoice-steps">
    <div class="ui fluid vertical steps">
      <div [ngClass]="{ active: step === 1, completed: step > 1 }" class="step">
        <i class="address card outline icon"></i>
        <div class="content">
          <div class="title">{{ "invoice.newInvoice.customerInformation" | translate }}</div>
          <div class="description">
            <div *ngIf="!searchCustomer">{{ "common.search" | translate }} {{ "common.customer" | translate }}</div>
            <div *ngIf="searchCustomer">
              <div>{{ searchCustomer.name }}</div>
              <div>{{ searchCustomer.invoiceNumber }}</div>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ active: step === 2, completed: step > 2 }" class="step">
        <i class="address book outline icon"></i>
        <div class="content">
          <div class="title">{{ "common.newInvoice.billingAddress" | translate }}</div>
          <div *ngIf="!billingAddress" class="description">{{ "invoice.newInvoice.checkBillingAddress" | translate }}</div>
          <div class="description" *ngIf="billingAddress">
            <div *ngIf="billingAddress.company">{{ billingAddress.company }}</div>
            <div>{{ billingAddress.firstName }} {{ billingAddress.lastName }}</div>
            <div *ngIf="billingAddress.addInfo">{{ billingAddress.addInfo }}</div>
            <div>{{ billingAddress.zip }} {{ billingAddress.city }}</div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ active: step === 3, completed: step > 3 }" class="step">
        <i class="file alternate outline icon"></i>
        <div class="content">
          <div class="title">{{ "invoice.newInvoice.billingDetails" | translate }}</div>
          <div *ngIf="!invoiceData" class="description">{{ "invoice.newInvoice.checkBillingAddress" | translate }}</div>
          <div *ngIf="invoiceData" class="description">{{ "invoice.newInvoice.dateFixed" | translate }}</div>
        </div>
      </div>
      <div [ngClass]="{ active: step === 4, completed: step > 4 }" class="step">
        <i class="list ul icon"></i>
        <div class="content">
          <div class="title">{{ "invoice.common.positions" | translate }}</div>
          <div *ngIf="!positions" class="description">{{ "invoice.newInvoice.enterInvoiceItems" | translate }}</div>
          <div *ngIf="positions">{{ "invoice.newInvoice.invoiceAmount" | translate }}:</div>
          <div *ngIf="positions">{{ totalAmountNet | euro }} {{ "invoice.newInvoice.nett" | translate }}</div>
        </div>
      </div>
      <div [ngClass]="{ active: step === 5, completed: step > 5 }" class="step">
        <i class="info icon"></i>
        <div class="content">
          <div class="title">{{ "invoice.newInvoice.checkData" | translate }}</div>
          <div class="description">{{ "invoice.newInvoice.checkBillingInformation" | translate }}</div>
        </div>
      </div>
      <div [ngClass]="{ active: step === 6, completed: step > 6 }" class="step">
        <i class="print icon"></i>
        <div class="content">
          <div class="title">{{ "invoice.common.print" | translate }}</div>
          <div class="description">{{ "invoice.newInvoice.enterBillingInformation" | translate }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="invoice-inputs">
    <div *ngIf="step === 1" class="search-customers">
      <ti-invoice-search-customer (selected)="selectCustomer($event)"></ti-invoice-search-customer>
    </div>

    <div *ngIf="step === 2" class="edit-billing-address">
      <ti-edit-billing-address
        (back)="backTo(1)"
        (addressCreated)="selectBillingAddress($event)"
        [customer]="searchCustomer"></ti-edit-billing-address>
    </div>

    <div *ngIf="step === 3" class="edit-invoice-data">
      <ti-edit-invoice-data
        [showControls]="true"
        (back)="backTo(2)"
        (dataCreated)="selectInvoiceData($event)"
        [invoiceData]="createStandardInvoiceData()"></ti-edit-invoice-data>
    </div>

    <div *ngIf="step === 4" class="edit-invoice-positions">
      <ti-edit-invoice-possitions
        [taxInPercent]="invoiceData.taxInPercent"
        (back)="backTo(3)"
        (created)="selectInvoicePositions($event)"></ti-edit-invoice-possitions>
    </div>

    <div *ngIf="step === 5" class="check-invoice">
      <ti-check-invoice [showControls]="true" (save)="saveInvoice()" [invoice]="getInvoice()" (back)="backTo(4)"></ti-check-invoice>
    </div>

    <div *ngIf="step === 6">
      <ti-finished-invoice [invoice]="saved" (newInvoice)="backToStart()"></ti-finished-invoice>
    </div>
  </div>
</div>

<div *ngIf="loading" class="ui active dimmer">
  <div class="ui text loader">{{ "common.loading" | translate }}</div>
</div>
