import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { LoggedIn } from "../shared/guards/logged-in";
import { ShowCompanyComponent } from "./show-company/show-company.component";

const routes: Routes = [{ path: "company/show/:companyId", component: ShowCompanyComponent, outlet: "primary", canActivate: [LoggedIn] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule {}
