<div *ngIf="customer" class="show-customer-container">
  <div class="customer-info">
    <div class="customer-menu">
      <button e2e="btn-show-customer-menu" mat-mini-fab [matMenuTriggerFor]="menu"><mat-icon>edit</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button e2e="btn-show-costumer-menu-item-new-invoice" (click)="quickInvoice()" mat-menu-item>
          <mat-icon>add</mat-icon>
          <span>{{ "invoice.common.newInvoice" | translate }}</span>
        </button>
        <button
          e2e="btn-show-costumer-menu-item-show-invoices"
          [routerLink]="'/invoices/customer/' + customer.customerNumber"
          mat-menu-item>
          <mat-icon>filter_none</mat-icon>
          <span>{{ "customer.manage.showCustomer.showInvoices" | translate }}</span>
        </button>
        <button
          e2e="btn-show-costumer-menu-item-delete-customer"
          (click)="deleteCustomer(customer.customerNumber)"
          class="delete-menu-item"
          mat-menu-item>
          <mat-icon>remove_circle_outline</mat-icon>
          <span>{{ "common.delete" | translate }}</span>
        </button>
      </mat-menu>
    </div>

    <div *ngIf="privateCustomer" class="private">
      <div class="header">{{ privateCustomer.salutation }} {{ privateCustomer.firstName }} {{ privateCustomer.lastName }}</div>
      <div class="customer-number">{{ privateCustomer.customerNumber }}</div>
    </div>
    <div *ngIf="freelanceCustomer" class="private">
      <div class="header">{{ freelanceCustomer.salutation }} {{ freelanceCustomer.firstName }} {{ freelanceCustomer.lastName }}</div>
      <div class="customer-number">{{ freelanceCustomer.customerNumber }}</div>
      <span>({{ "common.freelancer" | translate }})</span>
    </div>
    <div *ngIf="businessCustomer" class="business">
      <div class="header">{{ businessCustomer.companyName }}</div>
      <div class="customer-number">{{ businessCustomer.customerNumber }}</div>
    </div>
  </div>

  <div class="customer-data">
    <div class="left">
      <div e2e="card-customer-data" class="ti-card data-item">
        <til-divider actionButtonIcon="edit" (buttonClicked)="editCustomerData = true" icon="person">{{
          "customer.manage.showCustomer.customerData" | translate
        }}</til-divider>
        <div *ngIf="businessCustomer && !editCustomerData">
          <div>{{ "customer.manage.showCustomer.businessCustomer" | translate }}</div>
          <div *ngIf="businessCustomer.website">
            <a [href]="businessCustomer.website">{{ businessCustomer.website }}</a>
          </div>
          <div *ngIf="businessCustomer.phone">{{ businessCustomer.phone }}</div>
          <div *ngIf="businessCustomer.mail">
            <a [href]="'mailto:' + businessCustomer.mail">{{ businessCustomer.mail }}</a>
          </div>
          <div *ngIf="businessCustomer.vatId">{{ businessCustomer.vatId }}</div>
        </div>
        <ti-business-customer
          (cancel)="editCustomerData = false"
          *ngIf="businessCustomer && editCustomerData"
          [businessCustomer]="businessCustomer"
          (created)="saveBusinessCustomer($event)"></ti-business-customer>
        <div *ngIf="privateCustomer && !editCustomerData">
          <div *ngIf="privateCustomer">{{ "customer.manage.showCustomer.privateCustomer" | translate }}</div>
          <div *ngIf="privateCustomer.phone">{{ privateCustomer.phone }}</div>
          <div *ngIf="privateCustomer.mobile">{{ privateCustomer.mobile }}</div>
          <div *ngIf="privateCustomer.mail">{{ privateCustomer.mail }}</div>
        </div>
        <ti-private-customer
          (cancel)="editCustomerData = false"
          *ngIf="editCustomerData && privateCustomer"
          [privateCustomer]="privateCustomer"
          (created)="savePrivateCustomer($event)"></ti-private-customer>
        <div *ngIf="freelanceCustomer && !editCustomerData">
          <div *ngIf="freelanceCustomer">{{ "customer.manage.showCustomer.freelancerCustomer" | translate }}</div>
          <div *ngIf="freelanceCustomer.companyName">{{ freelanceCustomer.companyName }}</div>
          <div *ngIf="freelanceCustomer.phone">{{ freelanceCustomer.phone }}</div>
          <div *ngIf="freelanceCustomer.mobile">{{ freelanceCustomer.mobile }}</div>
          <div *ngIf="freelanceCustomer.mail">{{ freelanceCustomer.mail }}</div>
          <div *ngIf="freelanceCustomer.bankAccount">{{ freelanceCustomer.bankAccount.bankname }}</div>
          <div *ngIf="freelanceCustomer.bankAccount">{{ freelanceCustomer.bankAccount.iban }}</div>
          <div *ngIf="freelanceCustomer.bankAccount">{{ freelanceCustomer.bankAccount.bic }}</div>
        </div>
        <ti-freelance-customer
          (cancel)="editCustomerData = false"
          *ngIf="editCustomerData && freelanceCustomer"
          [freelanceCustomer]="freelanceCustomer"
          (created)="saveFreelanceCustomer($event)"></ti-freelance-customer>
      </div>
      <div e2e="card-customer-address" class="ti-card data-item card-customer-address">
        <til-divider actionButtonIcon="edit" (buttonClicked)="editAddress = true" icon="home">{{
          "customer.manage.showCustomer.customerAddress" | translate
        }}</til-divider>
        <div *ngIf="customer.address && !editAddress; else noAddress" class="address">
          <div>{{ customer.address.street }} {{ customer.address.streetNumber }}</div>
          <div *ngIf="customer.address.addressInfo">{{ customer.address.addressInfo }}</div>
          <div>{{ customer.address.zip }} {{ customer.address.city }}</div>
        </div>
        <ng-template #noAddress>
          <div *ngIf="!editAddress">
            <div class="no-data">
              {{ "customer.manage.showCustomer.noAddressAvailable" | translate }}
            </div>
          </div>
        </ng-template>
        <div *ngIf="editAddress" class="manage-address">
          <ti-manage-address
            (cancel)="editAddress = false"
            (created)="saveAddress($event)"
            [address]="customer.address"></ti-manage-address>
        </div>
      </div>
    </div>

    <div class="right">
      <div e2e="card-billing-address" class="ti-card data-item">
        <til-divider actionButtonIcon="edit" (buttonClicked)="editBillingAddress = true" icon="local_post_office">{{
          "common.billingAddress" | translate
        }}</til-divider>
        <div *ngIf="customer.billingAddress && !editBillingAddress; else noBillingAddress" class="billing-address">
          <div *ngIf="customer.billingAddress.company">{{ customer.billingAddress.company }}</div>
          <div *ngIf="!customer.billingAddress.company">{{ customer.billingAddress.salutation }}</div>
          <div>{{ customer.billingAddress.firstName }} {{ customer.billingAddress.lastName }}</div>
          <div *ngIf="customer.billingAddress.addInfo">{{ customer.billingAddress.addInfo }}</div>
          <div>{{ customer.billingAddress.street }} {{ customer.billingAddress.streetNumber }}</div>
          <div>{{ customer.billingAddress.zip }} {{ customer.billingAddress.city }}</div>
        </div>
        <ng-template #noBillingAddress>
          <div *ngIf="!editBillingAddress">
            <div class="no-data">
              {{ "customer.manage.showCustomer.noBillingAddressAvailable" | translate }}
            </div>
          </div>
        </ng-template>
        <div *ngIf="editBillingAddress" class="manage-address">
          <ti-manage-billing-address
            [showControls]="true"
            (cancel)="editBillingAddress = false"
            (created)="saveBillingAddress($event)"
            [edit]="customer.billingAddress"
            [customer]="customer"></ti-manage-billing-address>
        </div>
      </div>

      <div *ngIf="businessCustomer" e2e="card-contact-people" class="ti card data-item">
        <til-divider>{{ "customer.manage.showCustomer.contactPeople" | translate }}</til-divider>
        <div *ngIf="businessCustomer.contactPeople && businessCustomer.contactPeople.length > 0">
          <div e2e="contact-person" *ngFor="let cp of businessCustomer.contactPeople" class="contact-person">
            <div>
              <div class="content">
                <mat-icon class="write icon" e2e="btn-edit-contact-person" (click)="editContactPerson(cp)">edit</mat-icon>
                <mat-icon class="remove icon" e2e="btn-delete-contact-person" (click)="deleteContactPerson(cp)">remove</mat-icon>
                <div class="header">
                  {{ cp.firstName }} {{ cp.lastName }} <span *ngIf="cp.department">- ({{ cp.department }} )</span>
                </div>
                <div *ngIf="cp.mail">
                  <mat-icon>email</mat-icon>
                  {{ cp.mail }}
                </div>
                <div *ngIf="cp.mobile">
                  <mat-icon>phonelink_ring</mat-icon>
                  {{ cp.mobile }}
                </div>
                <div *ngIf="cp.phone">
                  <mat-icon>call</mat-icon>
                  {{ cp.phone }}
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div>

        <div *ngIf="!contactPersonEdit && !editContactPeople" class="add-contact-person">
          <button e2e="btn-add-contact-people" (click)="addContactPerson()" mat-mini-fab color="accent">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div *ngIf="editContactPeople" class="manage-address">
          <ti-contact-person
            (cancel)="cancelSaveContactPerson()"
            [contactPerson]="contactPersonEdit"
            (created)="saveContactPerson($event)"></ti-contact-person>
        </div>
      </div>
    </div>
  </div>
</div>
