import { Component, EventEmitter, inject, Input, Output, ViewChild } from "@angular/core";
import { TiDatePipe } from "../../../../shared/pipes/ti-date-pipe";
import { ConfirmModalComponent, ConfirmModalData } from "../../../../shared/modals/confirm-modal/confirm-modal.component";
import { EuroPipe } from "../../../../shared/pipes/euro-pipe";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModalResult } from "../../../../shared/model/confirm-modal-result";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ApplicationHelper, Page } from "ti-frontend-shared";
import * as moment from "moment";
import { Moment } from "moment";
import { CreditTO } from "../../../../shared/generated/transportObjects";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ti-credit-list",
  templateUrl: "./credit-list.component.html",
  styleUrls: ["./credit-list.component.scss"]
})
export class CreditListComponent {
  @Input()
  showPagination: boolean = true;

  @Input()
  provideFilter: boolean = true;

  @Input()
  noCreditsHint: boolean;

  @Input()
  itemsPerPage: 5 | 10 | 25 | 100 = 10;

  @Input()
  itemsPerPageConfigurable: boolean = true;

  @Input()
  visibleColumns: string[] = ["date", "customer", "description", "amount", "action"];

  @Input()
  page: Page<CreditTO>;

  @Output()
  pageEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @ViewChild(MatTable<CreditTO>)
  table: MatTable<CreditTO>;

  tableDataSource: MatTableDataSource<CreditTO>;

  @Output()
  deleted: EventEmitter<CreditTO> = new EventEmitter<CreditTO>();

  @Output()
  edit: EventEmitter<CreditTO> = new EventEmitter<CreditTO>();

  private dp: TiDatePipe = inject(TiDatePipe);
  private ep: EuroPipe = inject(EuroPipe);
  private dialog: MatDialog = inject(MatDialog);
  private translate: TranslateService = inject(TranslateService);

  constructor() {}

  getDateString(credit: CreditTO): string {
    if (credit.creditDate) {
      return this.dp.transform(credit.creditDate);
    } else {
      return credit.creditMonth;
    }
  }

  checkDelete(credit: CreditTO): void {
    const context: ConfirmModalData = {
      title: "customer.shared.components.creditList.reallyDelete",
      question:
        this.translate.instant("customer.shared.components.creditList.youAreAboutToDeleteTheCreditFrom") +
        credit.customer +
        this.translate.instant("customer.shared.components.creditList.over") +
        this.ep.transform(credit.amount)
    };
    this.dialog
      .open(ConfirmModalComponent, { data: context })
      .afterClosed()
      .subscribe({
        next: (res: ConfirmModalResult) => {
          if (this.translate.instant(ConfirmModalResult.ACCEPT) === res) {
            this.deleted.emit(credit);
          }
        }
      });
  }

  handlePageEvent(event: PageEvent): void {
    this.pageEvent.emit(event);
  }

  updateRows(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<CreditTO>(this.sortCredits(this.page.content));
      if (this.paginator) {
        this.paginator.pageIndex = this.page.number;
        this.paginator.pageSize = this.page.size;
      }
      this.table.renderRows();
      this.table.dataSource = this.tableDataSource;
    });
  }

  private sortCredits(credits: CreditTO[]): CreditTO[] {
    return credits.sort((a: CreditTO, b: CreditTO) => {
      let aCompDate: Moment;
      if (ApplicationHelper.isObjectDefined(a.creditDate)) {
        aCompDate = moment(a.creditDate);
      } else {
        aCompDate = ApplicationHelper.isObjectDefined(a.creditMonth) ? moment(a.creditMonth) : undefined;
      }

      let bCompDate: Moment;
      if (ApplicationHelper.isObjectDefined(b.creditDate)) {
        bCompDate = moment(b.creditDate);
      } else {
        bCompDate = ApplicationHelper.isObjectDefined(b.creditMonth) ? moment(b.creditMonth) : undefined;
      }
      if (!ApplicationHelper.isObjectDefined(aCompDate)) {
        return -1;
      }
      if (!ApplicationHelper.isObjectDefined(bCompDate)) {
        return 1;
      }
      return aCompDate.isBefore(bCompDate) ? 1 : -1;
    });
  }
}
