<div class="modal">
  <h2 class="header">{{ data.title | translate }}</h2>
  <div class="content">
    <p>{{ data.question | translate }}</p>
  </div>
  <mat-divider></mat-divider>
  <div class="actions">
    <button e2e="btn-cancel" (click)="close()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" (click)="accept()" mat-raised-button color="primary">
      {{ data.approveLabel || "common.accept" | translate }}
    </button>
  </div>
</div>
