import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../../shared/utils/ValidationHelper";
import { BusinessCustomerTO } from "../../../shared/generated/transportObjects";
import { BusinessCustomerForm } from "../../form-model/business-customer-form";

@Component({
  selector: "ti-business-customer",
  templateUrl: "./business-customer.component.html",
  styleUrls: ["./business-customer.component.scss"]
})
export class BusinessCustomerComponent implements OnInit {
  @Input()
  businessCustomer: BusinessCustomerTO;

  @Output()
  created: EventEmitter<BusinessCustomerTO> = new EventEmitter<BusinessCustomerTO>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup;
  createMode: boolean;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}
  ngOnInit(): void {
    this.init();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  private init(): void {
    if (!this.businessCustomer) {
      this.createMode = true;
      this.businessCustomer = {} as any;
    }
    this.formGroup = this.createFormGroup();
  }

  private createFormGroup(): FormGroup<BusinessCustomerForm> {
    return this.fb.group<BusinessCustomerForm>({
      companyName: this.fb.control(this.businessCustomer.companyName || "", Validators.required),
      mail: this.fb.control(this.businessCustomer.mail || "", Validators.pattern(ValidationHelper.mailAddress)),
      phone: this.fb.control(this.businessCustomer.phone || "", Validators.pattern(ValidationHelper.phoneNumber)),
      vatId: this.fb.control(this.businessCustomer.vatId || "", Validators.pattern(ValidationHelper.vatIdNumber)),
      website: this.fb.control(this.businessCustomer.website || "", Validators.pattern(ValidationHelper.webAddress))
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const result: BusinessCustomerTO = this.formGroup.value;
      if (!this.createMode) {
        result.id = this.businessCustomer.id;
        result.version = this.businessCustomer.version;
        result.customerNumber = this.businessCustomer.customerNumber;
        result.contactPeople = this.businessCustomer.contactPeople;
      }
      result.type = "business";
      this.created.emit(result);
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }
}
