<div class="card-overview-container">
  <div class="ti-cards">
    <div e2e="card-btn-search-customer" class="ti-card ti-link-card cms-card medium" routerLink="/customers/search">
      <span class="material-icons">person_pin</span>
      <div>{{ "common.search" | translate }}</div>
      <p>{{ "customer.welcome.searchForCustomers" | translate }}</p>
    </div>
    <div e2e="card-btn-create-customer" class="ti-card ti-link-card cms-card medium" routerLink="/customers/create">
      <span class="material-icons">person_add</span>
      <div>{{ "common.create" | translate }}</div>
      <p>{{ "customer.welcome.createNewCustomer" | translate }}</p>
    </div>
    <div e2e="card-btn-show-all-customers" class="ti-card ti-link-card cms-card medium" routerLink="/customers/all">
      <span class="material-icons">people_alt</span>
      <div>{{ "customer.welcome.show" | translate }}</div>
      <p>{{ "customer.welcome.showAllCustomers" | translate }}</p>
    </div>
  </div>

  <div class="icon-message">
    <til-flex-icon-message size="20px" color="accent" [withCountTo]="true" [amount]="count" icon="people">{{
      "common.customers" | translate
    }}</til-flex-icon-message>
  </div>
</div>
