export class ApiEndpoints {
  // User
  static loginCmsUser = "/treeit/cm/rest/v1/users/login";
  static updatePreferredLanguage = "/treeit/cm/rest/v1/users/update/lang";
  // Customer
  static createCustomer = "/treeit/cm/rest/v1/customers/create";
  static updateCustomer = (customerNumber: string): string => `/treeit/cm/rest/v1/customers/update/${customerNumber}`;
  static deleteCustomer = (customerNumber: string): string => `/treeit/cm/rest/v1/customers/${customerNumber}`;
  static findByString = "/treeit/cm/rest/v1/customers/search";
  static findCustomer = "/treeit/cm/rest/v1/customers/find";
  static saveAddress = (customerNumber: string): string => `/treeit/cm/rest/v1/customers/${customerNumber}/address/save`;
  static saveBankAccount = (customerNumber: string): string => `/treeit/cm/rest/v1/customers/${customerNumber}/bankaccount/save`;
  static saveBillingAddress = (customerNumber: string): string => `/treeit/cm/rest/v1/customers/${customerNumber}/billingaddress/save`;
  static count = "/treeit/cm/rest/v1/customers/count";
  static getAllCustomers = "/treeit/cm/rest/v1/customers/all";
  static loadCustomerHeaders = "/treeit/cm/rest/v1/customers/meta/all";

  static saveContactPeople = (businessCustomerNumber: string): string =>
    `/treeit/cm/rest/v1/customers/${businessCustomerNumber}/contact-people/save`;

  // Customer Credits
  static getAllCredits = "/treeit/cm/rest/v1/credits/all";
  static createCredit = (cutomerNumber: string): string => `/treeit/cm/rest/v1/credits/create/${cutomerNumber}`;
  static deleteCredit = (creditId: number): string => `/treeit/cm/rest/v1/credits/${creditId}`;
  static updateCredit = (creditId: number): string => `/treeit/cm/rest/v1/credits/update/${creditId}`;

  static deleteInvoice = (invoiceId: number): string => `/treeit/cm/rest/v1/invoices/${invoiceId.toString()}`;

  static updateInvoice = "/treeit/cm/rest/v1/invoices/update";
  static printCustomerInvoice = (invoiceNumber: string): string => `/treeit/cm/rest/v1/invoices/customers/print/${invoiceNumber}`;
  static printProductInvoice = (invoiceNumber: string): string => `/treeit/cm/rest/v1/invoices/products/print/${invoiceNumber}`;
  static outstandings = "/treeit/cm/rest/v1/invoices/outstandings";
  static findCustomerByInvoiceNumber = (invoiceNumber: string): string =>
    `/treeit/cm/rest/v1/invoices/customers/findbyinvoice/${invoiceNumber}`;
  static correctInvoice = (invoiceId: number): string => `/treeit/cm/rest/v1/invoices/correct/${invoiceId.toString()}`;

  // Invoices customers
  static createInvoice = (customerNumber: string): string => `/treeit/cm/rest/v1/invoices/customers/${customerNumber}/create`;
  static findAllCustomerInvoices = "/treeit/cm/rest/v1/invoices/customers/all";
  static findAllInvoicesForCustomer = (customerNumber: string): string => `/treeit/cm/rest/v1/invoices/customers/${customerNumber}/all`;
  static findAllOpenCustomerInvoices = "/treeit/cm/rest/v1/invoices/customers/open";
  static findAllDueCustomerInvoices = `/treeit/cm/rest/v1/invoices/customers/due`;
  static changePaymentStatus = (invoiceNumber: string): string => `/treeit/cm/rest/v1/invoices/customers/${invoiceNumber}/change/payment`;
  static findByInvoiceId = (invoiceNumber: string): string => `/treeit/cm/rest/v1/invoices/customers/find/${invoiceNumber}`;
  static findAllInvoiceTemplates = "/treeit/cm/rest/v1/invoice/templates";

  // Invoices Products
  static findAllProductInvoices = "/treeit/cm/rest/v1/invoices/products/all";
  static findAllOpenProductInvoices = "/treeit/cm/rest/v1/invoices/products/open";
  static findAllDueProductInvoices = "/treeit/cm/rest/v1/invoices/products/due";

  // Stats
  static getQuickStats = "/treeit/cm/rest/v1/statistics/overview/quick";
  static getStats = "/treeit/cm/rest/v1/statistics/all";
  static getSales = "/treeit/cm/rest/v1/statistics/sales";
  static getSalesPositions = (year: number): string => `/treeit/cm/rest/v1/statistics/sales-positions/${year}`;
  static reportProfit = "/treeit/cm/rest/v1/statistics/profit/report";

  // Products
  static findAllProducts = "/treeit/cm/rest/v1/common/products/all";

  // Auth
  static auth = "/treeit/cm/rest/v1/users/login";

  // Company
  static updateCompany = (companyId: number): string => `/treeit/cm/rest/v1/company/${companyId}/update`;
}
