import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class HeaderFactory {
  private state: { [param: string]: string | string[] };

  create(): HeaderFactory {
    this.state = {};
    return this;
  }

  add(key: string, value: string): HeaderFactory {
    this.state[key] = value;
    return this;
  }

  build(): HttpParams {
    return new HttpParams({ fromObject: this.state });
  }
}
