import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../utils/ValidationHelper";
import { AddressTO } from "../../generated/transportObjects";
import { ManageAddressForm } from "../../form-model/manage-address-form";

@Component({
  selector: "ti-manage-address",
  templateUrl: "./manage-address.component.html",
  styleUrls: ["./manage-address.component.scss"]
})
export class ManageAddressComponent implements OnInit {
  @Input()
  address: AddressTO;

  @Output()
  created: EventEmitter<AddressTO> = new EventEmitter<AddressTO>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  private init(): void {
    if (!this.address) {
      this.address = {} as any;
    }
    this.formGroup = this.createFormGroup();
  }

  private createFormGroup(): FormGroup<ManageAddressForm> {
    return this.fb.group<ManageAddressForm>({
      id: this.fb.control(this.address.id),
      version: this.fb.control(this.address.version),
      street: this.fb.control(this.address.street || "", Validators.required),
      streetNumber: this.fb.control(this.address.streetNumber || "", [
        Validators.required,
        Validators.pattern(ValidationHelper.streetNumber)
      ]),
      city: this.fb.control(this.address.city || "", Validators.required),
      zip: this.fb.control(this.address.zip || "", [Validators.required, Validators.pattern(ValidationHelper.zip)]),
      addressInfo: this.fb.control(this.address.addressInfo || "")
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      this.created.emit(this.formGroup.value);
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }
}
