<div class="card-container" *ngIf="sales && sales.salesPerYear">
  <div (click)="report(years)" *ngFor="let years of Object.keys(sales.salesPerYear)" class="ti selectable card">
    <div class="headline">{{ years }}</div>
    <mat-divider></mat-divider>
    <div class="meta">
      <div>
        <span
          >{{ "invoice.profitReporting.sales" | translate }}: <b>{{ sales.salesPerYear[years].totalSales | euro }}</b></span
        >
      </div>
      <div>
        <span
          >{{ "invoice.profitReporting.profit" | translate }}: <b>{{ sales.salesPerYear[years].profit || 0 | euro }}</b></span
        >
      </div>
    </div>
  </div>
</div>
