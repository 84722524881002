import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TimeUnit } from "../../shared/enums/time-unit.enum";
import { ValidationHelper } from "../../../shared/utils/ValidationHelper";
import { ApplicationStateService } from "../../../core/services/application-state.service";
import { InvoiceDataTO } from "../../../shared/generated/transportObjects";
import { TranslateService } from "@ngx-translate/core";
import { InvoiceForm } from "../../form-model/invoice-form";

@Component({
  selector: "ti-edit-invoice-data",
  templateUrl: "./edit-invoice-data.component.html",
  styleUrls: ["./edit-invoice-data.component.scss"]
})
export class EditInvoiceDataComponent implements OnInit {
  formGroup: FormGroup;
  timeUnitOptions: any[] = [
    {
      text: "Weeks",
      value: TimeUnit.WEEKS
    },
    { text: "Months", value: TimeUnit.MONTHS },
    { text: "Days", value: TimeUnit.DAYS }
  ];

  @Output()
  dataCreated: EventEmitter<InvoiceDataTO> = new EventEmitter<InvoiceDataTO>();

  @Output()
  back: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  invoiceData: InvoiceDataTO;

  @Input()
  showControls: boolean;

  private fb: FormBuilder = inject(FormBuilder);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private translate: TranslateService = inject(TranslateService);

  constructor() {}

  ngOnInit(): void {
    if (!this.invoiceData) {
      const now: Date = new Date();
      this.invoiceData = {
        invoiceDate: now,
        invoiceTextAfter: "",
        invoiceTextBefore: this.translate.instant("invoice.newInvoice.invoiceTextBefore"),
        numberPrefix: now.getFullYear().toString(),
        paid: false,
        salutation: this.translate.instant("invoice.newInvoice.salutation"),
        subject: this.translate.instant("invoice.newInvoice.costAccounting"),
        timeForPayment: 30,
        timeUnit: TimeUnit.DAYS,
        taxInPercent: 19,
        signer: undefined,
        dueString: undefined
      } as any;
    }
    this.formGroup = this.createFormGroup();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  createFormGroup(): FormGroup<InvoiceForm> {
    return this.fb.group<InvoiceForm>({
      id: this.fb.control(this.invoiceData.id),
      version: this.fb.control(this.invoiceData.version),
      invoiceDate: this.fb.control(this.invoiceData.invoiceDate, Validators.required),
      invoiceTextAfter: this.fb.control(this.invoiceData.invoiceTextAfter),
      invoiceTextBefore: this.fb.control(this.invoiceData.invoiceTextBefore),
      numberPrefix: this.fb.control(this.invoiceData.numberPrefix, Validators.required),
      paid: this.fb.control(this.invoiceData.paid, Validators.required),
      salutation: this.fb.control(this.invoiceData.salutation),
      subject: this.fb.control(this.invoiceData.subject, Validators.required),
      timeForPayment: this.fb.control(this.invoiceData.timeForPayment, Validators.required),
      timeUnit: this.fb.control(this.invoiceData.timeUnit, Validators.required),
      signer: this.fb.control(this.invoiceData.signer),
      taxInPercent: this.fb.control(this.invoiceData.taxInPercent, Validators.required)
    });
  }

  dueString(data: InvoiceDataTO): string {
    let unit: string;
    if (data.timeUnit === TimeUnit.DAYS) {
      unit = this.translate.instant("invoice.newInvoice.editInvoiceData.days");
    } else if (data.timeUnit === TimeUnit.MONTHS) {
      unit = this.translate.instant("invoice.newInvoice.editInvoiceData.months");
    } else if (data.timeUnit === TimeUnit.WEEKS) {
      unit = this.translate.instant("invoice.newInvoice.editInvoiceData.weeks");
    }
    return data.timeForPayment.toString() + unit;
  }

  save(): void {
    if (this.formGroup.valid) {
      const invoiceData: InvoiceDataTO = this.formGroup.value;
      if (!invoiceData.paid) {
        const dueString: string =
          this.translate.instant("invoice.newInvoice.payableWithin") +
          this.dueString(invoiceData) +
          this.translate.instant("invoice.newInvoice.purelyNett");
        invoiceData.dueString = dueString;
      }
      this.dataCreated.emit(invoiceData);
    }
  }

  cancelEdit(): void {
    this.applicationStateService.setInvoiceStep(4, this.formGroup.value);
    this.back.emit();
  }
}
