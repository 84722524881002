<div class="form-container">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="save()">
    <mat-form-field>
      <mat-label>{{ "customer.manage.businessCustomer.company" | translate }}</mat-label>
      <input
        [maxlength]="100"
        e2e="input-company"
        matInput
        placeholder="{{ 'customer.manage.businessCustomer.company' | translate }}"
        formControlName="companyName" />
      <mat-error *ngIf="hasError('companyName')">{{ "formValidation.customer.business.required.companyName" | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "customer.manage.common.email" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-email" matInput placeholder="info@company.com" formControlName="mail" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "customer.manage.common.phone" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-phone" matInput placeholder="09701 675 999 0" formControlName="phone" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "customer.manage.businessCustomer.website" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-website" matInput placeholder="www.company.com" formControlName="website" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "customer.manage.businessCustomer.vatID" | translate }}</mat-label>
      <input [maxlength]="20" e2e="input-vat-id" matInput placeholder="XXX XXX XXX" formControlName="vatId" />
    </mat-form-field>
  </form>
  <div class="ti controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
