import { Component, inject, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { InvoiceService } from "../../../core/services/invoice.service";
import { InvoiceTO } from "../../generated/transportObjects";

@Component({
  selector: "ti-correct-invoice-modal",
  templateUrl: "./correct-invoice-modal.component.html",
  styleUrls: ["./correct-invoice-modal.component.scss"]
})
export class CorrectInvoiceModalComponent {
  private dialogRef: MatDialogRef<CorrectInvoiceModalComponent> = inject(MatDialogRef<CorrectInvoiceModalComponent>);
  private invoiceService: InvoiceService = inject(InvoiceService);
  constructor(@Inject(MAT_DIALOG_DATA) public data: InvoiceTO) {}

  close(): void {
    this.dialogRef.close(false);
  }

  correctInvoice(): void {
    this.invoiceService.correctInvoice(this.data.id).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: () => this.dialogRef.close(false)
    });
  }
}
