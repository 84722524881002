import { LoginComponent } from "./components/login/login.component";
import { ApplicationStateService } from "./services/application-state.service";
import { NotificationService } from "./services/notification.service";
import { ErrorResponseService } from "./services/error-response.service";
import { StartComponent } from "./components/start/start.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { Subject } from "rxjs";
import { TokenInterceptorService } from "./services/token-interceptor-service.service";
import { ProductsOverviewComponent } from "./components/products/products-overview/products-overview.component";
import { SharedModule } from "../shared/shared.module";
import { CustomerService } from "./services/customer.service";
import { InvoiceService } from "./services/invoice.service";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { UserService } from "./services/user.service";
import { CompanyService } from "./services/company.service";
import { InvoiceTemplateService } from "./services/invoiceTemplateService";

@NgModule({
  imports: [SharedModule],
  exports: [StartComponent, LoginComponent, SidenavComponent],
  declarations: [StartComponent, LoginComponent, ProductsOverviewComponent, SidenavComponent],
  providers: [
    Subject,
    ErrorResponseService,
    CustomerService,
    InvoiceService,
    NotificationService,
    ApplicationStateService,
    TokenInterceptorService,
    UserService,
    CompanyService,
    InvoiceTemplateService,
    { provide: LOCALE_ID, useValue: "de-DE" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ]
})
export class CoreModule {}
