import { Component, inject, OnInit } from "@angular/core";
import { EuroPipe } from "../../shared/pipes/euro-pipe";
import { ActivatedRoute } from "@angular/router";
import { SalesPerMonthTO, SalesPerYearTO, StatisticTO } from "../../shared/generated/transportObjects";
import { ChartConfiguration } from "chart.js";

@Component({
  selector: "ti-stat-sales-per-year",
  templateUrl: "./stat-sales-per-year.component.html",
  styleUrls: ["./stat-sales-per-year.component.scss"]
})
export class StatSalesPerYearComponent implements OnInit {
  statistics: StatisticTO;
  options: ChartConfiguration<"bar">["options"];
  chartData: ChartConfiguration<"bar">["data"] = { labels: [], datasets: [] };
  selectedFilterOne: string;
  filterOptionsOne: string[] = [];
  selectedFilterTwo: string;
  filterOptionsTwo: string[] = [];
  private route: ActivatedRoute = inject(ActivatedRoute);
  private ep: EuroPipe = inject(EuroPipe);
  constructor() {}

  ngOnInit(): void {
    this.statistics = this.route.snapshot.data.statistics;
    this.initOptions();
    if (this.statistics?.salesPerYear && Object.keys(this.statistics.salesPerYear).length > 0) {
      this.initFilterOptions();
      this.initData();
    }
  }

  filter(): void {
    this.chartData = { labels: [], datasets: [] };
    this.initData();
  }

  private initData(): void {
    this.initLabels();
    const yearOne: number = +this.selectedFilterOne;
    const yearTwo: number = +this.selectedFilterTwo;
    const dataOne: number[] = [];
    const dataTwo: number[] = [];
    this.chartData.datasets = [];
    const salesYearOne: SalesPerYearTO = this.statistics.salesPerYear[yearOne];
    salesYearOne.salesPerMonth.forEach((salesPerMonth: SalesPerMonthTO) => dataOne.push(salesPerMonth.sales));
    this.chartData.datasets.push({ data: dataOne, label: yearOne.toString() });
    const salesYearTwo: SalesPerYearTO = this.statistics.salesPerYear[yearTwo];
    salesYearTwo.salesPerMonth.forEach((salesPerMonth: SalesPerMonthTO) => dataTwo.push(salesPerMonth.sales));
    this.chartData.datasets.push({ data: dataTwo, label: yearTwo.toString() });
  }

  private initLabels(): void {
    for (let i: number = 1; i <= 12; i++) {
      this.chartData.labels.push(i.toString());
    }
  }

  private initFilterOptions(): void {
    const availableYears: string[] = Object.keys(this.statistics.salesPerYear);
    this.filterOptionsTwo = [];
    this.filterOptionsOne = [];
    availableYears.forEach((year: string) => {
      this.filterOptionsOne.push(year);
      this.filterOptionsTwo.push(year);
    });
    if (availableYears.length === 1) {
      this.selectedFilterOne = this.selectedFilterTwo = availableYears.pop();
    } else {
      this.selectedFilterTwo = availableYears.pop();
      this.selectedFilterOne = availableYears.pop();
    }
  }

  private initOptions(): void {
    const that: this = this;
    this.options = {
      plugins: {
        legend: {
          position: "top",
          display: true,
          fullSize: true,
          labels: {
            font: {
              size: 18
            },
            padding: 20
          }
        },
        tooltip: {
          callbacks: {
            label: function (context): string {
              let value: number = context.parsed.y as number;
              return that.ep.transform(value);
            }
          }
        }
      },
      responsive: true
    };
  }
}
