import { ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { InvoiceService } from "../../../../core/services/invoice.service";
import { Observable } from "rxjs";
import { DownloadHelper } from "../../utils/download-helper";
import { ApplicationHelper, Page, SpinnerService } from "ti-frontend-shared";
import { ApplicationStateService } from "../../../../core/services/application-state.service";
import { Router } from "@angular/router";
import { CustomerService } from "../../../../core/services/customer.service";
import { MatDialog } from "@angular/material/dialog";
import { CorrectInvoiceModalComponent } from "../../../../shared/modals/correct-invoice-modal/correct-invoice-modal.component";
import { CustomerInvoiceTO, CustomerTO, InvoiceDataTO, InvoiceTO, PositionTOUnion } from "../../../../shared/generated/transportObjects";
import * as moment from "moment";
import { Moment } from "moment";

@Component({
  selector: "ti-invoice-list",
  templateUrl: "./invoice-list.component.html",
  styleUrls: ["./invoice-list.component.scss"]
})
export class InvoiceListComponent implements OnInit, OnChanges {
  currentCustomerPage: number;
  currentProductPage: number;
  productInvoices: Page<InvoiceTO>;
  customerInvoices: Page<CustomerInvoiceTO>;
  showDetails: InvoiceTO;
  editInvoice: CustomerInvoiceTO;

  @Input()
  customerPageLoader: (page: number) => Observable<Page<InvoiceTO>>;
  @Input()
  productPageLoader: (page: number) => Observable<Page<InvoiceTO>>;

  @Input()
  customerId: number;

  private is: InvoiceService = inject(InvoiceService);
  private cs: CustomerService = inject(CustomerService);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private router: Router = inject(Router);
  private spinner: SpinnerService = inject(SpinnerService);
  private dialog: MatDialog = inject(MatDialog);
  private changeDetRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {}

  ngOnInit(): void {
    this.currentCustomerPage = 0;
    this.currentProductPage = 0;
    this.customerPageLoader(0).subscribe({
      next: (res: Page<CustomerInvoiceTO>) => (this.customerInvoices = res)
    });
    if (this.productPageLoader) {
      this.productPageLoader(0).subscribe({
        next: (res: Page<InvoiceTO>) => (this.productInvoices = res)
      });
    }
  }

  correctInvoice(invoice: InvoiceTO): void {
    this.dialog
      .open(CorrectInvoiceModalComponent, { data: invoice })
      .afterClosed()
      .subscribe({
        next: (corrected: boolean) => {
          if (corrected) {
            this.ngOnInit();
          }
        }
      });
  }

  printCustomerInvoice(invoice: InvoiceTO): void {
    this.spinner.spin(true);
    this.is.printCustomerInvoice(invoice.invoiceNumberString).subscribe({
      next: (res: ArrayBuffer) => {
        const blob: Blob = new Blob([res], { type: "application/pdf" });
        const fileName: string = invoice.invoiceNumberString + ".pdf";
        this.spinner.spin(false);
        DownloadHelper.downloadFile(blob, fileName);
      },
      error: () => this.spinner.spin(false)
    });
  }

  printProductInvoice(invoice: InvoiceTO): void {
    this.spinner.spin(true);
    this.is.printProductInvoice(invoice.invoiceNumberString).subscribe({
      next: (res: ArrayBuffer) => {
        const blob: Blob = new Blob([res], { type: "application/pdf" });
        const fileName: string = invoice.invoiceNumberString + ".pdf";
        this.spinner.spin(false);
        DownloadHelper.downloadFile(blob, fileName);
      },
      error: () => this.spinner.spin(false)
    });
  }

  changePaymentState(invoice: InvoiceTO): void {
    const currentInvoice: InvoiceTO = this.customerInvoices.content.find(
      (value: CustomerInvoiceTO) => value.invoiceNumberString === invoice.invoiceNumberString
    );
    this.is.changePaymentStatus(invoice.invoiceNumberString).subscribe({
      next: () => (currentInvoice.invoiceData.paid = !currentInvoice.invoiceData.paid),
      complete: () => this.changeDetRef.detectChanges()
    });
  }

  loadMoreCustomerInvoices(): void {
    this.currentCustomerPage++;
    this.customerPageLoader(this.currentCustomerPage).subscribe({
      next: (res: Page<CustomerInvoiceTO>) => (this.customerInvoices.content = this.customerInvoices.content.concat(res.content))
    });
  }

  loadMoreProductInvoices(): void {
    this.currentProductPage++;
    this.productPageLoader(this.currentProductPage).subscribe({
      next: (res: Page<InvoiceTO>) => (this.productInvoices.content = this.productInvoices.content.concat(res.content))
    });
  }

  edit(invoice: InvoiceTO | CustomerInvoiceTO, type: "customer" | "product"): void {
    invoice.type = type;
    if ("customerInvoiceType" in invoice) {
      this.editInvoice = invoice;
    }
  }

  cancelEditMode(): void {
    this.editInvoice = undefined;
  }

  saved(): void {
    this.ngOnInit();
    this.editInvoice = undefined;
  }

  copyInvoiceDataForNewOne(invoice: InvoiceTO | CustomerInvoiceTO): void {
    this.spinner.spin(true);

    this.is.findCustomerByInvoiceNumber(invoice.invoiceNumberString).subscribe({
      next: (customerNumber: string) => {
        this.cs.findCustomer(customerNumber).subscribe({
          next: (customer: CustomerTO) => {
            const invoiceData: InvoiceDataTO = invoice.invoiceData;
            const today: Moment = moment();
            const numberPrefix: string = today.get("year").toString() + "-";
            const invoiceDate: string = today.format("YYYY-MM-DD");
            invoice.positions.forEach((p: PositionTOUnion) => (p.id = p.version = undefined));
            invoice.invoiceData.id = invoice.invoiceData.version = undefined;
            invoice.billingAddress.id = invoice.billingAddress.version = undefined;
            this.applicationStateService.clearSteps();
            this.applicationStateService.setInvoiceCopy();
            this.applicationStateService.setInvoiceStep(1, customer);
            if ("customerInvoiceType" in invoice) {
              this.applicationStateService.setInvoiceStep(2, invoice.customerInvoiceType);
            }
            this.applicationStateService.setInvoiceStep(3, invoice.billingAddress);
            this.applicationStateService.setInvoiceStep(4, { ...invoiceData, paid: false, invoiceDate, numberPrefix });
            this.applicationStateService.setInvoiceStep(5, invoice.positions);
            this.spinner.spin(false);
            this.router.navigateByUrl("invoices/new");
          }
        });
      },
      error: () => this.spinner.spin(false)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerPageLoader.currentValue !== changes.customerPageLoader.previousValue) {
      this.customerPageLoader(0).subscribe({
        next: (value: Page<CustomerInvoiceTO>) => {
          this.customerInvoices = value;
        }
      });
    }
    if (
      ApplicationHelper.isObjectDefined(changes?.productPageLoader?.currentValue) &&
      changes.productPageLoader.currentValue !== changes.productPageLoader.previousValue
    ) {
      this.productPageLoader(0).subscribe({
        next: (value: Page<InvoiceTO>) => {
          this.productInvoices = value;
        }
      });
    }
  }
}
