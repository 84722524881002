import { inject, Inject, Injectable } from "@angular/core";
import { HeaderFactory } from "../utils/header-factory";
import { HttpClient } from "@angular/common/http";
import { ApiEndpoints } from "../../shared/utils/api-endpoints";
import { CustomerInvoiceTO, InvoiceTO, ReceivablesTO } from "../../shared/generated/transportObjects";
import { Page } from "ti-frontend-shared";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class InvoiceService {
  private http: HttpClient = inject(HttpClient);
  private hf: HeaderFactory = inject(HeaderFactory);
  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    this.hf = new HeaderFactory();
  }

  deleteInvoice(invoiceId: number): Observable<void> {
    const url: string = this.serverUrl + ApiEndpoints.deleteInvoice(invoiceId);
    return this.http.delete<void>(url);
  }

  updateInvoice(invoice: CustomerInvoiceTO): Observable<InvoiceTO> {
    const url: string = this.serverUrl + ApiEndpoints.updateInvoice;
    return this.http.put<InvoiceTO>(url, invoice);
  }

  printCustomerInvoice(invoiceNumber: string): Observable<ArrayBuffer> {
    const url: string = this.serverUrl + ApiEndpoints.printCustomerInvoice(invoiceNumber);
    return this.http.get(url, { responseType: "arraybuffer" });
  }

  printProductInvoice(invoiceNumber: string): Observable<ArrayBuffer> {
    const url: string = this.serverUrl + ApiEndpoints.printProductInvoice(invoiceNumber);
    return this.http.get(url, { responseType: "arraybuffer" });
  }
  getOutstandings(): Observable<ReceivablesTO> {
    const url: string = this.serverUrl + ApiEndpoints.outstandings;
    return this.http.get<ReceivablesTO>(url);
  }

  correctInvoice(invoiceId: number): Observable<InvoiceTO> {
    const url: string = this.serverUrl + ApiEndpoints.correctInvoice(invoiceId);
    return this.http.post<InvoiceTO>(url, {});
  }

  saveInvoice(invoice: CustomerInvoiceTO, customerNumber: string): Observable<InvoiceTO> {
    const url: string = this.serverUrl + ApiEndpoints.createInvoice(customerNumber);
    return this.http.post<InvoiceTO>(url, invoice);
  }

  findAllProductInvoices(page: number): Observable<Page<InvoiceTO>> {
    const url: string = this.serverUrl + ApiEndpoints.findAllProductInvoices;
    return this.http.get<Page<InvoiceTO>>(url, { params: this.hf.create().add("page", page.toString()).build() });
  }

  findAllCustomerInvoices(page: number): Observable<Page<InvoiceTO>> {
    const url: string = this.serverUrl + ApiEndpoints.findAllCustomerInvoices;
    return this.http.get<Page<InvoiceTO>>(url, { params: this.hf.create().add("page", page.toString()).build() });
  }

  changePaymentStatus(invoiceNumber: string): Observable<InvoiceTO> {
    const url: string = this.serverUrl + ApiEndpoints.changePaymentStatus(invoiceNumber);
    return this.http.put<InvoiceTO>(url, {});
  }

  findAllByCustomerId(page: number, customerNumber: string): Observable<Page<InvoiceTO>> {
    const url: string = this.serverUrl + ApiEndpoints.findAllInvoicesForCustomer(customerNumber);
    return this.http.get<Page<InvoiceTO>>(url, { params: this.hf.create().add("page", page.toString()).build() });
  }

  findAllOpenProductInvoices(page: number): Observable<Page<InvoiceTO>> {
    const url: string = this.serverUrl + ApiEndpoints.findAllOpenProductInvoices;
    return this.http.get<Page<InvoiceTO>>(url, { params: this.hf.create().add("page", page.toString()).build() });
  }

  findAllOpenCustomerInvoices(page: number): Observable<Page<InvoiceTO>> {
    const url: string = this.serverUrl + ApiEndpoints.findAllOpenCustomerInvoices;
    return this.http.get<Page<InvoiceTO>>(url, { params: this.hf.create().add("page", page.toString()).build() });
  }

  findAllDueProductInvoices(page: number): Observable<Page<InvoiceTO>> {
    const url: string = this.serverUrl + ApiEndpoints.findAllDueProductInvoices;
    return this.http.get<Page<InvoiceTO>>(url, { params: this.hf.create().add("page", page.toString()).build() });
  }

  findAllDueCustomerInvoices(page: number): Observable<Page<InvoiceTO>> {
    const url: string = this.serverUrl + ApiEndpoints.findAllDueCustomerInvoices;
    return this.http.get<Page<InvoiceTO>>(url, { params: this.hf.create().add("page", page.toString()).build() });
  }

  findCustomerByInvoiceNumber(invoiceNumber: string): Observable<string> {
    const url: string = this.serverUrl + ApiEndpoints.findCustomerByInvoiceNumber(invoiceNumber);
    return this.http.get(url, { responseType: "text" });
  }
}
