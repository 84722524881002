import { inject, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "tiDateTime"
})
export class TiDateTimePipe implements PipeTransform {
  private datePipe: DatePipe = inject(DatePipe);
  constructor() {}

  transform(value): string {
    const formatted: string = this.datePipe.transform(value, "dd.MM.yyyy - HH:mm");
    return formatted + " Uhr";
  }
}
