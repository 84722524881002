<div class="spinner-wrapper">
  <div class="loader">
    <div class="face">
      <div class="circle"></div>
    </div>
    <div class="face">
      <div class="circle"></div>
    </div>
  </div>
</div>
