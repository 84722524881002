import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../../shared/utils/ValidationHelper";
import { FreelanceCustomerTO } from "../../../shared/generated/transportObjects";
import { FreelanceCustomerForm } from "../../form-model/freelance-customer-form";

@Component({
  selector: "ti-freelance-customer",
  templateUrl: "./freelance-customer.component.html",
  styleUrls: ["./freelance-customer.component.scss"]
})
export class FreelanceCustomerComponent implements OnInit {
  @Input()
  freelanceCustomer: FreelanceCustomerTO;

  @Output()
  created: EventEmitter<FreelanceCustomerTO> = new EventEmitter<FreelanceCustomerTO>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup;
  createMode: boolean;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  private init(): void {
    if (!this.freelanceCustomer) {
      this.createMode = true;
      this.freelanceCustomer = {} as any;
    }
    this.formGroup = this.createFormGroup();
  }

  private createFormGroup(): FormGroup<FreelanceCustomerForm> {
    return this.fb.group<FreelanceCustomerForm>({
      salutation: this.fb.control(this.freelanceCustomer.salutation || "", Validators.required),
      firstName: this.fb.control(this.freelanceCustomer.firstName || "", Validators.required),
      lastName: this.fb.control(this.freelanceCustomer.lastName || "", Validators.required),
      mobile: this.fb.control(this.freelanceCustomer.mobile || "", Validators.pattern(ValidationHelper.mobilePhoneNumber)),
      mail: this.fb.control(this.freelanceCustomer.mail || "", Validators.pattern(ValidationHelper.mailAddress)),
      phone: this.fb.control(this.freelanceCustomer.phone || "", Validators.pattern(ValidationHelper.phoneNumber)),
      companyName: this.fb.control(this.freelanceCustomer.companyName || ""),
      bankAccount: this.fb.group({
        bankname: this.fb.control(this.freelanceCustomer.bankAccount?.bankname || ""),
        iban: this.fb.control(this.freelanceCustomer.bankAccount?.iban || "", Validators.pattern(ValidationHelper.iban)),
        bic: this.fb.control(this.freelanceCustomer.bankAccount?.bic || "", Validators.pattern(ValidationHelper.bic))
      })
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const result: FreelanceCustomerTO = this.formGroup.value;
      if (!result.bankAccount.bic || !result.bankAccount.iban || !result.bankAccount.bankname) {
        result.bankAccount = undefined;
      }

      if (!this.createMode) {
        result.id = this.freelanceCustomer.id;
        result.version = this.freelanceCustomer.version;
        result.customerNumber = this.freelanceCustomer.customerNumber;
      }
      result.type = "freelance";
      this.created.emit(result);
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }

  isDirtyOrTouched(): boolean {
    const firstName: boolean = this.formGroup.get("firstName").dirty || this.formGroup.get("firstName").touched;
    const lastName: boolean = this.formGroup.get("lastName").dirty || this.formGroup.get("lastName").touched;
    const companyName: boolean = this.formGroup.get("companyName").dirty || this.formGroup.get("companyName").touched;
    if (firstName || lastName || companyName) {
      return true;
    }
    return false;
  }
}
