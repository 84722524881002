<div class="select">
  <mat-form-field>
    <mat-label>{{ "statistics.salesPerYear.year" | translate }} A</mat-label>
    <mat-select (selectionChange)="filter()" [(value)]="selectedFilterOne">
      <mat-option *ngFor="let option of filterOptionsOne" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ "statistics.salesPerYear.year" | translate }} B</mat-label>
    <mat-select (selectionChange)="filter()" [(value)]="selectedFilterTwo">
      <mat-option *ngFor="let option of filterOptionsTwo" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="chart-container">
  <div *ngIf="chartData.datasets.length > 0; else noData" style="display: block">
    <canvas baseChart [data]="chartData" [options]="options" [type]="'bar'" width="150" height="100"> </canvas>
  </div>
  <ng-template #noData>
    <ti-no-data></ti-no-data>
  </ng-template>
</div>
