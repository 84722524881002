import { Component, inject } from "@angular/core";
import { ProductService } from "../../../services/product.service";
import { ProductTO } from "../../../../shared/generated/transportObjects";

@Component({
  selector: "ti-products-overview",
  templateUrl: "./products-overview.component.html",
  styleUrls: ["./products-overview.component.scss"]
})
export class ProductsOverviewComponent {
  products: ProductTO[];
  private ps: ProductService = inject(ProductService);
  constructor() {
    this.ps.findAllProducts().subscribe({ next: (value: ProductTO[]) => (this.products = value) });
  }
}
