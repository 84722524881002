<div class="sales-pos-container">
  <div class="year-picker">
    <div class="ui search">
      <div (keyup.enter)="createPositions(yi.value)" class="ui icon input">
        <mat-form-field>
          <mat-label>{{ "invoice.listSalesVolume.businessYear" | translate }}</mat-label>
          <input
            matInput
            #yi="matInput"
            class="prompt"
            type="number"
            placeholder="{{ 'invoice.listSalesVolume.businessYear' | translate }}" />
        </mat-form-field>
      </div>
    </div>
    <i class="arrow right icon" (click)="createPositions(yi.value)"></i>
  </div>
  <div *ngIf="salesPostitions" class="result-table">
    <table
      *ngIf="salesPostitions.invoiceSalesPositions && salesPostitions.invoiceSalesPositions.length > 0"
      mat-table
      [dataSource]="salesPostitions.invoiceSalesPositions"
      class="credits">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{ "invoice.common.invoiceDate" | translate }}</th>
        <td mat-cell *matCellDef="let invoice">{{ invoice.invoiceDate | tiDate }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ salesPostitions.invoiceCount }} {{ "invoice.listSalesVolume.invoices" | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>{{ "invoice.listSalesVolume.invoiceNumber" | translate }}</th>
        <td mat-cell *matCellDef="let invoice">{{ invoice.invoiceNumberString }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ salesPostitions.positionCount }} {{ "invoice.common.positions" | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>{{ "common.customer" | translate }}</th>
        <td mat-cell *matCellDef="let invoice">{{ invoice.customer }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="positions">
        <th mat-header-cell *matHeaderCellDef>{{ "invoice.common.positions" | translate }}</th>
        <td mat-cell *matCellDef="let invoice">
          <div>
            <b>{{ invoice.invoiceAmount | euro }}</b>
          </div>
          <div *ngFor="let pos of invoice.positions">
            <div class="pos-row">{{ pos.positionDate }} - {{ pos.posDesc }} - {{ pos.amount | euro }}</div>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ salesPostitions.totalSales | euro }} {{ "invoice.listSalesVolume.totalSales" | translate }}
        </td>
      </ng-container>
      <tr class="header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="header" mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>
