import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

export class ValidationHelper {
  static vatIdNumber: string = "^[A-Z]{2}[0-9]{8,12}$";
  static mobilePhoneNumber: string = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]{10,12}$";
  static phoneNumber: string = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$";

  static webAddress: string = "^(http\\:\\/\\/|https\\:\\/\\/)?([a-z0-9äöüÄÖÜß][a-z0-9äöüÄÖÜß\\-]*\\.)+[a-z0-9][a-z0-9\\-]*$";
  static mailAddress: string = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
  static iban: string =
    "^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$";
  static bic: string = "[A-Z]{3,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,4}){0,1}";
  static streetNumber: string = "^[1-9]\\d*(?:[ -]?(?:[a-zA-Z]+|[1-9]\\d*))?$";
  static zip: string = "^(?!01000|99999)(0[1-9]\\d{3}|[1-9]\\d{4})$";

  static hasError(controlName: string, formGroup: FormGroup): boolean {
    const control: FormControl = formGroup.get(controlName) as FormControl;
    if (!control) {
      return false;
    }
    return control.invalid && (control.dirty || control.touched);
  }

  static hasArrayError(index: number, array: FormArray): boolean {
    const control: FormControl = array.at(index) as FormControl;
    if (!control) {
      return false;
    }
    return control.invalid && (control.dirty || control.touched);
  }

  static passwordMissmatchValidator(name1: string, name2: string): ValidatorFn {
    return (ac: AbstractControl) => {
      const group: FormGroup = ac as FormGroup;
      if (group.get(name1).value === group.get(name2).value) {
        return null;
      } else {
        return { pwNotEqual: "Passwords did not match" };
      }
    };
  }

  static atLeastOneRequired(childrenPaths: string[], errorKey?: string): ValidatorFn {
    return (c: AbstractControl) => {
      const atLeastOneHasNoRequiredError: boolean = childrenPaths.some(
        (path: string) => !Validators.required(c.get(path) as AbstractControl)
      );
      if (!atLeastOneHasNoRequiredError) {
        childrenPaths.forEach((path: string) => {
          (c.get(path) as AbstractControl).setErrors({ required: true });
        });
        const errorObj: any = {};
        errorObj[errorKey || "atLeastOneRequired"] = { fields: childrenPaths };
        return errorObj;
      } else {
        childrenPaths.forEach((path: string) => {
          (c.get(path) as AbstractControl).setErrors(null);
        });
        return null;
      }
    };
  }
}
