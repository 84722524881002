import { Component } from "@angular/core";

@Component({
  selector: "ti-statistics-welcome",
  templateUrl: "./statistics-welcome.component.html",
  styleUrls: ["./statistics-welcome.component.scss"]
})
export class StatisticsWelcomeComponent {
  constructor() {}
}
