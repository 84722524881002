import { CustomerService } from "../../core/services/customer.service";
import { Component, inject, OnInit } from "@angular/core";

@Component({
  selector: "ti-customer-welcome",
  templateUrl: "./customer-welcome.component.html",
  styleUrls: ["./customer-welcome.component.scss"]
})
export class CustomerWelcomeComponent implements OnInit {
  count: number = 0;
  private cs: CustomerService = inject(CustomerService);
  constructor() {}

  ngOnInit(): void {
    this.cs.count().subscribe({ next: (res: number) => (this.count = res) });
  }
}
