import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceService } from "../../core/services/invoice.service";
import { CustomerService } from "../../core/services/customer.service";
import { BusinessCustomerTO, CustomerTO, InvoiceTO, PrivateCustomerTO, SearchCustomerTO } from "../../shared/generated/transportObjects";
import { Observable } from "rxjs";
import { Page } from "ti-frontend-shared";

@Component({
  selector: "ti-search-invoice-by-customer",
  templateUrl: "./search-invoice-by-customer.component.html",
  styleUrls: ["./search-invoice-by-customer.component.scss"]
})
export class SearchInvoiceByCustomerComponent implements OnInit {
  customerId: string;
  customer: CustomerTO;
  customerByIdPageLoader = undefined;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private cs: CustomerService = inject(CustomerService);
  private is: InvoiceService = inject(InvoiceService);

  constructor() {}

  ngOnInit(): void {
    this.customerId = this.activatedRoute.snapshot.params["customerId"];
    if (this.customerId) {
      this.cs.findCustomer(this.customerId).subscribe({
        next: (res: CustomerTO) => {
          this.customer = res;
          this.customerByIdPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllByCustomerId(page, this.customerId);
        }
      });
    }
  }

  get customerName(): string {
    if (this.customer.type === "business") {
      return (this.customer as BusinessCustomerTO).companyName;
    } else {
      const pc: PrivateCustomerTO = this.customer as PrivateCustomerTO;
      return pc.firstName + " " + pc.lastName;
    }
  }

  selectCustomer(customer: SearchCustomerTO): void {
    this.customerId = customer.customerNumber;
    this.cs.findCustomer(customer.customerNumber).subscribe({
      next: (res: CustomerTO) => {
        this.customer = res;
        this.customerByIdPageLoader = (page: number): Observable<Page<InvoiceTO>> => this.is.findAllByCustomerId(page, this.customerId);
      }
    });
  }
}
