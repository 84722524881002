import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { CompanyTO } from "../../shared/generated/transportObjects";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyDataForm } from "../form-model/company-data-form";
import { ValidationHelper } from "../../shared/utils/ValidationHelper";

@Component({
  selector: "ti-edit-company-data",
  templateUrl: "./edit-company-data.component.html",
  styleUrls: ["./edit-company-data.component.scss"]
})
export class EditCompanyDataComponent implements OnInit {
  formGroup: FormGroup;

  @Input()
  invoiceTemplates: string[];

  @Output()
  updated: EventEmitter<CompanyTO> = new EventEmitter<CompanyTO>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  company: CompanyTO;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}
  ngOnInit(): void {
    this.formGroup = this.creatFormGroup();
  }

  creatFormGroup(): FormGroup<CompanyDataForm> {
    return this.fb.group<CompanyDataForm>({
      name: this.fb.control(this.company.name || "", Validators.required),
      legalForm: this.fb.control(this.company.legalForm || ""),
      taxId: this.fb.control(this.company.taxId || "", Validators.pattern(ValidationHelper.vatIdNumber)),
      fon: this.fb.control(this.company.fon || "", Validators.pattern(ValidationHelper.mobilePhoneNumber)),
      webAddress: this.fb.control(this.company.webAddress || "", Validators.pattern(ValidationHelper.webAddress)),
      mailAddress: this.fb.control(this.company.mailAddress || "", Validators.pattern(ValidationHelper.mailAddress)),
      invoiceTemplateName: this.fb.control(this.company.invoiceTemplateName)
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const result: CompanyTO = this.formGroup.value;
      result.companyId = this.company.companyId;
      result.version = this.company.version;
      result.address = this.company.address;
      result.bankAccount = this.company.bankAccount;
      this.updated.emit(result);
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }
}
