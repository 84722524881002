<div class="dialog-container">
  <h1 mat-dialog-title>Gewinne melden für {{ data.year }}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="profit-input">
      <mat-form-field>
        <mat-label>{{ "invoice.profitReporting.profit" | translate }}</mat-label>
        <input #pi matInput name="profit" type="number" />
      </mat-form-field>
      <p>{{ data.max | euro }} {{ "shared.modal.reportProfitModal.salesIn" | translate }} {{ data.year }}</p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions class="controls">
    <button mat-button (click)="this.onCancelClick()" color="warn">{{ "common.cancel" | translate }}</button>
    <button (click)="this.onReportClick(pi.value)" cdkFocusInitial mat-raised-button color="primary">
      {{ "invoice.invoiceWelcome.reportProfit" | translate }}
    </button>
  </div>
</div>
