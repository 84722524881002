<div class="customers">
  <div e2e="business-customer" *ngIf="businessCustomers && businessCustomers.length > 0" class="business">
    <til-divider icon="business">{{ "customer.showAllCustomers.businessCustomers" | translate }} ({{ allBusinessCustomers }})</til-divider>
    <div class="ti-cards floating">
      <div (click)="showCustomer(bc.customerNumber)" *ngFor="let bc of businessCustomers" class="ti-card ti-link-card">
        <div class="card-content">
          <div class="headline">{{ bc.companyName }}</div>
          <div class="sub-header">
            {{ bc.customerNumber }} - {{ "customer.showAllCustomers.createdAt" | translate }} {{ bc.createdAt | date }}
          </div>
          <div class="address">
            <div *ngIf="bc.address">
              <div>{{ bc.address.street }} {{ bc.address.streetNumber }}</div>
              <div>{{ bc.address.zip }}, {{ bc.address.city }}</div>
            </div>
            <div *ngIf="!bc.address">{{ "customer.showAllCustomers.noAddressCreated" | translate }}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="meta">
            <div>
              <span class="material-icons">mail</span><span>{{ bc.mail || "n/a" }}</span>
            </div>
            <div>
              <span class="material-icons">phone_enabled</span><span>{{ bc.phone || "n/a" }}</span>
            </div>
            <div>
              <span class="material-icons">web</span><span>{{ bc.website || "n/a" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div e2e="private-customer" *ngIf="privateCustomers && privateCustomers.length > 0" class="private">
    <til-divider icon="person">{{ "customer.showAllCustomers.privateCustomers" | translate }} ({{ allPrivateCustomers }})</til-divider>

    <div>
      <div class="ti-cards floating">
        <div (click)="showCustomer(pc.customerNumber)" *ngFor="let pc of privateCustomers" class="ti-card ti-link-card">
          <div class="card-content">
            <div class="headline">{{ pc.firstName }} {{ pc.lastName }}</div>
            <div class="sub-header">
              {{ pc.customerNumber }} - {{ "customer.showAllCustomers.createdAt" | translate }} {{ pc.createdAt | date }}
            </div>

            <div class="address">
              <div *ngIf="pc.address">
                <div>{{ pc.address.street }} {{ pc.address.streetNumber }}</div>
                <div>{{ pc.address.zip }}, {{ pc.address.city }}</div>
              </div>
              <div *ngIf="!pc.address">{{ "customer.showAllCustomers.noAddressCreated" | translate }}</div>
            </div>

            <mat-divider></mat-divider>

            <div class="meta">
              <div>
                <span class="material-icons">mail</span><span>{{ pc.mail || "n/a" }}</span>
              </div>
              <div>
                <span class="material-icons">phone_enabled</span><span>{{ pc.phone || "n/a" }}</span>
              </div>
              <div>
                <span class="material-icons">phonelink_ring</span><span>{{ pc.mobile || "n/a" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div e2e="freelance-customer" *ngIf="freelanceCustomer && freelanceCustomer.length > 0" class="freelance">
    <til-divider icon="transfer_within_a_station"
      >{{ "customer.showAllCustomers.freelanceCustomers" | translate }} ({{ allFreelanceCustomers }})</til-divider
    >
    <div class="ti-cards floating">
      <div (click)="showCustomer(fc.customerNumber)" *ngFor="let fc of freelanceCustomer" class="ti-link-card ti-card">
        <div class="card-content">
          <div class="headline">{{ fc.firstName }} {{ fc.lastName }}</div>
          <div class="sub-header">
            {{ fc.customerNumber }} - {{ "customer.showAllCustomers.createdAt" | translate }} {{ fc.createdAt | date }}
          </div>

          <div class="address">
            <div *ngIf="fc.address">
              <div>{{ fc.address.street }} {{ fc.address.streetNumber }}</div>
              <div>{{ fc.address.zip }}, {{ fc.address.city }}</div>
            </div>
            <div *ngIf="!fc.address">{{ "customer.showAllCustomers.noAddressCreated" | translate }}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="meta">
            <div>
              <span class="material-icons">mail</span><span>{{ fc.mail || "n/a" }}</span>
            </div>
            <div>
              <span class="material-icons">phone_enabled</span><span>{{ fc.phone || "n/a" }}</span>
            </div>
            <div>
              <span class="material-icons">web</span><span>{{ fc.companyName || "n/a" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
