import { Observable, Subject } from "rxjs";
import { inject, Injectable } from "@angular/core";

@Injectable()
export class NotificationService {
  private userInfoChanges: Subject<any> = inject(Subject<any>);
  constructor() {
    this.userInfoChanges = new Subject<any>();
  }

  getUserInfoOberservable(): Observable<any> {
    return this.userInfoChanges.asObservable();
  }

  userInfoChanged(): void {
    this.userInfoChanges.next(undefined);
  }
}
