import { AfterViewInit, Component, EventEmitter, inject, OnInit, Output, ViewChild } from "@angular/core";
import { CustomerService } from "../../../core/services/customer.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { BusinessCustomerTO, CustomerTO, PrivateCustomerTO, SearchCustomerTO } from "../../../shared/generated/transportObjects";

@Component({
  selector: "ti-invoice-search-customer",
  templateUrl: "./invoice-search-customer.component.html",
  styleUrls: ["./invoice-search-customer.component.scss"]
})
export class InvoiceSearchCustomerComponent implements OnInit, AfterViewInit {
  formGroup: UntypedFormGroup;

  @ViewChild("searchinput", { static: true })
  private searchField: MatInput;

  customers: SearchCustomerTO[];
  private collection: CustomerTO[];

  @Output()
  selected: EventEmitter<SearchCustomerTO> = new EventEmitter<SearchCustomerTO>();

  private cs: CustomerService = inject(CustomerService);
  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  constructor() {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      searchTerm: this.fb.control("")
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchField.focus();
    }, 300);
  }

  search(): void {
    this.cs.searchCustomers(this.formGroup.get("searchTerm").value).subscribe({
      next: (res: CustomerTO[]) => {
        this.collection = res;
        this.fillCustomers();
      }
    });
  }

  selectCustomer(customer: SearchCustomerTO): void {
    this.selected.emit(customer);
  }

  private fillCustomers(): void {
    this.customers = new Array();
    const business: SearchCustomerTO[] = this.collection
      .filter((cu: CustomerTO) => cu.type === "business")
      .map((value: BusinessCustomerTO) => {
        const search: SearchCustomerTO = {
          customerNumber: value.customerNumber,
          billingAddress: value.address as any,
          customerType: "Business",
          invoiceNumber: value.customerNumber,
          name: value.companyName
        };
        return search;
      });
    const privateCustomers: SearchCustomerTO[] = this.collection
      .filter((cu: CustomerTO) => cu.type === "private")
      .map((value: PrivateCustomerTO) => {
        const search: SearchCustomerTO = {
          customerNumber: value.customerNumber,
          billingAddress: value.address as any,
          name: value.firstName + " " + value.lastName,
          invoiceNumber: value.customerNumber,
          customerType: "Private"
        };
        return search;
      });
    this.customers = this.customers.concat(business, privateCustomers);
  }
}
