<div class="form-container">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="save()">
    <div class="two fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.firstname" | translate }}</mat-label>
        <input
          [maxlength]="100"
          e2e="input-firstname"
          matInput
          formControlName="firstName"
          name="firstName"
          type="text"
          placeholder="Max" />
        <mat-error *ngIf="hasError('firstName')">{{ "formValidation.common.required.firstName" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.lastname" | translate }}</mat-label>
        <input
          [maxlength]="100"
          e2e="input-lastname"
          matInput
          formControlName="lastName"
          name="lastName"
          type="text"
          placeholder="Monster" />
        <mat-error *ngIf="hasError('lastName')">{{ "formValidation.common.required.lastName" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="two fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.phone" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-phone" matInput formControlName="phone" name="phone" type="text" placeholder="09701 123 123" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.mobile" | translate }}</mat-label>
        <input
          [maxlength]="100"
          e2e="input-mobile"
          matInput
          formControlName="mobile"
          name="mobile"
          type="text"
          placeholder="0151 12 89 211" />
      </mat-form-field>
    </div>
    <div class="two fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.email" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-email" matInput formControlName="mail" name="mail" type="text" placeholder="max@monster.com" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.contactPerson.department" | translate }}</mat-label>
        <input
          [maxlength]="100"
          e2e="input-department"
          matInput
          formControlName="department"
          name="department"
          type="text"
          placeholder="Technical" />
      </mat-form-field>
    </div>
  </form>
  <div class="controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
