<div *ngIf="customer">
  <ti-invoice-list #invoiceListComponent [customerPageLoader]="customerByIdPageLoader"></ti-invoice-list>
  <div class="not-found" *ngIf="!invoiceListComponent.customerInvoices?.totalElements">
    <ti-invoice-search-customer (selected)="selectCustomer($event)"></ti-invoice-search-customer>
    <div>
      <til-message [headline]="'invoice.common.notFound' | translate" icon="info_outline" type="warn" />
    </div>
  </div>
</div>
<ti-invoice-search-customer *ngIf="!customerId" (selected)="selectCustomer($event)"></ti-invoice-search-customer>
