import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BankAccountTO } from "../../shared/generated/transportObjects";
import { ValidationHelper } from "../../shared/utils/ValidationHelper";
import { BankAccountForm } from "../form-model/bank-account-form";

@Component({
  selector: "ti-edit-bank-account",
  templateUrl: "./edit-bank-account.component.html",
  styleUrls: ["./edit-bank-account.component.scss"]
})
export class EditBankAccountComponent implements OnInit {
  formGroup: FormGroup;

  @Output()
  created: EventEmitter<BankAccountTO> = new EventEmitter<BankAccountTO>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  bankAccount: BankAccountTO;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}
  ngOnInit(): void {
    if (!this.bankAccount) {
      this.bankAccount = {} as BankAccountTO;
    }
    this.formGroup = this.creatFormGroup();
  }

  creatFormGroup(): FormGroup<BankAccountForm> {
    return this.fb.group<BankAccountForm>({
      bankname: this.fb.control(this.bankAccount.bankname || "", Validators.required),
      iban: this.fb.control(this.bankAccount.iban || "", [Validators.required, Validators.pattern(ValidationHelper.iban)]),
      bic: this.fb.control(this.bankAccount.bic || "", [Validators.required, Validators.pattern(ValidationHelper.bic)])
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const result: BankAccountTO = this.formGroup.value;
      result.id = this.bankAccount.id;
      result.version = this.bankAccount.version;
      this.created.emit(result);
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }
}
