<div class="start-container">
  <div class="ti-cards">
    <div e2e="card-btn-customers" class="ti-card ti-link-card cms-card" routerLink="/customers/welcome">
      <span class="material-icons">people_alt</span>
      <div>{{ "common.customers" | translate }}</div>
      <p>{{ "core.components.start.manageTreeITCustomers" | translate }}</p>
    </div>

    <div e2e="card-btn-invoices" class="ti-card ti-link-card cms-card" routerLink="/invoices/welcome">
      <span class="material-icons">how_to_vote</span>
      <div>{{ "topNavigation.invoices" | translate }}</div>
      <p>{{ "core.components.start.manageTreeITInvoices" | translate }}</p>
    </div>

    <div e2e="card-btn-products" class="ti-card ti-link-card cms-card" routerLink="/apps/products">
      <span class="material-icons">hot_tub</span>
      <div>{{ "common.products" | translate }}</div>
      <p>{{ "core.components.start.showAllTheFancyProducts" | translate }}</p>
    </div>

    <div e2e="card-btn-stats" class="ti-card ti-link-card cms-card" routerLink="/statistics/welcome">
      <span class="material-icons">assessment</span>
      <div>{{ "topNavigation.stats" | translate }}</div>
      <p>{{ "core.components.start.runAwesomeReports" | translate }}</p>
    </div>
  </div>
</div>
