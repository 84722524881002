<div *ngIf="customerLoaded || billingAddress" class="billing-address-container">
  <ti-manage-billing-address
    [showControls]="true"
    (cancel)="cancel()"
    cancelLabel="{{ 'common.back' | translate }}"
    confLabel="{{ 'common.next' | translate }}"
    [customer]="customerLoaded"
    (created)="saveAddress($event)"
    [edit]="address"></ti-manage-billing-address>
</div>
