import { inject, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({ name: "timonth" })
export class TiMonthPipe implements PipeTransform {
  private datePipe: DatePipe = inject(DatePipe);
  constructor() {}

  transform(value): string {
    return this.datePipe.transform(value, "MM/yyyy");
  }
}
