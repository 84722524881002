import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../../shared/utils/ValidationHelper";
import { ContactPersonTO } from "../../../shared/generated/transportObjects";
import { ContactPersonForm } from "../../form-model/contact-person-form";

@Component({
  selector: "ti-contact-person",
  templateUrl: "./contact-person.component.html",
  styleUrls: ["./contact-person.component.scss"]
})
export class ContactPersonComponent implements OnInit {
  @Input()
  contactPerson: ContactPersonTO;

  @Output()
  created: EventEmitter<ContactPersonTO> = new EventEmitter<ContactPersonTO>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  formGroup: FormGroup;
  createMode: boolean;

  private fb: FormBuilder = inject(FormBuilder);

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  private init(): void {
    if (!this.contactPerson) {
      this.createMode = true;
      this.contactPerson = {} as any;
    }
    this.formGroup = this.createFormGroup();
  }

  private createFormGroup(): FormGroup<ContactPersonForm> {
    return this.fb.group<ContactPersonForm>({
      firstName: this.fb.control(this.contactPerson.firstName || "", Validators.required),
      lastName: this.fb.control(this.contactPerson.lastName || "", Validators.required),
      mail: this.fb.control(this.contactPerson.mail || ""),
      phone: this.fb.control(this.contactPerson.phone || ""),
      mobile: this.fb.control(this.contactPerson.mobile || ""),
      department: this.fb.control(this.contactPerson.department || "")
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const contactPerson: ContactPersonTO = this.formGroup.value;
      this.created.emit(contactPerson);
    }
  }

  cancelEdit(): void {
    this.cancel.emit();
  }
}
