import { HttpClient } from "@angular/common/http";
import { Inject, inject, Injectable } from "@angular/core";
import { HeaderFactory } from "../utils/header-factory";
import { Observable } from "rxjs";
import { ApiEndpoints } from "../../shared/utils/api-endpoints";

@Injectable({
  providedIn: "root"
})
export class InvoiceTemplateService {
  private http: HttpClient = inject(HttpClient);
  private hf: HeaderFactory = inject(HeaderFactory);
  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    this.hf = new HeaderFactory();
  }

  findAllTemplates(): Observable<string[]> {
    const url: string = this.serverUrl + ApiEndpoints.findAllInvoiceTemplates;
    return this.http.get<string[]>(url);
  }
}
