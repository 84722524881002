<div *ngIf="page && page.content.length > 0" class="list-container">
  <table [dataSource]="tableDataSource" class="credits" mat-table>
    <ng-container matColumnDef="date">
      <th *matHeaderCellDef mat-header-cell>{{ "customer.shared.components.creditList.date" | translate }}</th>
      <td *matCellDef="let credit" mat-cell>{{ getDateString(credit) }}</td>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th *matHeaderCellDef mat-header-cell>{{ "common.customer" | translate }}</th>
      <td *matCellDef="let credit" mat-cell>
        <span *ngIf="credit">{{ credit.customer }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th *matHeaderCellDef mat-header-cell>{{ "common.description" | translate }}</th>
      <td *matCellDef="let credit" mat-cell>{{ credit.note }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th *matHeaderCellDef mat-header-cell>{{ "common.amount" | translate }}</th>
      <td *matCellDef="let credit" mat-cell>
        <span>{{ credit.amount | euro }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th *matHeaderCellDef mat-header-cell>{{ "customer.shared.components.creditList.action" | translate }}</th>
      <td *matCellDef="let credit" mat-cell>
        <button e2e="btn-edit-credit" (click)="edit.emit(credit)" mat-button>
          <mat-icon class="edit">edit</mat-icon>
        </button>
        <button e2e="btn-delete-credit" (click)="checkDelete(credit)" mat-button>
          <mat-icon class="delete">delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: visibleColumns" class="row" mat-row></tr>
  </table>
  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="page.totalElements"
    [pageSizeOptions]="[10, 25, 100]"
    [pageSize]="10"></mat-paginator>
</div>

<div *ngIf="noCreditsHint && page && page.content.length === 0" class="no-credits">
  <i class="hand spock outline icon"></i>
  <div class="message">{{ "customer.shared.components.creditList.norCreditsFound" | translate }}</div>
</div>
