import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerService } from "../../../core/services/customer.service";
import { BusinessCustomerTO, FreelanceCustomerTO, PrivateCustomerTO } from "../../../shared/generated/transportObjects";

@Component({
  selector: "ti-choose-type",
  templateUrl: "./choose-type.component.html",
  styleUrls: ["./choose-type.component.scss"]
})
export class ChooseTypeComponent implements OnInit {
  createType: string;

  private cs: CustomerService = inject(CustomerService);
  private router: Router = inject(Router);

  constructor() {}

  ngOnInit(): void {
    this.createType = "business";
  }

  createBusinessCustomer(businessCustomer: BusinessCustomerTO): void {
    this.cs.createCustomer(businessCustomer).subscribe({
      next: (res: BusinessCustomerTO) => this.router.navigateByUrl("/customers/show/" + res.customerNumber)
    });
  }

  createPrivateCustomer(privateCustomer: PrivateCustomerTO): void {
    this.cs.createCustomer(privateCustomer).subscribe({
      next: (res: PrivateCustomerTO) => this.router.navigateByUrl("/customers/show/" + res.customerNumber)
    });
  }

  createFreelanceCustomer(freelanceCustomer: FreelanceCustomerTO): void {
    this.cs
      .createCustomer(freelanceCustomer)
      .subscribe({ next: (res: FreelanceCustomerTO) => this.router.navigateByUrl("/customers/show/" + res.customerNumber) });
  }

  cancel(): void {
    this.router.navigateByUrl("customers/welcome");
  }
}
