import { Component, inject, OnInit } from "@angular/core";
import { InvoiceService } from "../../core/services/invoice.service";
import { Observable } from "rxjs";
import { InvoiceTO } from "../../shared/generated/transportObjects";
import { Page } from "ti-frontend-shared";

@Component({
  selector: "ti-all-open-invoices",
  templateUrl: "./all-open-invoices.component.html",
  styleUrls: ["./all-open-invoices.component.scss"]
})
export class AllOpenInvoicesComponent implements OnInit {
  outstandings: number;

  private is: InvoiceService = inject(InvoiceService);

  constructor() {}

  ngOnInit(): void {
    this.is.getOutstandings().subscribe({
      next: (res: any) => (this.outstandings = res.total)
    });
  }

  get openCustomerInvoicesPageLoader(): (page: number) => Observable<Page<InvoiceTO>> {
    return (page: number) => this.is.findAllOpenCustomerInvoices(page);
  }

  get openProductInvoicesPageLoader(): (page: number) => Observable<Page<InvoiceTO>> {
    return (page: number) => this.is.findAllOpenProductInvoices(page);
  }
}
