import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { APP_CONFIG, AppConfig } from "./app.config";

fetch("/assets/context/context.json")
  .then((response: Response) => response.json())
  .then((config: AppConfig) => {
    if (config.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: config },
      {
        provide: "ADM_SERVER_URL",
        useValue: config.adminServerUrl
      },
      { provide: "SERVER_URL", useValue: config.serverUrl }
    ])
      .bootstrapModule(AppModule)
      .catch((err: any) => console.error(err));
  });
