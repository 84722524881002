import { Router } from "@angular/router";
import { Component, inject } from "@angular/core";
import { AuthenticationService } from "ti-frontend-shared";

@Component({
  selector: "ti-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.scss"]
})
export class StartComponent {
  private as: AuthenticationService = inject(AuthenticationService);
  private router: Router = inject(Router);
  constructor() {}
}
