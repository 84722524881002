<div class="search-area">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="search()">
    <mat-form-field>
      <mat-label>{{ "common.search" | translate }} {{ "common.customers" | translate }}</mat-label>
      <input e2e="input-search-customer" matInput placeholder="Max" formControlName="searchTerm" #searchinput="matInput" />
    </mat-form-field>
  </form>
</div>

<div *ngIf="customers && customers.length > 0" class="result-area">
  <div (click)="selectCustomer(cust)" class="ti-card" *ngFor="let cust of customers">
    <div class="header">
      {{ cust.name }}
    </div>
    <div class="add-info">{{ cust.invoiceNumber }} - {{ cust.customerType }}</div>
    <mat-divider></mat-divider>
    <div *ngIf="cust.billingAddress">
      <div>{{ cust.billingAddress.street }} {{ cust.billingAddress.streetNumber }}</div>
      <div>{{ cust.billingAddress.zip }} {{ cust.billingAddress.city }}</div>
    </div>
  </div>
</div>
