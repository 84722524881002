import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedIn } from "../shared/guards/logged-in";
import { StatisticsWelcomeComponent } from "./statistics-welcome/statistics-welcome.component";
import { StatsOverviewComponent } from "./stats-overview/stats-overview.component";
import { StatSalesPerYearComponent } from "./stat-sales-per-year/stat-sales-per-year.component";
import { StatSalesTrendComponent } from "./stat-sales-trend/stat-sales-trend.component";
import { StatisticsResolver } from "./shared/resolver/statistics.resolver";

const routes: Routes = [
  {
    path: "statistics/welcome",
    component: StatisticsWelcomeComponent,
    outlet: "primary",
    canActivate: [LoggedIn]
  },
  {
    path: "statistics/overview",
    component: StatsOverviewComponent,
    outlet: "primary",
    canActivate: [LoggedIn],
    resolve: { statistics: StatisticsResolver }
  },
  {
    path: "statistics/sales/year",
    component: StatSalesPerYearComponent,
    outlet: "primary",
    canActivate: [LoggedIn],
    resolve: { statistics: StatisticsResolver }
  },
  {
    path: "statistics/sales/trend",
    component: StatSalesTrendComponent,
    outlet: "primary",
    canActivate: [LoggedIn],
    resolve: { statistics: StatisticsResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatisticsRoutingModule {}
