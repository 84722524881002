export class ChartColors {
  static CHARTCOLORS_1: Array<any> = [
    {
      backgroundColor: [
        "#f3de7b",
        "#d4701d",
        "#1d6d8a",
        "#e5e500",
        "#cae59d",
        "#1bb493",
        "#bb4455",
        "#2c4157",
        "#c9e0a3",
        "#f3de7b",
        "#d4701d",
        "#1d6d8a",
        "#e5e500",
        "#cae59d",
        "#1bb493",
        "#bb4455",
        "#2c4157",
        "#c9e0a3"
      ]
    }
  ];

  static SETCOLORS: Array<any> = [
    { backgroundColor: "#f3de7b" },
    { backgroundColor: "#d4701d" },
    { backgroundColor: "#1d6d8a" },
    { backgroundColor: "#e5e500" },
    { backgroundColor: "#cae59d" },
    { backgroundColor: "#1bb493" },
    { backgroundColor: "#bb4455" },
    { backgroundColor: "#2c4157" },
    { backgroundColor: "#c9e0a3" },
    { backgroundColor: "#f3de7b" },
    { backgroundColor: "#d4701d" },
    { backgroundColor: "#1d6d8a" },
    { backgroundColor: "#e5e500" },
    { backgroundColor: "#cae59d" },
    { backgroundColor: "#1bb493" },
    { backgroundColor: "#bb4455" },
    { backgroundColor: "#2c4157" },
    { backgroundColor: "#c9e0a3" }
  ];
}
