import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { StatisticTO } from "../../shared/generated/transportObjects";

@Component({
  selector: "ti-stats-overview",
  templateUrl: "./stats-overview.component.html",
  styleUrls: ["./stats-overview.component.scss"]
})
export class StatsOverviewComponent implements OnInit {
  statistics: StatisticTO;
  private route: ActivatedRoute = inject(ActivatedRoute);
  constructor() {}

  ngOnInit(): void {
    this.statistics = this.route.snapshot.data.statistics;
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  get prevYear(): number {
    return this.currentYear - 1;
  }

  get calculateYearAvg(): number {
    const years: string[] = Object.keys(this.statistics.salesPerYear);
    let sumOfAllYear: number = 0;
    for (let year of years) {
      sumOfAllYear = sumOfAllYear + (this.statistics.salesPerYear[year] ? this.statistics.salesPerYear[year].totalSales : 0);
    }
    return sumOfAllYear;
  }

  get calculateMonthAvg(): number {
    return this.calculateYearAvg / 12;
  }
}
