<div class="form-container">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="saveAddress()">
    <div>
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.salutation" | translate }}</mat-label>
        <mat-select e2e="select-salutation" formControlName="salutation" name="salutation">
          <mat-option *ngFor="let sal of ['Mr', 'Ms']" [value]="sal">
            {{ "customer.manage.freelanceCustomer.salutation." + sal | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="two fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.firstname" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-firstname" matInput placeholder="Max" formControlName="firstName" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.common.lastname" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-lastname" matInput placeholder="Master" formControlName="lastName" />
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>{{ "customer.manage.freelanceCustomer.companyName" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-company" matInput placeholder="Bullet Corp" formControlName="company" />
    </mat-form-field>
    <mat-error *ngIf="formGroup.hasError('atLeastOneRequired')">
      {{ "formValidation.customer.freelance.required.atLeastOneRequired" | translate }}
    </mat-error>
    <div class="street fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.street" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-street" matInput placeholder="Antstreet" formControlName="street" />
        <mat-error *ngIf="hasError('street')">{{ "formValidation.customer.address.required.street" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.Nr." | translate }}</mat-label>
        <input [maxlength]="10" e2e="input-street-number" matInput placeholder="12" formControlName="streetNumber" />
        <mat-error *ngIf="hasError('streetNumber')">{{ "formValidation.customer.address.required.streetNumber" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>{{ "customer.manage.manageAddress.additionalInfo" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-additional-info" matInput placeholder="2. Floor" formControlName="addInfo" />
    </mat-form-field>
    <div class="zip fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.ZIP" | translate }}</mat-label>
        <input [maxlength]="10" e2e="input-zip" matInput placeholder="97657" formControlName="zip" />
        <mat-error *ngIf="hasError('zip')">{{ "formValidation.customer.address.required.zip" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.city" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-city" matInput placeholder="Newtown" formControlName="city" />
        <mat-error *ngIf="hasError('city')">{{ "formValidation.customer.address.required.city" | translate }}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <div *ngIf="showControls" class="controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">
      {{ (cancelLabel ? cancelLabel : "common.cancel") | translate }}
    </button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="saveAddress()" mat-raised-button color="primary">
      {{ (confLabel ? confLabel : "common.save") | translate }}
    </button>
  </div>
</div>
