import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerService } from "../../../core/services/customer.service";
import { ApplicationStateService } from "../../../core/services/application-state.service";
import {
  AddressTO,
  BillingAddressTO,
  BusinessCustomerTO,
  ContactPersonTO,
  CustomerTO,
  FreelanceCustomerTO,
  PrivateCustomerTO
} from "../../../shared/generated/transportObjects";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmModalComponent, ConfirmModalData } from "../../../shared/modals/confirm-modal/confirm-modal.component";
import { ConfirmModalResult } from "../../../shared/model/confirm-modal-result";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "ti-show-customer",
  templateUrl: "./show-customer.component.html",
  styleUrls: ["./show-customer.component.scss"]
})
export class ShowCustomerComponent implements OnInit {
  customer: CustomerTO;
  privateCustomer: PrivateCustomerTO;
  businessCustomer: BusinessCustomerTO;
  freelanceCustomer: FreelanceCustomerTO;
  editAddress: boolean;
  editBillingAddress: boolean;
  editCustomerData: boolean;
  editContactPeople: boolean;
  contactPersonEdit: ContactPersonTO;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private cs: CustomerService = inject(CustomerService);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private router: Router = inject(Router);
  private dialog: MatDialog = inject(MatDialog);
  private translate: TranslateService = inject(TranslateService);

  constructor() {}

  ngOnInit(): void {
    const customerNumber: string = this.activatedRoute.snapshot.params["customerNumber"];
    this.editAddress = false;
    this.editBillingAddress = false;
    this.editCustomerData = false;
    this.editContactPeople = false;
    this.loadData(customerNumber);
  }

  private loadData(customerNumber: string): void {
    this.cs.findCustomer(customerNumber).subscribe({
      next: (value: CustomerTO) => this.fillData(value)
    });
  }

  private fillData(customer: CustomerTO): void {
    this.customer = customer;
    if (customer.type === "business") {
      this.businessCustomer = customer as BusinessCustomerTO;
    }
    if (customer.type === "private") {
      this.privateCustomer = customer as PrivateCustomerTO;
    }
    if (customer.type === "freelance") {
      this.freelanceCustomer = customer as FreelanceCustomerTO;
    }
  }

  saveAddress(address: AddressTO): void {
    this.cs.saveAddress(address, this.customer.customerNumber).subscribe({
      next: (res: AddressTO) => {
        this.customer.address = res;
        this.editAddress = false;
      }
    });
  }

  saveBillingAddress(billingAddress: BillingAddressTO): void {
    this.cs.saveBillingAddress(billingAddress, this.customer.customerNumber).subscribe({
      next: (res: BillingAddressTO) => {
        this.customer.billingAddress = res;
        this.editBillingAddress = false;
      }
    });
  }

  saveBusinessCustomer(businessCustomer: BusinessCustomerTO): void {
    this.cs.updateCustomer(businessCustomer, businessCustomer.customerNumber).subscribe({
      next: (res: BusinessCustomerTO) => {
        this.customer = res;
        this.businessCustomer = res;
        this.editCustomerData = false;
      }
    });
  }

  savePrivateCustomer(privateCustomer: PrivateCustomerTO): void {
    this.cs.updateCustomer(privateCustomer, privateCustomer.customerNumber).subscribe({
      next: (res: PrivateCustomerTO) => {
        this.customer = res;
        this.privateCustomer = res;
        this.editCustomerData = false;
      }
    });
  }

  saveFreelanceCustomer(freelanceCustomer: FreelanceCustomerTO): void {
    this.cs.updateCustomer(freelanceCustomer, freelanceCustomer.customerNumber).subscribe({
      next: (res: FreelanceCustomerTO) => {
        this.customer = res;
        this.freelanceCustomer = res;
        this.editCustomerData = false;
      }
    });
  }

  saveContactPerson(contactPerson: ContactPersonTO): void {
    this.businessCustomer.contactPeople = [...this.businessCustomer.contactPeople, contactPerson];
    this.cs.saveContactPeople(this.businessCustomer.contactPeople, this.customer.customerNumber).subscribe({
      next: (res: ContactPersonTO[]) => {
        this.businessCustomer.contactPeople = res;
        this.contactPersonEdit = undefined;
        this.editContactPeople = false;
      }
    });
  }

  deleteContactPerson(contactPerson: ContactPersonTO): void {
    const context: ConfirmModalData = {
      title: this.translate.instant("common.delete") + ` ${contactPerson.firstName} ${contactPerson.lastName}?`,
      question: "customer.manage.showCustomer.youAreAboutToDeleteThisContactPerson"
    };
    this.dialog
      .open(ConfirmModalComponent, { data: context })
      .afterClosed()
      .subscribe({
        next: (res: ConfirmModalResult) => {
          if (this.translate.instant(ConfirmModalResult.ACCEPT) === res) {
            this.businessCustomer.contactPeople = this.businessCustomer.contactPeople.filter(
              (value: ContactPersonTO) => value !== contactPerson
            );
            this.cs.saveContactPeople(this.businessCustomer.contactPeople, this.customer.customerNumber).subscribe({
              next: (result: ContactPersonTO[]) => {
                this.businessCustomer.contactPeople = result;
                this.contactPersonEdit = undefined;
                this.editContactPeople = false;
              }
            });
          }
        }
      });
  }

  addContactPerson(): void {
    this.contactPersonEdit = undefined;
    this.editContactPeople = true;
  }

  editContactPerson(edit: ContactPersonTO): void {
    this.businessCustomer.contactPeople = this.businessCustomer.contactPeople.filter((value: ContactPersonTO) => value !== edit);
    this.contactPersonEdit = edit;
    this.editContactPeople = true;
  }

  deleteCustomer(customerNumber: string): void {
    this.cs.deleteCustomer(customerNumber).subscribe({
      next: () => {
        this.router.navigateByUrl("/customers/all");
      }
    });
  }

  quickInvoice(): void {
    this.applicationStateService.setInvoiceStep(1, this.customer);
    this.applicationStateService.setInvoiceStep(2, this.customer.billingAddress);
    this.router.navigateByUrl("/invoices/new");
  }

  cancelSaveContactPerson(): void {
    if (this.contactPersonEdit) {
      this.businessCustomer.contactPeople = [...this.businessCustomer.contactPeople, this.contactPersonEdit];
    }
    this.contactPersonEdit = undefined;
    this.editContactPeople = false;
  }
}
