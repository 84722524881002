import { Inject, inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HeaderFactory } from "../utils/header-factory";
import { Observable } from "rxjs";
import { CompanyTO } from "../../shared/generated/transportObjects";
import { ApiEndpoints } from "../../shared/utils/api-endpoints";

@Injectable()
export class CompanyService {
  private http: HttpClient = inject(HttpClient);
  private hf: HeaderFactory = inject(HeaderFactory);

  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    this.hf = new HeaderFactory();
  }
  updateCompany(company: CompanyTO, companyId: number): Observable<CompanyTO> {
    const url: string = this.serverUrl + ApiEndpoints.updateCompany(companyId);
    return this.http.put<CompanyTO>(url, company);
  }
}
