import { LoggedIn } from "./../shared/guards/logged-in";
import { SearchCustomerComponent } from "./search-customer/search-customer.component";
import { ShowAllCustomersComponent } from "./show-all-customers/show-all-customers.component";
import { CustomerWelcomeComponent } from "./customer-welcome/customer-welcome.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ChooseTypeComponent } from "./manage/choose-type/choose-type.component";
import { ShowCustomerComponent } from "./manage/show-customer/show-customer.component";

const routes: Routes = [
  { path: "customers/welcome", component: CustomerWelcomeComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "customers/create", component: ChooseTypeComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "customers/show/:customerNumber", component: ShowCustomerComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "customers/all", component: ShowAllCustomersComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "customers/search", component: SearchCustomerComponent, outlet: "primary", canActivate: [LoggedIn] },
  { path: "customers/search/:search", component: ShowAllCustomersComponent, outlet: "primary", canActivate: [LoggedIn] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule {}
