import { Component, inject, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationHelper } from "../../../../shared/utils/ValidationHelper";
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Moment } from "moment";
import { CreditTO, CustomerHeadersTO } from "../../../../shared/generated/transportObjects";
import { CreditForm } from "../../../form-model/credit-form";

@Component({
  selector: "ti-create-credit-modal",
  templateUrl: "./create-credit-modal.component.html",
  styleUrls: ["./create-credit-modal.component.scss"]
})
export class CreateCreditModalComponent implements OnInit, OnDestroy {
  customerHeaders: any[];
  selectedCustomer: CustomerHeadersTO;
  formGroup: FormGroup;
  editMode: boolean = false;
  creditToEdit: CreditTO;
  private monthSub: Subscription;
  private dateSub: Subscription;

  fb: FormBuilder = inject(FormBuilder);
  dialogRef: MatDialogRef<CreateCreditModalComponent> = inject(MatDialogRef<CreateCreditModalComponent>);
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
  ngOnInit(): void {
    this.customerHeaders = this.data.customerHeaders;
    this.editMode = this.data.editMode;
    if (this.editMode) {
      this.creditToEdit = this.data.credit;
      this.selectedCustomer = this.customerHeaders.find((value: any) => value.customerName === this.creditToEdit.customer);
    }
    this.createFormGroup();
    this.createSubscriptions();
  }

  ngOnDestroy(): void {
    this.monthSub.unsubscribe();
    this.dateSub.unsubscribe();
  }

  hasError(control: string): boolean {
    return ValidationHelper.hasError(control, this.formGroup);
  }

  save(): void {
    if (this.formGroup.valid && this.selectedCustomer) {
      const credit: CreditTO = this.formGroup.value;
      credit.customer = this.selectedCustomer.customerNumber;
      this.dialogRef.close(credit);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  selectMonth(month: Moment, dp: any): void {
    this.formGroup.get("creditMonth").setValue(month.toDate());
    dp.close();
  }

  private createSubscriptions(): void {
    this.monthSub = this.formGroup.get("creditMonth").valueChanges.subscribe({
      next: (value: any) => {
        if (value) {
          this.formGroup.get("creditDate").setValue(undefined);
        }
      }
    });
    this.dateSub = this.formGroup.get("creditDate").valueChanges.subscribe({
      next: (value: any) => {
        if (value) {
          this.formGroup.get("creditMonth").setValue(undefined);
        }
      }
    });
  }

  private createFormGroup(): void {
    this.formGroup = this.fb.group<CreditForm>(
      {
        id: this.fb.control(this.creditToEdit ? this.creditToEdit.id : undefined),
        amount: this.fb.control(this.creditToEdit ? this.creditToEdit.amount : "", Validators.required),
        creditMonth: this.fb.control(this.creditToEdit ? this.creditToEdit.creditMonth : ""),
        creditDate: this.fb.control(this.creditToEdit ? this.creditToEdit.creditDate : ""),
        note: this.fb.control(this.creditToEdit ? this.creditToEdit.note : "", Validators.required)
      },
      { validators: ValidationHelper.atLeastOneRequired(["creditDate", "creditMonth"]) }
    );
  }
}
