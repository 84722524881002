import { Component, inject, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ti-report-profit-modal",
  templateUrl: "./report-profit-modal.component.html",
  styleUrls: ["./report-profit-modal.component.scss"]
})
export class ReportProfitModalComponent {
  dialogRef: MatDialogRef<ReportProfitModalComponent> = inject(MatDialogRef<ReportProfitModalComponent>);
  constructor(@Inject(MAT_DIALOG_DATA) public data: ReportProfitModalContext) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onReportClick(profit): void {
    this.dialogRef.close(profit);
  }
}

export interface ReportProfitModalContext {
  year: number;
  max: number;
}
