import { inject, Inject, Injectable } from "@angular/core";
import { HeaderFactory } from "../../../core/utils/header-factory";
import { HttpClient } from "@angular/common/http";
import { ApiEndpoints } from "../../../shared/utils/api-endpoints";
import { Observable } from "rxjs/index";
import { StatisticTO, SalesPositionsTO, QuickStatisticTO } from "../../../shared/generated/transportObjects";

@Injectable({
  providedIn: "root"
})
export class StatisticsService {
  private hf: HeaderFactory;
  private http: HttpClient = inject(HttpClient);
  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    this.hf = new HeaderFactory();
  }

  getQuickStatistics(): Observable<QuickStatisticTO> {
    const url: string = this.serverUrl + ApiEndpoints.getQuickStats;
    return this.http.get<QuickStatisticTO>(url);
  }

  getStatistics(): Observable<StatisticTO> {
    const url: string = this.serverUrl + ApiEndpoints.getStats;
    return this.http.get<StatisticTO>(url);
  }

  getSalesPositions(year: number): Observable<SalesPositionsTO> {
    const url: string = this.serverUrl + ApiEndpoints.getSalesPositions(year);
    return this.http.get<SalesPositionsTO>(url);
  }

  reportProfit(year: number, profit: number): Observable<Object> {
    const url: string = this.serverUrl + ApiEndpoints.reportProfit;
    return this.http.post(url, { year: year, profit: profit });
  }
}
