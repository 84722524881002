import { inject, Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApplicationHelper } from "ti-frontend-shared";
import { ApplicationStateService } from "./application-state.service";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.applicationStateService.getCurrentUser()?.token;
    if (ApplicationHelper.isObjectDefined(token)) {
      request = request.clone({
        setHeaders: {
          "X-TI-Auth": "Bearer " + token
        }
      });
      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
