<div class="message container">
  <div class="loader" *ngIf="loading">
    <mat-spinner [strokeWidth]="10" [diameter]="50" color="accent"></mat-spinner>
  </div>
  <div *ngIf="icon">
    <span class="material-icons">{{ icon }}</span>
  </div>
  <div class="message-content">
    <div class="headline" *ngIf="headline">{{ headline | translate }}</div>
    <div>{{ content | translate }}</div>
  </div>
</div>
