import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LoginModalComponent } from "./modals/login-modal/login-modal.component";
import { ConfirmModalComponent } from "./modals/confirm-modal/confirm-modal.component";
import { TiMonthPipe } from "./pipes/ti-month-pipe";
import { TiDateTimePipe } from "./pipes/ti-time-pipe";
import { TiDatePipe } from "./pipes/ti-date-pipe";
import { EuroPipe } from "./pipes/euro-pipe";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { LoaderComponent } from "./components/loader/loader.component";
import { ReportProfitModalComponent } from "./modals/report-profit-modal/report-profit-modal.component";
import { ManageBillingAddressComponent } from "./components/manage-billing-address/manage-billing-address.component";
import { MatInputModule } from "@angular/material/input";
import { MessageComponent } from "./components/message/message.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule } from "@angular/material/stepper";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule, MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatTableModule } from "@angular/material/table";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { TiFrontendSharedModule } from "ti-frontend-shared";
import { MatPaginatorModule } from "@angular/material/paginator";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { CorrectInvoiceModalComponent } from "./modals/correct-invoice-modal/correct-invoice-modal.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ManageAddressComponent } from "./components/manage-address/manage-address.component";

export const DateFormats: any = {
  parse: {
    dateInput: ["YYYY-MM-DD", "DD.MM.YYYY"]
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "YYYY MMM",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY MMMM"
  }
};

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatSidenavModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    TiFrontendSharedModule,
    MatPaginatorModule,
    MatGridListModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    EuroPipe,
    TiDatePipe,
    TiDateTimePipe,
    TiMonthPipe,
    LoginModalComponent,
    ConfirmModalComponent,
    ManageBillingAddressComponent,
    LoaderComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatInputModule,
    MessageComponent,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatSidenavModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    SpinnerComponent,
    TiFrontendSharedModule,
    MatPaginatorModule,
    MatGridListModule,
    TranslateModule,
    ManageAddressComponent
  ],
  declarations: [
    EuroPipe,
    TiDatePipe,
    TiDateTimePipe,
    TiMonthPipe,
    LoginModalComponent,
    ConfirmModalComponent,
    LoaderComponent,
    ReportProfitModalComponent,
    ManageBillingAddressComponent,
    MessageComponent,
    SpinnerComponent,
    CorrectInvoiceModalComponent,
    ManageAddressComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    EuroPipe,
    TiDatePipe,
    TiDateTimePipe
  ]
})
export class SharedModule {}

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
