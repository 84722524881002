<div class="controls">
  <div e2e="btn-business-customer" (click)="createType = 'business'" [ngClass]="{ selected: createType === 'business' }">
    <span class="material-icons">domain</span>
    <div>{{ "customer.manage.type.business" | translate }}</div>
  </div>
  <div e2e="btn-private-customer" (click)="createType = 'private'" [ngClass]="{ selected: createType === 'private' }">
    <span class="material-icons">person</span>
    <div>{{ "customer.manage.type.private" | translate }}</div>
  </div>
  <div e2e="btn-freelance-customer" (click)="createType = 'freelance'" [ngClass]="{ selected: createType === 'freelance' }">
    <span class="material-icons">transfer_within_a_station</span>
    <div>{{ "common.freelancer" | translate }}</div>
  </div>
</div>
<ti-business-customer
  (cancel)="cancel()"
  (created)="createBusinessCustomer($event)"
  *ngIf="createType === 'business'"></ti-business-customer>
<ti-private-customer (cancel)="cancel()" (created)="createPrivateCustomer($event)" *ngIf="createType === 'private'"></ti-private-customer>
<ti-freelance-customer
  (cancel)="cancel()"
  (created)="createFreelanceCustomer($event)"
  *ngIf="createType === 'freelance'"></ti-freelance-customer>
