import { Component, inject, Input, OnInit } from "@angular/core";
import { InvoicePositionModalComponent } from "../../modals/invoice-position-modal/invoice-position-modal.component";
import { CalculationHelper } from "../../utils/calculation-helper";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { PositionTO, PositionTOUnion } from "../../../../shared/generated/transportObjects";

@Component({
  selector: "ti-position-list",
  templateUrl: "./position-list.component.html",
  styleUrls: ["./position-list.component.scss"]
})
export class PositionListComponent implements OnInit {
  @Input()
  inputPositions: PositionTOUnion[];

  @Input()
  taxInPercent: number;

  positions: PositionTOUnion[] = [];

  tableSource: Subject<PositionTO[]> = new Subject<PositionTO[]>();

  displayedColumns: string[] = ["position", "effort", "description", "amountSingle", "priceSingle", "amount"];

  @Input()
  editable: boolean;

  private dialog: MatDialog = inject(MatDialog);
  constructor() {}

  ngOnInit(): void {
    if (!this.inputPositions) {
      this.positions = new Array();
    } else {
      this.positions = this.inputPositions;
    }
    setTimeout(() => {
      this.tableSource.next(this.positions);
    });
  }

  get totalNet(): number {
    return this.positions
      .map((value: PositionTOUnion) => CalculationHelper.getPositionNet(value))
      .reduce((previousValue: number, currentValue: number) => previousValue + currentValue);
  }

  get totalTax(): number {
    return (this.totalNet * this.taxInPercent) / 100;
  }

  get totalGross(): number {
    return (this.totalNet * (100 + this.taxInPercent)) / 100;
  }

  editPosition(pos: PositionTO): void {
    if (this.editable) {
      this.dialog
        .open(InvoicePositionModalComponent, { data: pos })
        .afterClosed()
        .subscribe({
          next: (res: any) => {
            if (res && res.delete) {
              this.deletePos(pos);
            } else if (res) {
              this.positions = this.positions.filter((value: PositionTOUnion) => value.number != res.number);
              this.positions.push(res);
              this.positions.sort((a: PositionTOUnion, b: PositionTOUnion) => a.number - b.number);
              this.tableSource.next(this.positions);
            }
          }
        });
    }
  }

  private deletePos(pos): void {
    const arr: PositionTOUnion[] = this.positions.filter((value: PositionTOUnion) => pos.number !== value.number);
    arr.forEach((value: PositionTOUnion, index: number) => (value.number = index + 1));
    this.positions = arr;
    this.tableSource.next(this.positions);
  }

  addPosition(): void {
    const matDialogRef: MatDialogRef<InvoicePositionModalComponent> = this.dialog.open(InvoicePositionModalComponent);
    matDialogRef.afterClosed().subscribe({
      next: (pos: PositionTOUnion) => {
        if (pos) {
          pos.number = this.positions.length + 1;
          this.positions.push(pos);
          setTimeout(() => {
            this.tableSource.next(this.positions);
          });
        }
      }
    });
  }
}
