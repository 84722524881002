import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { StatisticsService } from "../../statistics/shared/services/statistics.service";
import { MatInput } from "@angular/material/input";
import { SpinnerService } from "ti-frontend-shared";
import { SalesPositionsTO } from "../../shared/generated/transportObjects";

@Component({
  selector: "ti-list-sales-positions",
  templateUrl: "./list-sales-positions.component.html",
  styleUrls: ["./list-sales-positions.component.scss"]
})
export class ListSalesPositionsComponent implements OnInit {
  salesPostitions: SalesPositionsTO;

  @ViewChild("yi", { static: true })
  private yi: MatInput;

  displayedColumns: string[] = ["date", "number", "customer", "positions"];

  private ss: StatisticsService = inject(StatisticsService);
  private spinner: SpinnerService = inject(SpinnerService);

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.yi.focus();
    }, 200);
  }

  createPositions(year: string): void {
    this.spinner.spin(true);
    this.ss.getSalesPositions(+year).subscribe({
      next: (res: SalesPositionsTO) => {
        this.salesPostitions = res;
        this.spinner.spin(false);
      }
    });
  }
}
