import { NotificationService } from "./core/services/notification.service";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { SidenavService } from "./core/services/sidenav.service";
import { SpinnerService } from "ti-frontend-shared";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationStateService } from "./core/services/application-state.service";
import { PreferredLanguage, UserContextTO } from "./shared/generated/transportObjects";
import { UserService } from "./core/services/user.service";
import { PreferredLanguageEnum } from "./shared/enums/PreferredLanguage.enum";

@Component({
  selector: "ti-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnDestroy, OnInit {
  loading = false;
  sideNavOpened: boolean = false;
  private userInfoChanged: Observable<any>;
  private sideNavSubscription: Subscription;
  private spinnerSubscription: Subscription;

  private router: Router = inject(Router);
  private ns: NotificationService = inject(NotificationService);
  private navService: SidenavService = inject(SidenavService);
  private spinnerService: SpinnerService = inject(SpinnerService);
  private applicationStateService: ApplicationStateService = inject(ApplicationStateService);
  private userService: UserService = inject(UserService);
  translate: TranslateService = inject(TranslateService);

  constructor() {
    this.translate.addLangs([PreferredLanguageEnum.DE_DE, PreferredLanguageEnum.EN_US]);
    this.translate.setDefaultLang(PreferredLanguageEnum.EN_US);
    if (this.loggedIn) {
      this.translate.use(this.applicationStateService.getCurrentUser().preferredLanguage);
    } else {
      this.translate.use(PreferredLanguageEnum.EN_US);
    }
  }

  ngOnInit(): void {
    this.userInfoChanged = this.ns.getUserInfoOberservable();
    this.sideNavSubscription = this.navService.sideNavTrigger.subscribe({ next: (value: boolean) => (this.sideNavOpened = value) });
    this.spinnerSubscription = this.spinnerService.observable().subscribe({ next: (value: boolean) => (this.loading = value) });
  }

  ngOnDestroy(): void {
    this.sideNavSubscription.unsubscribe();
    this.spinnerSubscription.unsubscribe();
  }

  get loggedIn(): boolean {
    return this.applicationStateService.getCurrentUser() != undefined;
  }

  get fullUserName(): string {
    const currentUser: UserContextTO = this.applicationStateService.getCurrentUser();
    return currentUser ? (currentUser.userInformation.firstName || "") + " " + (currentUser.userInformation.lastName || "") : "";
  }

  logout(): void {
    this.spinnerService.spin(true);
    setTimeout(() => {
      this.spinnerService.spin(false);
      this.applicationStateService.clearForLogout();
      this.translate.use(PreferredLanguageEnum.EN_US);
      this.router.navigateByUrl("/apps/login");
    }, 500);
  }
  navHome(): void {
    this.router.navigateByUrl("/");
  }

  getCompany(): void {
    const companyId: number = this.applicationStateService.getCurrentUser().company.companyId;
    this.router.navigateByUrl("/company/show/" + companyId);
  }

  updateLanguage(lang: string): void {
    let username: string = this.applicationStateService.getCurrentUser().userInformation.uuid;
    this.userService.updatePreferredLanguage(username, lang).subscribe((res: PreferredLanguage): void => {
      let userContext: UserContextTO = this.applicationStateService.getCurrentUser();
      userContext.preferredLanguage = res;
      this.applicationStateService.setCurrentUser(userContext);
    });
  }
}
