<div class="modal">
  <h1 mat-dialog-title>{{ "invoice.correctInvoiceModel.correctingInvoice" | translate }} {{ data.invoiceNumberString }}</h1>
  <div mat-dialog-content class="content">
    <p>
      {{ "invoice.correctInvoiceModel.createCorrectionInvoiceFor" | translate }} {{ data.invoiceNumberString }}
      {{ "invoice.correctInvoiceModel.overTheWholeInvoiceAmountAreYouSure" | translate }}
    </p>
  </div>
  <div mat-dialog-actions class="actions">
    <button (click)="close()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-correct-invoice-confirm" (click)="correctInvoice()" mat-raised-button color="primary">
      {{ "invoice.correctInvoiceModel.createCorrectionInvoice" | translate }}
    </button>
  </div>
</div>
