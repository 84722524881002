<div class="form-container">
  <form novalidate [formGroup]="formGroup" (keyup.enter)="save()">
    <div class="street fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.street" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-street" matInput placeholder="Antstreet" formControlName="street" />
        <mat-error *ngIf="hasError('street')">{{ "formValidation.customer.address.required.street" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.Nr." | translate }}</mat-label>
        <input [maxlength]="10" e2e="input-street-number" matInput placeholder="12" formControlName="streetNumber" />
        <mat-error *ngIf="formGroup.get('streetNumber').hasError('required')">
          {{ "formValidation.customer.address.required.streetNumber" | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get('streetNumber').hasError('pattern')">
          {{ "formValidation.customer.address.pattern.streetNumber" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>{{ "customer.manage.manageAddress.additionalInfo" | translate }}</mat-label>
      <input [maxlength]="100" e2e="input-additional-info" matInput placeholder="2. Floor" formControlName="addressInfo" />
    </mat-form-field>
    <div class="zip fields">
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.ZIP" | translate }}</mat-label>
        <input [maxlength]="10" e2e="input-zip" matInput placeholder="97657" formControlName="zip" />
        <mat-error *ngIf="formGroup.get('zip').hasError('required')">
          {{ "formValidation.customer.address.required.zip" | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get('zip').hasError('pattern')">
          {{ "formValidation.customer.address.pattern.zip" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "customer.manage.manageAddress.city" | translate }}</mat-label>
        <input [maxlength]="100" e2e="input-city" matInput placeholder="Newtown" formControlName="city" />
        <mat-error *ngIf="hasError('city')">{{ "formValidation.customer.address.required.city" | translate }}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="controls">
    <button e2e="btn-cancel" (click)="cancelEdit()" mat-button color="warn">{{ "common.cancel" | translate }}</button>
    <button e2e="btn-save" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button color="primary">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
