import { inject, Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HeaderFactory } from "../utils/header-factory";
import { Observable } from "rxjs/internal/Observable";
import { ApiEndpoints } from "../../shared/utils/api-endpoints";
import { ProductTO } from "../../shared/generated/transportObjects";

@Injectable({
  providedIn: "root"
})
export class ProductService {
  private http: HttpClient = inject(HttpClient);
  private hf: HeaderFactory = inject(HeaderFactory);
  constructor(@Inject("SERVER_URL") private serverUrl: string) {
    this.hf = new HeaderFactory();
  }

  findAllProducts(): Observable<ProductTO[]> {
    const url: string = this.serverUrl + ApiEndpoints.findAllProducts;
    return this.http.get<ProductTO[]>(url);
  }
}
