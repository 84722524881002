<table *ngIf="positions && positions.length > 0" mat-table [dataSource]="tableSource" class="position">
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>{{ "invoice.shared.components.positionList.no" | translate }}</th>
    <td mat-cell *matCellDef="let pos">{{ pos.number }}</td>
  </ng-container>

  <ng-container matColumnDef="effort">
    <th mat-header-cell *matHeaderCellDef>{{ "invoice.shared.components.positionList.effort" | translate }}</th>
    <td mat-cell *matCellDef="let pos">
      <span *ngIf="pos.posDate">{{ pos.posDate | date }}</span>
      <span *ngIf="pos.posMonth">{{ pos.posMonth | timonth }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>{{ "common.description" | translate }}</th>
    <td mat-cell *matCellDef="let pos">{{ pos.description }}</td>
  </ng-container>

  <ng-container matColumnDef="amountSingle">
    <th mat-header-cell *matHeaderCellDef>{{ "common.amount" | translate }} (Pos)</th>
    <td mat-cell *matCellDef="let pos">
      <span *ngIf="pos.type === 'hours' || 'freelancer'">{{ pos.hours }}</span>
      <span *ngIf="pos.type === 'units'">{{ pos.units }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="priceSingle">
    <th mat-header-cell *matHeaderCellDef>{{ "common.price" | translate }} (Pos)</th>
    <td mat-cell *matCellDef="let pos">
      <span *ngIf="pos.type === 'hours' || 'freelancer'">{{ pos.hourlyRate }}</span>
      <span *ngIf="pos.type === 'units'">{{ pos.price }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th class="amount" mat-header-cell *matHeaderCellDef>{{ "common.amount" | translate }}</th>
    <td class="amount" mat-cell *matCellDef="let pos">
      <span *ngIf="pos.type === 'amount'">{{ pos.amount | euro }}</span>
      <span *ngIf="pos.type === 'hours'">{{ pos.hourlyRate * pos.hours | euro }}</span>
      <span *ngIf="pos.type === 'freelancer'">{{ pos.hourlyRate * pos.hours | euro }}</span>
      <span *ngIf="pos.type === 'units'">{{ pos.units * pos.price | euro }}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr [class.selectable]="editable" class="row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="editPosition(row)"></tr>
</table>

<div *ngIf="positions && positions.length > 0" class="sum">
  <div class="sum-box">
    <div>{{ totalNet | euro }}</div>
    <div>{{ totalTax | euro }}</div>
    <hr />
    <div>{{ totalGross | euro }}</div>
  </div>
</div>

<div *ngIf="positions && positions.length === 0 && editable" class="no-positions">
  <div class="message-container">
    <ti-message
      [headline]="'invoice.shared.components.positionList.createStuff'"
      [icon]="'emoji_people'"
      [content]="'invoice.shared.components.positionList.noInvoiceCreated'"></ti-message>
  </div>
</div>
<div *ngIf="editable" class="add-position">
  <button e2e="btn-add-invoice-position" mat-button color="accent" (click)="addPosition()">
    {{ "invoice.shared.components.positionList.addPosition" | translate }}
  </button>
</div>
